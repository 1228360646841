import { ActionTree } from 'vuex';
import _ from 'lodash';
import {
  IInboundOutboundMappingStoreState,
  IVuexRootStoreState,
  IOutboundMappingItem,
  IOutboundAlert,
  IOutboundMessage,
  IWorkorderTemplateData,
  ITemplateSection,
  IAlarm,
  ICondition,
  ITemplateStatus,
  IOutboundMessageStatusAlarm,
  ITemplateSearchRecord,
} from '@/types';

export const OutboundMappingStoreActions: ActionTree<IInboundOutboundMappingStoreState, IVuexRootStoreState> = {
  setMessageName: ({ commit }, messageName: string) => {
    commit('setMessageName', messageName);
  },

  setWorkOrderTemplate: ({ commit }, workOrderTemplate: string) => {
    commit('setWorkOrderTemplate', workOrderTemplate);
  },

  setMappings: ({ commit }, mappings: IOutboundMappingItem[]) => {
    commit('setMappings', mappings);
  },

  setAlerts: ({ commit }, alerts: IOutboundAlert[]) => {
    commit('setAlerts', alerts);
  },

  setEditedOutboundMessage: ({ commit }, outboundMessage: IOutboundMessage[]) => {
    commit('setEditedOutboundMessage', outboundMessage);
  },

  setEndpoint: ({ commit }, endpoint: string) => {
    commit('setEndpoint', endpoint);
  },
  /**
   *  @summary Retrieves all work order templates that have configured outbound messages
   *  @author Ewa Murjas
   */
  setMappedTemplates: async ({ commit, dispatch }) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates?filter[has_outbound_mappings]=1&with_versions=1`,
      backupError: 'Error loading templates. Please try again later.',
    }, { root: true })
      .then((data) => {
        data.data = data.data.map((record: ITemplateSearchRecord) => {
          Object.assign(record, {
            createdByName: record.user?.name,
          });
          return record;
        });
        commit('setMappedTemplates', data.data);
      }).finally(() => commit('setLoading', false));
  },

  /**
   *  @summary Retrieves data for a specified work order template
   *  @author Ewa Murjas
   */
  loadTemplateData: async ({ commit, dispatch, getters }) => {
    commit('setLoading', true);
    const templateId = getters.getWorkOrderTemplateId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}`,
      backupError: 'Error loading template. Please try again later.',
    }, { root: true })
      .then((data) => commit('setTemplateData', data.data as IWorkorderTemplateData))
      .finally(() => commit('setLoading', false));
  },

  /**
   *  @summary Loads configured outbound messages for a specified template
   *  @description
   *      This action retrieves the outbound messages configured for a work order template.
   *      The mapped fields that are returned don't include a field name property so we
   *      retrieve the sections data for the specified template and map the names based on
   *      the field ids.
   *  @author Ewa Murjas
   */
  loadOutboundMessages: async ({ getters, commit, dispatch }) => {
    commit('setOutboundMessageLoading', true);

    /**
     *  @TODO review the need for this load event, remove if not or improve if required.
     */
    await dispatch('loadTemplateData');
    const templateId = getters.getWorkOrderTemplateId;
    const { sections } = getters.getTemplateData;
    const fieldOperators = getters.getFieldConditions;

    // Return fieldName from fieldId assicated with the field mapping/alarm
    function getFieldName(fieldId: number) {
      const fieldObject = {
        customFieldName: '',
        fieldType: '',
      };
      sections.forEach((section: ITemplateSection) => {
        const { fields } = section;

        const fieldFound = fields.find((field) => field.id === fieldId);
        if (fieldFound) {
          fieldObject.customFieldName = `${fieldFound.name} (${section.name})`;
          fieldObject.fieldType = fieldFound.type;
        }
      });
      return fieldObject;
    }

    // With new version of a template we generate a new ID so we don't require the version param.
    // If this changes in the future and we begin using the original template's ID then we should
    // re-enable the version param here.
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/outbound`,
      // options: {
      //   params: {
      //     version, // (from getTemplateData)
      //   },
      // },
      backupError: 'Error loading outbound message. Please try again later.',
    }, { root: true })
      .then((data) => {
        data.data = data.data.map((message: IOutboundMessage) => {
          const cloneMessage = _.cloneDeep(message);
          const { fields } = cloneMessage;

          // get fieldName property to each outbound field mapping
          cloneMessage.fields = fields.map((field) => {
            const { customFieldName } = getFieldName(field.customFieldId);

            Object.assign(field, {
              fieldName: customFieldName,
            });

            return field;
          });

          // Get each alarm, and create new array of objs with the conditions details + id info
          if (cloneMessage.alarms.length > 0) {
            const { alarms } = cloneMessage;

            cloneMessage.alarms = alarms.map((alarm: any) => {
              const { conditions, id } = alarm as IAlarm;
              const newCondition = {};

              conditions.forEach((condition: IOutboundAlert) => {
                if (condition.customFieldId) {
                  const { customFieldName, fieldType } = getFieldName(condition.customFieldId);
                  const criteriaText = fieldOperators[fieldType].find((fieldCondition: ICondition) => fieldCondition.value === condition.criteria).text;
                  Object.assign(newCondition, {
                    action: 'Field Change',
                    customFieldName,
                    id,
                    criteriaText,
                    ...condition,
                  });
                }
              });
              return newCondition;
            }) as IOutboundAlert[];
          }

          // add any status alarms into our main alarms array
          if (cloneMessage.statuses.length > 0) {
            const { statuses } = cloneMessage;

            statuses.forEach((status: IOutboundMessageStatusAlarm) => {
              const statusAlarm = {} as IOutboundAlert;

              Object.assign(statusAlarm, {
                action: 'Status Change',
                value: status.name,
                id: status.id,
                criteriaText: 'Changes to',
                customFieldName: 'Status',
              });

              cloneMessage.alarms.push(statusAlarm);
            });
          }
          return cloneMessage;
        });
        commit('setOutboundMessages', data.data as IOutboundMessage[]);
      })
      .finally(() => commit('setOutboundMessageLoading', false));
  },

  /**
   *  @summary Retrieves the options for each field type for the 'Condition' select box
   *  @author Ewa Murjas
   */
  loadFieldConditions: async ({ commit, dispatch }) => {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/alarmcriterias`,
    }, { root: true })
      .then((data) => {
        data.data.file = [{ value: '=', text: 'File present' }, { value: '!=', text: 'File not present' }];
        commit('setFieldConditions', data.data);
      });
  },

  /**
   *  @summary Creates a new outbound message
   *  @description
   *      This action retrieves the outbound message name and field mappings configured
   *      by the user in the mapping wizard. It passes this info to the API to creates a
   *      new outbound message, then creates a success or failure notification based on
   *      the response.
   *  @author Ewa Murjas
   */
  createOutboundMessage: async ({ getters, commit, dispatch }) => {
    commit('setLoading', true);

    const templateId = getters.getWorkOrderTemplateId;
    const messageName = getters.getMessageName;
    const endpoint = getters.getEndpoint;
    const fields = getters.getMappings;

    const outboundMessage = {
      name: messageName,
      type: 'api', // TODO replace with value chosen by user
      options: {
        format: 'json',
        http_method: 'POST',
        url: endpoint,
        auth_token: 'test',
      },
      fields,
    };

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/outbound`,
      options: {
        method: 'POST',
        data: outboundMessage,
      },
      backupError: 'Could not create outbound message. Please try again later.',
    }, { root: true })
      .then(async (response) => {
        const outboundId = response.data.id;
        await dispatch('createOutboundAlarm', outboundId);

        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Outbound message created successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  /**
   *  @summary Updates an edited outbound message
   *  @description
   *      The outbound message to be updated is retrieved using the getEditedOutboundMessage getter.
   *      This getter retrieves the editedOutboundMessage property which is set when the user selects
   *      the message in the left hand-side panel. We then pass the updated outbound message to the API
   *      and create a success or failure notification based on the response.
   *  @author Ewa Murjas
   */
  updateOutboundMessage: async ({ getters, commit, dispatch }) => {
    commit('setLoading', true);

    const templateId = getters.getWorkOrderTemplateId;
    const updatedOutboundMessage = getters.getEditedOutboundMessage;
    const endpoint = getters.getEndpoint;

    updatedOutboundMessage.options.url = endpoint;

    // TODO remove - temporary fix for existing messages created incorrectly
    if (updatedOutboundMessage.type === null) delete updatedOutboundMessage.type;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/outbound/${updatedOutboundMessage.id}`,
      options: {
        method: 'PUT',
        data: updatedOutboundMessage,
      },
      backupError: 'Could not update outbound message. Please try again later.',
    }, { root: true })
      .then(async () => {
        await dispatch('updateOutboundAlarm');
        /**
         * @TODO Review method for updating outbound messages list: Reloading not sustainable.
         */
        await dispatch('loadOutboundMessages');
        commit('setLoading', false);
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Outbound message updated successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  /**
   *  @summary Deletes an outbound message
   *  @description
   *      The outbound message to be deleted is retrieved using the getEditedOutboundMessage getter.
   *      This getter retrieves the editedOutboundMessage property which is set when the user selects
   *      the message in the left hand-side panel. We then pass the message id to the API and create a
   *      success or failure notification based on the response.
   *  @author Ewa Murjas
   */
  deleteOutboundMessage: async ({ getters, commit, dispatch }, outboundId: number) => {
    commit('setLoading', true);
    const templateId = getters.getWorkOrderTemplateId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/outbound/${outboundId}`,
      options: {
        method: 'DELETE',
      },
      backupError: 'Could not delete outbound message. Please try again later.',
    }, { root: true })
      .then(() => {
        dispatch('setMappings', []);
        commit('removeOutboundMessage', outboundId);
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Outbound message deleted successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  /**
   *  @summary Creates an outbound alarm for a specific outbound message
   *  @author `EJ McVey`
   */
  createOutboundAlarm: async ({ getters, commit, dispatch }, outboundMessageId) => {
    const alerts = getters.getAlerts as IOutboundAlert[];

    const templateId = getters.getWorkOrderTemplateId;

    // Create an individual alarm for each field alert
    alerts.forEach(async (alert) => {
      let url;
      let apiCall;
      const newAlarm = {};
      const baseUrl = `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}`;

      if (alert.action === 'Field Change') {
        // remove all properties not accepted by the API
        delete alert.action;
        delete alert.customFieldName;
        delete alert.criteriaText;
        delete alert.id;

        // Needs to be an array of condition objs
        const conditionArray = [alert];

        Object.assign(newAlarm, {
          name: 'Outbound Alarm',
          event_type: 'outbound_message',
          event_id: outboundMessageId,
          conditions: conditionArray,
        });

        url = `${baseUrl}/alarms`;
        apiCall = 'POST';
      } else {
        // status change alarm call
        Object.assign(newAlarm, {
          name: alert.value,
          outbound_message_id: outboundMessageId,
        });
        url = `${baseUrl}/statuses/${alert.statusId}`;
        apiCall = 'PUT';
      }

      await dispatch('auth/apiRequest', {
        url,
        options: {
          method: apiCall,
          data: newAlarm,
        },
      }, { root: true });
    });
    commit('setLoading', false);
  },

  /**
   *  @summary Updates an existing outbound alarm
   *  @author Ewa Murjas
   */
  updateOutboundAlarm: async ({ getters, commit, dispatch }) => {
    const alerts = getters.getAlerts as IOutboundAlert[];
    const templateId = getters.getWorkOrderTemplateId;
    const editedOutboundMessage = getters.getEditedOutboundMessage;

    // filter only newly added alert with temp string id
    const filteredAlerts = alerts.filter((alert) => typeof alert.id === 'string');

    // delete any alerts before updating/adding
    await dispatch('deleteAlarms');

    // remove all properties not accepted by the API
    filteredAlerts.forEach(async (alert) => {
      let url;
      let apiCall;
      const newAlarm = {};
      const baseUrl = `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}`;

      if (alert.action === 'Field Change') {
        delete alert.action;
        delete alert.customFieldName;
        delete alert.criteriaText;

        let alarmId;

        // If id is a number it exists in db, we update, else we are creating new
        if (typeof alert.id === 'number') {
          alarmId = alert.id;
          url = `${baseUrl}/alarms/${alarmId}`;
          apiCall = 'PUT';
        } else {
          delete alert.id;
          url = `${baseUrl}/alarms`;
          apiCall = 'POST';
        }

        const conditionArray = [alert];

        Object.assign(newAlarm, {
          name: 'Updated Outbound Alarm',
          event_type: 'outbound_message',
          event_id: editedOutboundMessage.id,
          conditions: conditionArray,
        });
      } else {
        url = `${baseUrl}/statuses/${alert.statusId}`;
        apiCall = 'PUT';

        Object.assign(newAlarm, {
          name: alert.value,
          outbound_message_id: editedOutboundMessage.id,
        });
      }

      await dispatch('auth/apiRequest', {
        url,
        options: {
          method: apiCall,
          data: newAlarm,
        },

      }, { root: true })
        .finally(() => {
          commit('setLoading', false);
        });
    });
  },

  addAlarmToFieldDeleteArray: ({ commit }, alarmId: number | string) => {
    if (typeof alarmId === 'number') commit('addAlarmToFieldDeleteArray', alarmId);
  },

  addAlarmToStatusDeleteArray: ({ commit }, statusName: string) => {
    commit('addAlarmToStatusDeleteArray', statusName);
  },

  deleteAlarms: async ({
    getters, rootGetters, dispatch, commit,
  }) => {
    const templateId = getters.getWorkOrderTemplateId;
    const deleteFieldAlarms = getters.getDeletedFieldAlarms;
    const deleteStatusAlarms = getters.getDeletedStatusAlarms;

    let deleteAlarms;

    // new array of field alarm id's and status alarm name strings
    if (deleteFieldAlarms && deleteStatusAlarms) deleteAlarms = [...deleteFieldAlarms, ...deleteStatusAlarms];
    else deleteAlarms = deleteFieldAlarms || deleteStatusAlarms;

    if (deleteAlarms.length) {
      // ids to delete (temp ids have been removed)
      deleteAlarms.forEach(async (deleteAlarm: number | string) => {
        let url;
        let apiCall;
        const dataObject = {};
        const baseUrl = `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}`;

        if (typeof deleteAlarm === 'string') {
          // delete status alarm on status name
          const statuses = rootGetters['templateStatus/getData'];
          const { id } = statuses.find((status: ITemplateStatus) => status.name === deleteAlarm);

          url = `${baseUrl}/statuses/${id}`;
          apiCall = 'PUT';
          Object.assign(dataObject, {
            name: deleteAlarm,
            outbound_message_id: null,
          });
        } else {
          // delete field alarm
          url = `${baseUrl}/alarms/${deleteAlarm}`;
          apiCall = 'DELETE';
        }

        await dispatch('auth/apiRequest', {
          url,
          options: {
            method: apiCall,
            data: dataObject,
          },
          backupError: 'Some Alarms could not be deleted. Please try again later.',
        }, { root: true });

        /* clear delete alarm arrays */
        commit('removeAlarmFromDeleteArray');
      });
    }
  },

  // @TODO need to delete alarms if whole message is deleted

  // Util
  resetStoreState: ({ commit }) => {
    commit('resetState');
  },
};
