import { GetterTree } from 'vuex';
import { CancelTokenSource } from 'axios';
import { formatDateString } from '@/utils';
import {
  IVersionListStoreState,
  IVuexRootStoreState,
  IVersionSearchFilter,
  ITemplateVersionListRecord,
  IPagination,
  ITemplateVersionListStoreStateFilter,
  ITemplateVersionSearchFilter,
} from '@/types';

export const VersionListStoreGetters: GetterTree<IVersionListStoreState, IVuexRootStoreState> = {
  getData: (state): ITemplateVersionListRecord[] => state.data,
  getTotal: (state): number => state.total,
  getLoading: (state): boolean => state.loading,
  getPagination: (state): IPagination => state.pagination,
  getFilter: (state): ITemplateVersionListStoreStateFilter => state.filter,
  getCancelSource: (state): CancelTokenSource => state.cancelSource,
  /**
   * Get filter and pagination formatted into api param format
   * @return {ITemplateSearchFilter} params & values to send
   */
  getFilterValue: (state): IVersionSearchFilter => {
    const { filter } = state;
    const { pagination } = state;

    let createAtAfter = null;
    let createAtBefore = null;

    if (filter.createdAt) {
      const createdAtArr = filter.createdAt.split(' ~ ');
      createAtAfter = formatDateString(createdAtArr[0]);
      createAtBefore = formatDateString(createdAtArr[1]);
    }

    let updatedAtAfter = null;
    let updatedAtBefore = null;

    if (filter.updatedAt) {
      const updatedAtArr = filter.updatedAt.split(' ~ ');
      updatedAtAfter = formatDateString(updatedAtArr[0]);
      updatedAtBefore = formatDateString(updatedAtArr[1]);
    }
    const filterObj = {
      'filter[identifier]': filter.identifier || null,
      'filter[description]': filter.description || null,
      'filter[version]': filter.version || null,
      'filter[created_by]': filter.createdBy || null,
      'filter[created_after]': createAtAfter,
      'filter[created_before]': createAtBefore,
      'filter[updated_after]': updatedAtAfter,
      'filter[updated_before]': updatedAtBefore,
      sort: `${filter.isDesc}${filter.sortBy}`,
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    } as ITemplateVersionSearchFilter;
    // removes any empty property
    return Object.keys(filterObj).reduce((accu, key) => {
      if (
        filterObj[key]
        || (typeof filterObj[key] === 'boolean' && !filterObj[key])
      ) accu[key] = filterObj[key];
      return accu;
    }, {} as IVersionSearchFilter);
  },
};
