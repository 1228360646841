var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"400","persistent":_vm.action === 'save' ? _vm.saveLoading : _vm.publishLoading},scopedSlots:_vm._u([(_vm.screenSize === 'large' && !_vm.isBackBtn)?{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"my-4 py-6",class:{'white--text' : _vm.action === 'publish'},attrs:{"id":_vm.action == 'save' ? 'template-edit__save-btn' : 'template-edit__publish-btn',"block":"","color":_vm.action == 'save' ? 'primary' : 'secondary',"loading":_vm.isLoading},on:{"click":_vm.openDialog}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.action === 'save' ? 'Save' : 'Publish')+" ")])]}}:(_vm.screenSize === 'small' && _vm.action==='save')?{key:"activator",fn:function(ref){
var ondialog = ref.on;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var ontooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"secondary"}},'v-btn',attrs,false),Object.assign({}, ondialog, ontooltip)),[_c('v-icon',[_vm._v("mdi-tray-arrow-up")])],1)]}}],null,true),model:{value:(_vm.tooltipShow),callback:function ($$v) {_vm.tooltipShow=$$v},expression:"tooltipShow"}},[_c('span',[_vm._v("Save")])])]}}:(_vm.screenSize === 'small' && _vm.action === 'publish' )?{key:"activator",fn:function(ref){
var ondialog = ref.on;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var ontooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"indigo"}},'v-btn',attrs,false),Object.assign({}, ondialog, ontooltip)),[_c('v-icon',[_vm._v("mdi-send-outline")])],1)]}}],null,true),model:{value:(_vm.tooltipShow),callback:function ($$v) {_vm.tooltipShow=$$v},expression:"tooltipShow"}},[_c('span',[_vm._v("Publish")])])]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.getPopupTitle)+" ")]),_c('v-card-text',{staticClass:"save-template__card-text"},[_vm._v(" "+_vm._s(_vm.getPopupMessage.text)+" ")]),(_vm.getPopupMessage.subtext)?_c('v-card-text',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getPopupMessage.subtext)+" ")]):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":"","disabled":_vm.action === 'save' ? _vm.saveLoading : _vm.publishLoading},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cancel ")]),(_vm.isBackBtn)?_c('v-btn',{attrs:{"color":"secondary","text":"","disabled":_vm.saveLoading},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" Confirm ")]):_vm._e(),_c('v-btn',{attrs:{"id":"saveTemplateBtn","loading":_vm.action === 'save' ? _vm.saveLoading : _vm.publishLoading,"color":"primary","text":""},on:{"click":function($event){return _vm.saveTemplateChanges()}}},[_vm._v(" "+_vm._s(_vm.action === 'save' ? 'Save' : 'Publish')+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }