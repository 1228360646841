import axios from 'axios';
import {
  ITemplateListStoreState,
} from '@/types';

/**
 * Get default store state
 * @return {ITemplateListStoreState}
 */
export const getDefaultState = (): ITemplateListStoreState => ({
  filter: {
    identifier: '',
    published: null,
    version: '',
    createdBy: '',
    createdAt: '',
    updatedAt: '',
    sortBy: '',
    isDesc: '',
  },
  pagination: { page: 1, itemsPerPage: 10, serverItemsLength: 0 },
  cancelSource: axios.CancelToken.source(),
  loading: false,
  unpublishedTemplateLoading: false,
  publishedTemplates: [],
  defaultGridSettings: [],
  defaultGridSettingTemplateField: [],
  unpublishedTemplates: [],
  data: [],
} as ITemplateListStoreState);
