import { Module } from 'vuex';
import {
  IVuexRootStoreState,
} from '@/types';
import { messageTemplateStore } from './messageTemplate';
import { templateStore } from './templates';
import { apiStore } from './api';

interface INewStore {
  appName?: string
}

export const newStore: Module<INewStore, IVuexRootStoreState> = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    api: apiStore,
    template: templateStore,
    messageTemplate: messageTemplateStore,
  },
};
