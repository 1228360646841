import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IOutboundStatusStoreState,
  ITemplateStatus,
} from '@/types';

export const TemplateStatusStoreActions: ActionTree<IOutboundStatusStoreState, IVuexRootStoreState> = {
  setTemplateId: ({ commit }, templateId) => {
    commit('setTemplateId', templateId);
  },

  loadData: async ({ getters, commit, dispatch }) => {
    commit('setLoading', true);

    const templateId = getters.getTemplateId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/statuses`,
    }, { root: true })
      .then((data) => commit('setData', data.data as ITemplateStatus[]))
      .finally(() => commit('setLoading', false));
  },
};
