import axios from 'axios';
import {
  ITableLookupsStoreState,
} from '@/types';

/**
 * Get default store state
 * @return {ITableLookupsStoreState}
 */
export const getDefaultState = (): ITableLookupsStoreState => ({
  filter: {
    key: '',
    type: '',
    sortBy: '',
    isDesc: '',
  },
  pagination: { page: 1, itemsPerPage: 10, serverItemsLength: 0 },
  cancelSource: axios.CancelToken.source(),
  loading: false,
  data: [],
  lookupTypes: [],
  dropdownLookups: {
    labour_role: [],
    resource_type: [],
    category: [],
    sub_category: [],
    commercial_category: [],
    unit_materials: [],
    unit_plant: [],
  },
} as ITableLookupsStoreState);
