import { GetterTree } from 'vuex';
import {
  IVuexRootStoreState, IMessageTemplateState, IMessageTemplateType, IMessageTemplate,
} from '@/types';

export const messageTemplateGetters: GetterTree<IMessageTemplateState, IVuexRootStoreState> = {
  getMessageTemplates: (state) => (type: IMessageTemplateType) => state[type],
  getMessageTemplateById: (state) => (type: IMessageTemplateType, id: string) => state[type].list.find((tmp: IMessageTemplate) => tmp.id === id),
  getMessageTemplatePagination: (state) => (type: IMessageTemplateType) => state[type].pagination,
};
