/* eslint-disable spaced-comment */
import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IAPIStoreState,
} from '@/types';

export const apiActions: ActionTree<IAPIStoreState, IVuexRootStoreState> = {
  /************************************************************************************************
   * @module WorkOrder
   * C, R, U
  ************************************************************************************************/

  /**
   *  @description
   *    Accepts a work order payload, and POSTs the new work order to the API.
   *  @param workOrder - The work order payload
   *  @author Jack O'Connor
   */
  createWorkOrder: async ({ dispatch }, workOrder) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders`,
    options: {
      method: 'POST',
      data: workOrder,
    },
    backupError: 'Failed to create Work Order',
  }, { root: true }),
  /**
   *  @description
   *    Fetches all work orders from the API with the optional argument of params
   *    to filter the call
   *  @param params - Optional filter params
   *  @author Jack O'Connor
   */
  fetchAllWorkOrders: async ({ dispatch }, { params, preservedKeys }) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders`,
    options: {
      method: 'GET',
      params,
    },
    preservedKeys,
    backupError: 'Failed to load Work Orders',
  }, { root: true }),
  /**
   *  @description
   *    Fetches an individual work order from the API using an ID argument
   * @param workOrderId - The ID of the work order
   * @author Jack O'Connor
   */
  fetchWorkOrderById: async ({ dispatch }, workOrderId) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workOrderId}`,
    options: {
      method: 'GET',
    },
    backupError: `Failed to load Work Order with ID: ${workOrderId}`,
  }, { root: true }),
  /**
   *  @description
   *    Sends an identifier to the validate API as a payload and expects a success
   *    with either a true or false value as a response, denoting whether or not the
   *    given identifier is valid for use or not.
   *  @param identifier - The identifier to be searched
   *  @author Jack O'Connor
   */
  validateIdentifier: async ({ dispatch }, identifier) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/validateidentifier`,
    options: {
      params: {
        identifier,
      },
    },
    backupError: `There was an error validating identifier: '${identifier}'`,
  }, { root: true }),
  /**
   *  @description
   *    Updates an existing workorder with new data
   *  @param workOrder - The new work order object
   *  @author Jack O'Connor
   *  @todo Only send fields that have changed to improve BE performance
   */
  updateWorkOrder: async ({ dispatch }, workOrder) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workOrder.id}`,
    options: {
      method: 'PUT',
      data: {
        sections: workOrder.sections,
      },
    },
    backupError: `Failed to update Work Order with ID: ${workOrder.id}`,
  }, { root: true }),
  /**
   *  @description
   *    Updates an individual custom field with a new value
   *  @param payload.work_order_id - The work order ID of which the field belongs
   *  @param payload.work_order_field_id - The fieldId property of the field in question (not the .id)
   *  @param payload.field_value - The new field value
   *  @author Jack O'Connor
   */
  updateWorkOrderField: async ({ dispatch }, {
    work_order_id,
    work_order_field_id,
    field_value,
  }) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${work_order_id}/fields/${work_order_field_id}`,
    options: {
      method: 'PUT',
      data: {
        value: field_value,
      },
    },
    backupError: `Failed to update Work Order field with ID: ${work_order_field_id}`,
  }, { root: true }),
  /**
   *  @summary Updates a workorders current status
   *  @param payload.nextStatus - The status object containing id and name at minimum
   *  @param payload.workOrderId - The ID of the work order to transition the status of
   *  @author Jack O'Connor
   */
  updateWorkOrderStatus: async ({ dispatch }, { nextStatus, workOrderId }) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workOrderId}`,
    options: {
      method: 'PUT',
      data: {
        status: nextStatus.id,
      },
    },
    backupError: `There was an issue updating this Work Order to the ${nextStatus.name} status`,
  }, { root: true }),

  /************************************************************************************************
   * @module WorkOrderTemplate
   * @operations R
  ************************************************************************************************/

  /**
   *  @description
   *    Fetches all work order templates from the API with the optional argument of params
   *    to filter the call.
   *  @param params - Object of string, value params for filtering
   *  @author Jack O'Connor
   */
  fetchWorkOrderTemplates: async ({ dispatch }, params: IAPIParams) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates`,
    options: {
      method: 'GET',
      params,
    },
    backupError: 'Failed to load work order templates',
  }, { root: true }),
  /**
   *  @summary Fetches a single work order template from the API
   *  @param templateId - The ID of the work order template
   *  @returns Promise<Template>
   *  @author Jack O'Connor
   */
  fetchWorkOrderTemplateById: async ({ dispatch }, templateId) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}`,
    options: {
      method: 'GET',
    },
    backupError: `Failed to load Work Order Template with ID: ${templateId}`,
  }, { root: true }),
  /**
   *  @summary Loads all work order statuses for a specific work order template
   *  @param templateId - The work order template ID
   *  @todo Revise statuses when template API passes statuses down natively.
   *  @author Jack O'Connor
   */
  loadTemplateStatuses: async ({ dispatch }, templateId) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/statuses`,
  }, { root: true }),

  /************************************************************************************************
   * @module User
   * @operations R, U
  ************************************************************************************************/

  /**
   *  @summary Fetches settings for the current user
   *  @author Jack O'Connor
   */
  fetchUserSettings: async ({ dispatch }) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/user/settings`,
    options: {
      method: 'GET',
    },
    backupError: 'Failed to load user settings',
  }, { root: true }),
  /**
   *  @summary Loads all employees from the GeoPal API
   *  @author Jack O'Connor
   */
  loadEmployees: async ({ dispatch }) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/geopalapi/employees`,
  }, { root: true }),
  /**
   *  @summary Loads all available grid setting headers from the user's parent company account
   *  @author Jack O'Connor
   */
  fetchAvailableGridSettingFields: async ({ dispatch }) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/companysettings/gridfields`,
  }, { root: true }),
  /**
   *  @summar Updates the users selected work order grid headers
   *  @param gridSettings - An array of updated grid headers
   *  @author Jack O'Connor
   */
  saveUserGridTemplateSettings: async ({ dispatch }, gridSettings) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/user/settings`,
    options: {
      method: 'PUT',
      data: {
        data: {
          grid_search_headers: gridSettings,
        },
      },
    },
    backupError: 'Encountered an issue while saving the grid template settings',
  }, { root: true }),

  /************************************************************************************************
   * @module Job
   * @operations C, R, U
  ************************************************************************************************/

  /**
   *  @summary Creates a job against a workorder in the JobList component
   *  @param payload.data - The data to submit to the job
   *  @param payload.workOrderId - The work order Id we're committing the job to
   *  @author Jack O'Connor
   */
  createJob: async ({ dispatch }, { data, workOrderId }) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workOrderId}/jobs`,
    options: {
      method: 'POST',
      data,
    },
    backupError: 'Job failed to create',
  }, { root: true }),
  /**
   *  @description
   *    Loads all non-deleted job templates for a given work order template
   *  @param woTemplateId - The ID of the work order template to load the job templates for
   *  @author Jack O'Connor
   */
  loadJobTemplates: async ({ dispatch }, woTemplateId) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${woTemplateId}/jobs`,
    options: {
      params: {
        is_deleted: false,
      },
    },
    backupError: `Failed to load job templates for Work Order Template with ID: ${woTemplateId}`,
  }, { root: true }),

  /**
   *  @summary Updates a job against a workorder in the JobList component
   *  @param payload.data - The updated data to submit to the job
   *  @param payload.workOrderId - The work order Id we're committing the job to
   *  @author Jack O'Connor
   */
  updateJob: async ({ dispatch }, { data, workOrderId }) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workOrderId}/jobs/${data.job_id}`,
    options: {
      method: 'PUT',
      data,
    },
    backupError: 'Job failed to update',
  }, { root: true }),

  /************************************************************************************************
   * @module File
   * @operations C, R, D
  ************************************************************************************************/

  /**
   *  @description
   *    Accepts file field data and a workorder and builds a formdata object
   *    and uploads it via the API
   *  @param payload.field - The full field object
   *  @param payload.workorder - The work order to save the field against (minimum: id, companyId)
   *  @author Jack O'Connor
   */
  uploadFile: async ({ dispatch }, { field, workorder }) => {
    const formData = new FormData();

    const file = field.value;

    formData.append('file', file, file.name);
    formData.append('company_id', workorder.companyId);
    formData.append('field_id', field.id);

    return dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workorder.id}/upload-file`,
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      },
      backupError: `Failed to upload file against Work Order with ID: ${workorder.id}`,
    }, { root: true });
  },
  /**
   *  @summary Bulk creates workorders from a bulk upload xlsx template
   *  @param payload.templateId - The Work Order Template Id the work orders are based on
   *  @param payload.uploadFile - The xlsx file object
   *  @author Jack O'Connor
   */
  bulkUploadWorkOrders: async ({ dispatch }, { templateId, uploadFile }) => {
    const formData = new FormData();
    formData.append('file', uploadFile);

    return dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/bulk/import`,
      options: {
        method: 'POST',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      backupError: `Failed to bulk upload against Work Order Template with ID: ${templateId}`,
    }, { root: true });
  },
  /**
   *  @description
   *    Calls the download file API and uses the response to call the downloadFile util
   *    which will download the file to the end-users machine
   *  @param file - The file to be downloaded (minimum: id, name)
   *  @param workOrderId - The ID of the workorder the file is from
   *  @author Jack O'Connor
   */
  downloadFile: async ({ dispatch }, { file, workOrderId }) => {
    const fileId = file.value.fileId || file.value.id;

    return dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workOrderId}/file/${fileId}/download`,
      options: {
        responseType: 'blob',
      },
      backupError: 'Failed to download file',
    }, { root: true });
  },
  /**
   *  @summary downloads boilerplate excel template for assisting bulk uploading templates
   *  @param templateId The Id of the work order template
   *  @author Jack O'Connor
   */
  downloadBulkUploadBoilerplate: async ({ dispatch }, templateId: string) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/bulk/export`,
    options: {
      method: 'GET',
      responseType: 'blob',
    },
    backupError: `Failed to download bulk upload file for Template with ID ${templateId}`,
  }, { root: true }),
  /**
   *  @summary Exports list of workorders based on the currently selected filters (if any)
   *  @param filter - The filter object
   *  @author Jack O'Connor
   */
  exportSearch: async ({ dispatch }, filter) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/export`,
    options: {
      params: filter,
    },
    backupError: 'There was an issue exporting your Work Orders',
    preservedKeys: filter,
  }, { root: true }),
  /**
   *  @description
   *    Accepts a field and a workorder and deletes the existing file against
   *    the work order field
   *  @param payload.field - The field which we are deleting the file from
   *  @param payload.workorder - The workorder the field belongs to (minimum: id)
   *  @author Jack O'Connor
   */
  deleteFile: async ({ dispatch }, { field, workorder }) => {
    // when refreshed api returns fileId however the getter for file information returns id
    // therefore delete file has to account for both
    const fileId = field.value.fileId ? field.value.fileId : field.value.id;

    return dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workorder.id}/file/${fileId}`,
      options: {
        method: 'DELETE',
      },
      backupError: `Failed to delete file for Work Order with ID: ${workorder.id}`,
    }, { root: true });
  },

  /************************************************************************************************
   * @module Logs
   * @operations R
  ************************************************************************************************/

  /**
   *  @summary Loads all outbound logs for a specific work order
   *  @param payload.workOrderId - The ID of the workorder in question
   *  @param payload.filter - The filter object to apply to the search
   *  @author Jack O'Connor
   */
  loadMessageLogs: async ({ dispatch }, { workOrderId, filter }) => {
    const preserve = ['filter[created_before]', 'filter[created_after]'];
    return dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workOrderId}/messagelog`,
      options: {
        params: filter,
      },
      preservedKeys: preserve,
    }, { root: true });
  },

  /**
   *  @summary Loads all inbound logs for a specific work order
   *  @param payload.workOrderId - The ID of the workorder in question
   *  @param payload.filter - The filter object to apply to the search
   *  @author Jack O'Connor
   */
  loadInboundLogs: async ({ dispatch }, filter) => {
    const preserve = ['filter[work_order_identifier]', 'filter[created_before]', 'filter[created_after]'];
    return dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/inboundlogs`,
      options: {
        params: filter,
      },
      preservedKeys: preserve,
    }, { root: true });
  },
  /**
   *  @summary Resends an outbound message
   *  @param payload.workOrderId - The workorder the outbound is against
   *  @param payload.messageLogId - The ID of the outbound message
   *  @author Jack O'Connor
   */
  resendMessage: async ({ dispatch }, { workOrderId, messageLogId }) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workOrderId}/messagelog/${messageLogId}`,
    options: {
      method: 'POST',
    },
    backupError: 'Failed to resend message. Please try again later.',
  }, { root: true }),
  /**
   *  @summary Rebuilds an outbound message
   *  @param payload.workOrderId - The workorder the outbound is against
   *  @param payload.messageLogId - The ID of the outbound message
   *  @author Jack O'Connor
   */
  rebuildMessage: async ({ dispatch }, { workOrderId, messageLogId }) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workOrderId}/messagelog/rebuild/${messageLogId}`,
    options: {
      method: 'POST',
    },
    backupError: 'Failed to rebuild message. Please try again later.',
  }, { root: true }),

  /************************************************************************************************
   * @module AuditTrail
   * @operations R
  ************************************************************************************************/

  /**
   *  @summary Gets all audit trail activities for a specific work order
   *  @param payload.workOrderId - The ID of the work order
   *  @param payload.filter - The filter to apply
   *  @author Jack O'Connor
   */
  fetchAuditTrails: async ({ dispatch }, { workOrderId, filter }) => dispatch('auth/apiRequest', {
    url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${workOrderId}/activities`,
    options: {
      params: filter,
    },
  }, { root: true }),
};

interface IAPIParams {
  [key: string]: string | number | boolean
}
