/**
 * This file should aggregate global functions that can be reutilized across
 * the application
 */

import { DateTime } from 'luxon';
import {
  ITemplateFieldConditionalLogicField, ITemplateSectionField, INewWorkOrderTemplate, IField,
} from './types';

/**
 * Generates an element selector id
 * @param {string} id Id to be used as prefix
 * @param {string} selector Name of the select
 * @return {string} String as ID_SELECTOR
 */
export const getElementId = (id: string, selector: string): string => `${id}-${selector}`;

/**
 * Function is used to take a Javascript date format and
 * return it in ISO format i.e 06/15/2016 to 2016-06-06
 */
export const formatDateString = (date: string): string => {
  const splitDate = date.split('/');
  const jSDate = new Date();
  jSDate.setDate(+splitDate[0]);
  jSDate.setFullYear(+splitDate[2]);
  jSDate.setMonth(+splitDate[1] - 1);
  return DateTime.fromJSDate(jSDate).toISODate()
    ? DateTime.fromJSDate(jSDate).toISODate()
    : '';
};

export const getDateTimeStringFromIso = (timestamp: string): string => DateTime.fromISO(timestamp).toFormat('D T');

/**
 *  @description
 *    takes an argument of function and provides the ability
 *    to set a timeout before executing other calls.
 *  @author Jack O'Connor
 */
export const delayCall = (() => {
  let timer = 0;
  return (callback: any, ms: number) => {
    clearTimeout(timer);
    timer = window.setTimeout(() => { callback(); }, ms);
  };
})();

/**
 *  @description
 *    Creating fieldData object which we can return for use
 *    in gathering all section fields and subsection fields.
 * @param field
 */
export const createFieldData = (field: ITemplateSectionField): ITemplateFieldConditionalLogicField => {
  const fieldData = {
    inboundMapping: field.inboundMapping,
    name: field.name,
    type: field.type,
    tempId: field.tempId,
    id: field.id || -1,
  };
  if (field.type === 'select' || field.type === 'table') {
    Object.defineProperty(fieldData, 'structure', {
      value: field.structure,
    });
  } else if (field.type === 'joblist') {
    if (field.jobListStructure) {
      Object.defineProperty(fieldData, 'jobListStructure', {
        value: field.jobListStructure,
      });
    } else if (field.structure) {
      Object.defineProperty(fieldData, 'structure', {
        value: field.structure,
      });
    }
  }

  return fieldData;
};

/**
 *  @summary Splits subsections from the section fields array so we can commit them separately
 *  @author Jack O'Connor
 */
export const splitSubsectionsFromFields = (wot: INewWorkOrderTemplate): INewWorkOrderTemplate => {
  const workorder = JSON.parse(JSON.stringify(wot));
  for (let index = 0; index < workorder.sections.length; index++) {
    if (workorder.sections[index].subSections && workorder.sections[index].subSections.length > 0) {
      workorder.sections[index].fields = workorder.sections[index].fields.filter((field: any) => field.type !== 'subSection');
    }
  }

  return workorder;
};

/**
 *  @summary A method for extracting a fields array from a template
 *  @param wot - The work order template to get the fields from
 *  @author Jack O'Connor
 */
export const getFieldsFromTemplate = (wot: INewWorkOrderTemplate): IField[] => {
  const template = splitSubsectionsFromFields(wot);

  let fields: any[] = [];
  for (let index = 0; index < template.sections.length; index++) {
    if (template.sections[index].fields && Array.isArray(template.sections[index].fields) && template.sections[index].fields.length >= 1) {
      fields.push({ header: template.sections[index].name });
      fields = fields.concat(template.sections[index].fields);
    }
  }

  return fields;
};
