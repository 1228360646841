import { Module } from 'vuex';
import {
  ITemplateListStoreState,
  IVuexRootStoreState,
} from '@/types';
import { TemplateListStoreGetters } from './getters';
import { TemplateListStoreActions } from './actions';
import { TemplateListStoreMutations } from './mutations';
import { getDefaultState } from './state';

export const TemplateListStore: Module<ITemplateListStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: TemplateListStoreGetters,
  actions: TemplateListStoreActions,
  mutations: TemplateListStoreMutations,
};
