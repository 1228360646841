import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IWorkOrderTemplateState,
} from '@/types';

export const templateActions: ActionTree<IWorkOrderTemplateState, IVuexRootStoreState> = {
  fetchWorkOrderTemplates: async ({ dispatch, commit }, params) => {
    await dispatch('new/api/fetchWorkOrderTemplates', params, { root: true })
      .then((response) => {
        commit('setWorkOrderTemplates', response.data);
      });
  },
};
