import { ActionTree } from 'vuex';
import { concat } from 'lodash';
import {
  ITemplateMappingStoreState,
  IVuexRootStoreState,
  IJobField,
  ITemplateSectionField,
  ITemplateWorkflowMappingItem,
  IVComboboxItem,
  ITemplateSection,
  ITableFieldColumns,
  IWOTemplateJobWorkflow,
  ITemplateSearchRecord,
} from '@/types';

export const TemplateMappingStoreActions: ActionTree<ITemplateMappingStoreState, IVuexRootStoreState> = {
  /**
   *  @summary return all template mappings (GET)
   *  @description
   *    On load of the template mappings view, load all available mapped templates
   *    with field and workflow mappings.
   *  @author Jack O'Connor
   */
  async loadTemplateMappings({ dispatch, commit }) {
    commit('setLoading', true);
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates`,
      options: {
        params: {
          'filter[has_workflow_mappings]': 1,
          'filter[has_field_mappings]': 1,
          with_versions: 1,
        },
      },
      backupError: 'There was a problem loading the templates. Please try again later.',
    }, { root: true })
      .then((result) => {
        result.data = result.data.map((record: ITemplateSearchRecord) => {
          Object.assign(record, {
            createdByName: record.user?.name,
          });
          return record;
        });
        commit('setMappedTemplates', result.data);
        commit('setLoading', false);
      });
  },
  /**
   * @summary Load all the jobs attached to a work order template
   * @author Jack O'Connor
   * @TODO Remove extra logic when name is passed back from jobs
   */
  async loadTemplateData({
    dispatch, getters, commit,
  }) {
    const workOrderTmpId = getters.getWorkOrderTemplateId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTmpId}/jobs`,
      backupError: 'There was a problem loading the jobs. Please try again later.',
    }, { root: true })
      .then(async (result) => {
        commit('setTemplateJobs', result.data);
      });
  },
  /**
   * @summary Load all the workflows attached to a job template
   * @author Jack O'Connor
   */
  async loadJobWorkflows({ dispatch, getters, commit }) {
    const jobTemplateId = getters.getJobTemplateId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/geopalapi/jobtemplate/${jobTemplateId}`,
      backupError: 'There was a problem loading job workflows. Please try again later.',
    }, { root: true })
      .then((data) => {
        const workflows: ITableFieldColumns[] = [];
        const fields: ITableFieldColumns[] = [];

        data.jobTemplate.jobTemplateWorkflows.forEach((item: IWOTemplateJobWorkflow) => {
          workflows.push({
            value: item.id,
            text: item.name,
          });
        });

        data.jobTemplate.jobTemplateFields.forEach((item: IWOTemplateJobWorkflow) => {
          fields.push({
            value: item.id,
            text: item.name,
          });
        });

        commit('setJobWorkflows', workflows);
        commit('setJobFields', fields);
      });
  },
  /**
   *  @summary Retrieves all custom fields from a work order template.
   *  @param templateId - the work order template ID to get the fields from.
   *  @author Jack O'Connor
   */
  async getCustomFields({ dispatch }, templateId) {
    const result = await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/customfields`,
    }, { root: true });

    return result.data;
  },
  /**
   *  @summary Get all workflow mappings for a job.
   *  @description
   *    Gets all workflow mappings for a job. Calls upon 'loadJobWorkflows' and 'getCustomFields' methods
   *    in order to match to the workflow IDs from the original call and retrieve the field names as required.
   *    Constructs an object in a format specifically for loading into a data table in the mappings edit comp.
   *  @param payload.templateId - The work order template ID of which the job is attached.
   *  @param payload.jobId - the Job which we want to get the workflows from.
   *  @author Jack O'Connor
   */
  async loadJobWorkflowMappings({ dispatch, commit, getters }, payload) {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${payload.templateId}/jobs/${payload.jobId}/workflows`,
      backupError: 'There was a problem loading your workflow mappings. Please try again later.',
    }, { root: true })
      .then(async (result) => {
        await dispatch('loadJobWorkflows');
        const workflows = getters.getJobWorkflows;
        const data = [];
        const fields = await dispatch('getCustomFields', payload.templateId);

        for (let index = 0; index < result.data.length; index++) {
          const foundWF = workflows.find((wf: any) => wf.value === result.data[index].workflowId);
          const foundField = fields.find((field: any) => field.id === result.data[index].customFieldId);
          if (foundWF && foundField) {
            data.push({
              id: result.data[index].id,
              jobWorkflowName: foundWF.text,
              jobWorkflowId: foundWF.value,
              description: result.data[index].options?.use || 'code',
              workOrderFieldName: foundField.name,
              workOrderFieldId: foundField.id,
            });
          }
        }

        commit('setWorkflowMappings', {
          templateId: payload.templateId,
          [payload.jobId]: data,
        });
      });
  },
  /**
   *  @description
   *    Loads mappings for a specific job, structures them as per the front-end headers require.
   *    Calls upon getCustomFields in order to retrieve the name of the work order field.
   *  @param payload.templateId - the WO template ID the job belongs to
   *  @param payload.jobId - The Job ID the fields belong to.
   *  @TODO - Update when BE send the name down with the field IDs
   *  @author Jack O'Connor
   */
  async loadJobFieldMappings({ dispatch, getters, commit }, payload) {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${payload.templateId}/jobs/${payload.jobId}/fields`,
      backupError: 'There was a problem loading your field mappings. Please try again later.',
    }, { root: true })
      .then(async (result) => {
        const jobFields = getters.getJobFields;
        const fields = await dispatch('getCustomFields', payload.templateId);
        const data = [];

        for (let index = 0; index < result.data.length; index++) {
          const foundJF = jobFields.find((wf: any) => wf.value === result.data[index].fieldId);
          const foundField = fields.find((field: any) => field.id === result.data[index].customFieldId);

          if (foundJF && foundField) {
            data.push({
              id: result.data[index].id,
              jobFieldName: foundJF.text,
              jobFieldId: foundJF.value,
              description: result.data[index].options?.use || 'code',
              workOrderFieldName: foundField.name,
              workOrderFieldId: foundField.id,
            });
          }
        }

        commit('setMappings', {
          templateId: payload.templateId,
          [payload.jobId]: data,
        });
        commit('setMappingsLoading', false);
      });
  },
  /**
   *  @summary Create field mappings to map work order field to job fields.
   *  @param maps - The field mappings to be created
   *  @author Jack O'Connor
   */
  async createFieldMappings({ dispatch, getters, commit }, maps) {
    commit('setLoading', true);
    const jobTemplateId = getters.getJobTemplateId;
    const WOTemplateId = getters.getWorkOrderTemplateId;
    const jobId = getters.getTemplateJobs.find((tj: any) => tj.jobTemplateId === jobTemplateId).id;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${WOTemplateId}/jobs/${jobId}/fields/multiple`,
      options: {
        method: 'PUT',
        data: {
          fields: maps,
        },
      },
      backupError: 'There was a problem saving your field mappings. Please try again later.',
    }, { root: true })
      .then(async (result) => {
        const mappings = getters.getMappings[WOTemplateId][jobId];

        // grab the IDs from the API result and set the new items local state with the ID.
        // if the item was deleted it won't exist in the results, therefore we will set the local
        // ID to null and filter the final commit param by existing ID to cut out any deleted items.
        const newmaps = mappings.map((item: any) => {
          const id = result.data.find((res: any) => item.workOrderFieldId === res.customFieldId && item.jobFieldId === res.fieldId)?.id;
          const opts = maps.find((map: any) => map.id === id)?.options;

          if (id) {
            item = { ...item, id, description: opts && opts.use ? opts.use : 'code' };
          } else {
            item.id = null;
          }

          return item;
        });

        commit('setMappings', {
          templateId: WOTemplateId,
          [jobId]: newmaps.filter((item: any) => item.id !== null),
        });

        await dispatch('deleteFieldMappings', {
          templateId: WOTemplateId,
          jobId,
        });
      })
      .finally(() => commit('setLoading', true));
  },
  /**
   *  @summary Create workflow mappings to job workflow steps to work order fields.
   *  @param maps - The workflow mappings to be created
   *  @author Jack O'Connor
   */
  async createWorkflowMappings({ dispatch, getters, commit }, maps) {
    commit('setLoading', true);
    const jobTemplateId = getters.getJobTemplateId;
    const WOTemplateId = getters.getWorkOrderTemplateId;
    const jobId = getters.getTemplateJobs.find((tj: any) => tj.jobTemplateId === jobTemplateId).id;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${WOTemplateId}/jobs/${jobId}/workflows/multiple`,
      options: {
        method: 'PUT',
        data: {
          workflows: maps,
        },
      },
      backupError: 'There was a problem saving your workflow mappings. Please try again later.',
    }, { root: true })
      .then(async (result) => {
        const mappings = getters.getWorkflowMappings[WOTemplateId][jobId];

        // grab the IDs from the API result and set the new items local state with the ID.
        // if the item was deleted it won't exist in the results, therefore we will set the local
        // ID to null and filter the final commit param by existing ID to cut out any deleted items.
        const newmaps = mappings.map((item: any) => {
          const id = result.data.find((res: any) => item.workOrderFieldId === res.customFieldId && item.jobWorkflowId === res.workflowId)?.id;
          const opts = maps.find((map: any) => map.id === id)?.options;

          if (id) {
            item = { ...item, id, description: opts && opts.use ? opts.use : 'code' };
          } else {
            item.id = null;
          }

          return item;
        });

        commit('setWorkflowMappings', {
          templateId: WOTemplateId,
          [jobId]: newmaps.filter((item: any) => item.id !== null),
        });

        await dispatch('deleteWorkflowMappings', {
          templateId: WOTemplateId,
          jobId,
        });
      })
      .finally(() => commit('setLoading', true));
  },
  /**
   *  @description
   *    Loops over a list of field mappings to be deleted, adds the id to a list and
   *    sends them to the workflows delete API to be deleted.
   *  @param payload.templateId - the WO template ID the job / workflows fall under
   *  @param payload.jobId - The ID of the job the workflows fall under.
   *  @author Jack O'Connor
   */
  async deleteFieldMappings({ dispatch, getters, commit }, payload) {
    const deleted = getters.getDeletedMappings.filter((map: any) => map.type === 'field');

    if (!deleted || deleted.length < 1) return;

    const ids: number[] = [];
    for (let index = 0; index < deleted.length; index++) {
      ids.push(deleted[index].id);
    }

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${payload.templateId}/jobs/${payload.jobId}/fields/multiple`,
      options: {
        method: 'DELETE',
        data: {
          job_field_mappings: ids,
        },
      },
    }, { root: true });

    commit('clearDeletedMappings', 'field');
    commit('setLoading', false);
  },
  /**
   *  @description
   *    Loops over a list of workflow mappings to be deleted, adds the id to a list and
   *    sends them to the workflows delete API to be deleted.
   *  @param payload.templateId - the WO template ID the job / workflows fall under
   *  @param payload.jobId - The ID of the job the workflows fall under.
   *  @author Jack O'Connor
   */
  async deleteWorkflowMappings({ dispatch, getters, commit }, payload) {
    const deleted = getters.getDeletedMappings.filter((map: any) => map.type === 'workflow');

    if (!deleted || deleted.length < 1) return;

    const ids: number[] = [];
    for (let index = 0; index < deleted.length; index++) {
      ids.push(deleted[index].id);
    }

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${payload.templateId}/jobs/${payload.jobId}/workflows/multiple`,
      options: {
        method: 'DELETE',
        data: {
          job_workflow_mappings: ids,
        },
      },
    }, { root: true });

    commit('clearDeletedMappings', 'workflow');
    commit('setLoading', false);
  },
  /**
   *  @summary Deleted a job associated to a work order template
   *  @param payload.templateId - The WO Template ID the Job is apart of
   *  @param payload.jobId - The ID of the job to delete
   *  @author Jack O'Connor
   */
  deleteTemplateJob: async ({ dispatch, commit }, payload) => {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${payload.templateId}/jobs/${payload.jobId}`,
      options: {
        method: 'DELETE',
      },
    }, { root: true }).then(() => {
      commit('deleteTemplateJob', payload.jobId);
      commit('deleteJobMappings', {
        templateId: payload.templateId,
        jobId: payload.jobId,
      });
    });
  },

  removeMapping: ({ commit }, id: number): void => {
    commit('removeMapping', id);
  },
  setJobTemplate: ({ commit }, jobTemplate: string) => {
    commit('setJobTemplate', jobTemplate);
  },

  setWorkOrderTemplate: ({ commit }, workOrderTemplate: string) => {
    commit('setWorkOrderTemplate', workOrderTemplate);
  },

  setWorkOrderFields: (
    { commit },
    workOrderFields: ITemplateSectionField[],
  ) => {
    commit('setWorkOrderFields', workOrderFields);
  },

  setJobFields: ({ commit }, jobFields: IJobField[]) => {
    commit('setJobFields', jobFields);
  },

  setWorkflowMappings: ({ commit }, mappings: ITemplateWorkflowMappingItem[]) => {
    commit('setWorkflowMappings', mappings);
  },

  loadWorkOrderFields: async ({ getters, commit, dispatch }) => {
    commit('setLoading', true);

    if (getters.getCancelSource) commit('cancelCancelSource');
    commit('generateCancelSource');
    const workOrderTemplateId = getters.getWorkOrderTemplateId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTemplateId}`,
      backupError: 'There was a problem loading the workorder fields.',
    }, { root: true })
      .then((data) => {
        const { sections } = data.data;
        let workOrderTemplateFields: IVComboboxItem[] = [];

        sections.forEach((section: ITemplateSection) => {
          workOrderTemplateFields = concat(
            workOrderTemplateFields,
            section.fields.map((field) => ({
              text: `${field.name} (${section.name})`,
              value: field.id,
              type: field.type,
              structure: field.structure,
            })),
          );
        });

        commit('setWorkOrderFields', workOrderTemplateFields);
      })
      .finally(() => commit('setLoading', false));
  },

  loadJobFields: async ({ getters, commit, dispatch }) => {
    commit('setLoading', true);
    const jobTemplateId = getters.getJobTemplateId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/geopalapi/jobtemplate/${jobTemplateId}`,
      backupError: 'There was a problem loading the job fields. Please try again later.',
    }, { root: true })
      .then((data) => commit(
        'setJobFields',
        data.jobTemplate.jobTemplateFields as IJobField[],
      ))
      .finally(() => commit('setLoading', false));
  },

  /**
   *  @description
   *     Gets all job workflow mappings for a wo template and sets mappedFields to an array
   *     of id's of work order template fields that are already mapped
   *  @param templateId - the wo template id to check job mappings against
   *  @author Ewa Murjas
   */
  async loadAllJobMappings({ dispatch, commit }, templateId) {
    commit('setLoading', true);
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/jobs`,
    }, { root: true })
      .then(async (data) => {
        const mappings = data.data.map((job: any) => dispatch('auth/apiRequest', {
          url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/jobs/${job.id}/workflows`,
        }, { root: true }));

        const allJobs = await Promise.all(mappings);

        const mappedFields = allJobs.flatMap((mappingData: any) => {
          if (mappingData.data.length > 0) {
            return mappingData.data.map((mapping: any) => mapping.customFieldId);
          }
          return [];
        });

        commit('setMappedFields', mappedFields);
      })
      .finally(() => commit('setLoading', false));
  },

  cancelAllCurrentMappings: async ({ getters, commit }) => {
    const woTemplateId = getters.getWorkOrderTemplateId;
    const jobRefId = getters.getJobTemplateRefId;
    const data = getters.getData;

    const { mappings, workflowMappings } = data;
    if (mappings.length > 0) delete mappings[woTemplateId][jobRefId];
    if (workflowMappings.length > 0) delete workflowMappings[woTemplateId][jobRefId];

    commit('updateAllCurrentMappings', { mappings, workflowMappings });
  },

  removeUnsavedMappings: async ({ getters, commit }) => {
    const woTemplateId = getters.getWorkOrderTemplateId;
    const jobRefId = getters.getJobTemplateRefId;
    const data = getters.getData;

    const { mappings, workflowMappings } = data;
    const unsavedFields = mappings.length > 0 ? mappings[woTemplateId][jobRefId] : [];
    const unsavedWfs = workflowMappings.length > 0 ? workflowMappings[woTemplateId][jobRefId] : [];

    // filter any fields with no ids
    const filteredFieldMappings = unsavedFields.filter((field: IJobField) => field.id);
    const filteredWorkflowMappings = unsavedWfs.filter((workflow: IWOTemplateJobWorkflow) => workflow.id);

    if (mappings.length > 0) mappings[woTemplateId][jobRefId] = filteredFieldMappings;
    if (workflowMappings.length > 0) workflowMappings[woTemplateId][jobRefId] = filteredWorkflowMappings;

    commit('updateAllCurrentMappings', { mappings, workflowMappings });
  },

  // Util
  resetStoreState: ({ commit }) => {
    commit('resetState');
  },
};
