import { GetterTree } from 'vuex';
import { CancelTokenSource } from 'axios';
import {
  IAuditTrailStoreState,
  IVuexRootStoreState,
  IAuditTrailRecord,
  IPagination,
  IAuditTrailStoreStateFilter,
} from '@/types';

export const AuditTrailStoreGetters: GetterTree<IAuditTrailStoreState, IVuexRootStoreState> = {
  getData: (state): IAuditTrailRecord[] => state.data,
  getLoading: (state): boolean => state.loading,
  getPagination: (state): IPagination => state.pagination,
  getFilter: (state): IAuditTrailStoreStateFilter => state.filter,
  getCancelSource: (state): CancelTokenSource => state.cancelSource,
};
