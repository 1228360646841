import { ActionTree } from 'vuex';
import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import {
  IVuexAuthState,
  IVuexRootStoreState,
  IAuthToken,
  IAPIPayload,
} from '@/types';

export const authActions: ActionTree<IVuexAuthState, IVuexRootStoreState> = {
  /**
   *  @description
   *    Receives a token from App.vue on the message event listener.
   *    Calls the tokenDecode method and stores the result into state
   *    We keep the original encoded string for authorisation of API calls
   *    in the token.value property
   *  @param token - The encoded token from the monolith iframe
   *  @author Jack O'Connor
   */
  async setAuthToken({ commit, dispatch }, token) {
    const val = await dispatch('tokenDecode', token); // the token from the iframe.
    val.value = token; // set the token string to the value of the decoded JWT

    commit('SET_AUTH_TOKEN', val);
    commit('SET_ACTIVE_AUTH', false);
  },
  /**
   *  @summary An action to decode a JWT
   *  @param token - The encoded string value of the JWT
   *  @author Jack O'Connor
   */
  tokenDecode(_, token: string): IAuthToken {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  },
  /**
   *  @description
   *    Generates a new authentication token for development only.
   *    Contains no refresh token. This is required as local testing
   *    does not rely on an iframe with a JWT parsed from the monolith,
   *    therefore we need to use this to utilise Geopal APIs.
   *  @development ONLY
   *  @author Jack O'Connor
   */
  async getAuthToken({ commit, dispatch }) {
    commit('SET_ACTIVE_AUTH', true);
    // Used for cancellations of the fetch request
    const controller = new AbortController();

    // GET requesting the token from the GeoPal API
    const tokenResponse = await fetch(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_AUTH_TOKEN_URL}`, {
      method: 'GET',
      signal: controller.signal,
    })
      .then((res) => res.json())
      .then(async (data) => {
        const newToken: IAuthToken = await dispatch('tokenDecode', data.data);
        newToken.value = data.data;

        return newToken;
      });

    commit('SET_AUTH_TOKEN', tokenResponse);
    commit('SET_ACTIVE_AUTH', false);

    return tokenResponse;
  },
  /**
   *  @summary Returns a valid token object for PRODUCTION
   *  @description
   *      Checks app state for a valid auth token object. Optionally pass in
   *      a value in seconds if the prior token is known to have a fixed expiry,
   *      this will ensure we always regenerate a token if it was created before then.
   *      Either return an existing valid token or return a newly generated token.
   *  @param {number} expireTime - An optional expiry of the existing token to take into account.
   *  @returns {IAuthToken}
   *  @production
   *  @author Jack O'Connor
   */
  async getAuthKey({ getters, dispatch, commit }) {
    let authKey: IAuthToken | null = null;
    const token = getters.GET_AUTH_TOKEN;
    const nowTime: any = new Date();

    if (token.value && token.exp && process.env.NODE_ENV === 'production') {
      // token does exist
      // real: <, test: >
      if (token.exp < nowTime / 1000) {
        commit('SET_ACTIVE_AUTH', true);

        const result = await fetch('https://app.test.geopalsolutions.co.uk/token/refresh', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: token.refreshToken,
          }),
        })
          .then((response) => response.json())
          .then(async (newToken) => {
            const newTokenObject = await dispatch('tokenDecode', newToken.data);
            newTokenObject.value = newToken.data;

            commit('SET_AUTH_TOKEN', newTokenObject);

            return newTokenObject;
          });

        commit('SET_ACTIVE_AUTH', false);

        authKey = result;
      } else {
        // token is still valid
        authKey = token;
      }
    } else if (token.value && token.exp && process.env.NODE_ENV !== 'production') {
      // Token exists built from the getAuthToken method
      if (token.exp < nowTime / 1000) {
        // token has expired
        authKey = await dispatch('getAuthToken');
      } else {
        // token is valid
        authKey = token;
      }
    } else if ((!token || !token.value) && process.env.NODE_ENV !== 'production') {
      authKey = await dispatch('getAuthToken');
    }

    return authKey;
  },
  /**
   *  @summary Performs an API call as requested
   *  @description
   *      Uses axios.request to perform an API call.
   *      accepts a payload of url for the endpoint,
   *      options for all other required options.
   *
   *  @link https://axios-http.com/docs/req_config
   *  @param {IAPIPayload} payload
   *  @returns Promise<unknown>
   *  @author Jack O'Connor
   */
  async apiRequest({ getters, dispatch }, {
    url,
    options = {},
    backupError,
  }: IAPIPayload) {
    // If a token call is already in progress we will wait for this to complete
    // before continuing so we're not making multiple API calls for a token at once.
    if (getters.GET_ACTIVE_AUTH) {
      await dispatch('tokenCallComplete');
    }

    const token = await dispatch('getAuthKey');

    let error = false;

    // Add the token to the headers of the call
    const headers = {
      Authorization: `Bearer ${token.value}`,
    };

    // If other headers already exist, merge with them, otherwise write a headers obj
    if (options.headers) {
      Object.assign(options.headers, headers);
    } else {
      options.headers = headers;
    }

    // Do not convert .value to _value when applying the below filters
    const caseOptions = {
      preservedKeys: ['filter[category.value]', 'filter[sub_category.value]', 'filter[commercial_category.value]', 'filter[unit.value]',
        'filter[labour_role.value]', 'filter[resource_type.value]'],
    };

    const ax = applyCaseMiddleware(axios.create(), caseOptions);

    const result = await ax.request({
      url,
      ...options,
    })
      .then((response) => response.data)
      .catch((err) => {
        error = true;

        let errorMessage = backupError;

        if (err.response?.data.msg) {
          errorMessage = `Error: ${err.response.data.msg}`;
        }

        if (errorMessage && errorMessage.length > 0) {
          dispatch('alerts/createAlert', {
            type: 'error',
            message: errorMessage,
          }, { root: true });
        }

        return err;
      });

    return error ? Promise.reject(result) : result;
  },
  /**
   *  @description
   *    Loops continuously until a activeAuth becomes false.
   *    Allows us to do this synchronously.
   *  @author Jack O'Connor
   */
  async tokenCallComplete({ getters }) {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (getters.GET_ACTIVE_AUTH) {
          return;
        }

        clearInterval(interval);
        resolve(true);
      }, 100);
    });
  },
};
