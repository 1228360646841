import { GetterTree } from 'vuex';
import {
  IVuexRootStoreState,
  IMessageLogStoreState,
  IMessageLogStoreStateFilter,
  IPagination,
  IMessageLogInboundSearchFilter,
  IMessageLogStoreStateOutboundFilter,
  IMessageLogOutboundSearchFilter,
} from '@/types';
import { formatDateString } from '@/utils';

export const MessageLogStoreGetters: GetterTree<IMessageLogStoreState, IVuexRootStoreState> = {
  getFilter: (state): IMessageLogStoreStateFilter => state.filter,
  getOutboundFilter: (state): IMessageLogStoreStateOutboundFilter => state.outboundFilter,
  getLoading: (state): boolean => state.loading,
  getWorkOrderTemplate: (state): string => state.templateId,
  getInboundLogs: (state) => state.inboundLogs,
  getOutboundLogs: (state) => state.outboundLogs,

  getFilterValue: (state) => {
    const { filter } = state;
    const { pagination } = state;

    let createAtAfter = null;
    let createAtBefore = null;

    if (filter.createdAt) {
      const createdAtArr = filter.createdAt.split(' ~ ');
      createAtAfter = formatDateString(createdAtArr[0]);
      createAtBefore = formatDateString(createdAtArr[1]);
    }

    const successFilter = typeof filter.success === 'string' ? filter.success : null;

    const filterObj = {
      'filter[work_order_identifier]': filter.workOrderIdentifier || null,
      'filter[created_after]': createAtAfter,
      'filter[created_before]': createAtBefore,
      'filter[status_code]': filter.statusCode || null,
      'filter[status_name]': filter.statusName || null,
      'filter[success]': successFilter,
      sort: `${filter.isDesc}${filter.sortBy}`,
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    } as IMessageLogInboundSearchFilter;
    // removes any empty property
    return Object.keys(filterObj).reduce((accu, key) => {
      if (
        filterObj[key]
        || (typeof filterObj[key] === 'boolean' && !filterObj[key])
      ) accu[key] = filterObj[key];
      return accu;
    }, {} as IMessageLogInboundSearchFilter);
  },

  getOutboundFilterValue: (state) => {
    const { outboundPagination } = state;
    const { outboundFilter } = state;

    let createAtAfter = null;
    let createAtBefore = null;

    if (outboundFilter.createdAt) {
      const createdAtArr = outboundFilter.createdAt.split(' ~ ');
      createAtAfter = formatDateString(createdAtArr[0]);
      createAtBefore = formatDateString(createdAtArr[1]);
    }

    const successFilter = typeof outboundFilter.success === 'string' ? outboundFilter.success : null;

    const filterObj = {
      'filter[work_order_identifier]': outboundFilter.workOrderIdentifier || null,
      'filter[message_name]': outboundFilter.messageName,
      'filter[created_after]': createAtAfter,
      'filter[created_before]': createAtBefore,
      'filter[status_name]': outboundFilter.statusName || null,
      'filter[action]': outboundFilter.action,
      'filter[success]': successFilter,
      sort: `${outboundFilter.isDesc}${outboundFilter.sortBy}`,
      page: outboundPagination.page,
      per_page: outboundPagination.itemsPerPage,
    } as IMessageLogOutboundSearchFilter;
    // removes any empty property
    return Object.keys(filterObj).reduce((accu, key) => {
      if (
        filterObj[key]
        || (typeof filterObj[key] === 'boolean' && !filterObj[key])
      ) accu[key] = filterObj[key];
      return accu;
    }, {} as IMessageLogOutboundSearchFilter);
  },

  getPagination: (state): IPagination => state.pagination,
  getOutboundPagination: (state): IPagination => state.outboundPagination,
};
