import { Module } from 'vuex';
import { IAlertStoreState, IVuexRootStoreState } from '@/types';
import { AlertStoreGetters } from './getters';
import { AlertStoreActions } from './actions';
import { AlertStoreMutations } from './mutations';

export const AlertStore: Module<IAlertStoreState, IVuexRootStoreState> = ({
  namespaced: true,
  state: {
    alerts: [],
  },
  getters: AlertStoreGetters,
  actions: AlertStoreActions,
  mutations: AlertStoreMutations,
});
