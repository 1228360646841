import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueRouter from 'vue-router';
import MavonEditor from 'mavon-editor';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);
Vue.use(VueRouter);
Vue.use(MavonEditor);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
