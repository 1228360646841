import { GetterTree } from 'vuex';
import { CancelTokenSource } from 'axios';
import { formatDateString } from '@/utils';
import {
  IVuexRootStoreState,
  ITemplateListStoreState,
  IPagination,
  ITemplateSearchRecord,
  ITemplateListStoreStateFilter,
  ITemplateSearchFilter,
  IGridSettingsSelectionField,
  IVComboboxItem,
} from '@/types';

export const TemplateListStoreGetters: GetterTree<ITemplateListStoreState, IVuexRootStoreState> = {
  getData: (state): ITemplateSearchRecord[] => state.data,
  getUnpublishedTemplatesData: (state): IVComboboxItem[] => state.unpublishedTemplates,
  getUnpublishedTemplateLoading: (state): boolean => state.unpublishedTemplateLoading,
  getDefaultGridSettingTemplateFields: (state): IGridSettingsSelectionField[] => state.defaultGridSettingTemplateField,
  getDefaultGridSettingTemplateFieldsSelections: (state): IGridSettingsSelectionField[] => state.defaultGridSettings,
  getPublishedTemplatesData: (state): ITemplateSearchRecord[] => state.publishedTemplates,
  getFilter: (state): ITemplateListStoreStateFilter => state.filter,
  /**
   * Get filter and pagination formatted into api param format
   * @return {ITemplateSearchFilter} params & values to send
   */
  getFilterValue: (state): ITemplateSearchFilter => {
    const { filter } = state;
    const { pagination } = state;

    let createAtAfter = null;
    let createAtBefore = null;

    if (filter.createdAt) {
      const createdAtArr = filter.createdAt.split(' ~ ');
      createAtAfter = formatDateString(createdAtArr[0]);
      createAtBefore = formatDateString(createdAtArr[1]);
    }

    let updatedAtAfter = null;
    let updatedAtBefore = null;

    if (filter.updatedAt) {
      const updatedAtArr = filter.updatedAt.split(' ~ ');
      updatedAtAfter = formatDateString(updatedAtArr[0]);
      updatedAtBefore = formatDateString(updatedAtArr[1]);
    }

    const filterObj = {
      'filter[identifier]': filter.identifier || null,
      'filter[published]':
        filter.published === null ? null : filter.published,
      'filter[version]': filter.version || null,
      'filter[created_after]': createAtAfter,
      'filter[created_before]': createAtBefore,
      'filter[updated_after]': updatedAtAfter,
      'filter[updated_before]': updatedAtBefore,
      sort: `${filter.isDesc}${filter.sortBy}`,
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    } as ITemplateSearchFilter;
    // removes any empty property
    return Object.keys(filterObj).reduce((accu, key) => {
      if (
        filterObj[key]
        || (typeof filterObj[key] === 'boolean' && !filterObj[key])
      ) accu[key] = filterObj[key];
      return accu;
    }, {} as ITemplateSearchFilter);
  },
  getPagination: (state): IPagination => state.pagination,
  getCancelSource: (state): CancelTokenSource => state.cancelSource,
  getLoading: (state): boolean => state.loading,
  getLoadingUnpublishedTemplates: (state): boolean => state.unpublishedTemplateLoading,
};
