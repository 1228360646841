import { Module } from 'vuex';
import { IVuexAuthState, IVuexRootStoreState } from '@/types';
import { authGetters } from './getters';
import { authActions } from './actions';
import { authMutations } from './mutations';

export const authStore: Module<IVuexAuthState, IVuexRootStoreState> = {
  namespaced: true,
  state: {
    token: {
      refreshToken: null,
      value: null,
      company_id: null,
      exp: null,
      iat: null,
      iss: null,
      name: null,
      user_id: null,
    },
    activeAuth: process.env.NODE_ENV === 'production',
  },
  getters: authGetters,
  actions: authActions,
  mutations: authMutations,
};
