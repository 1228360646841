import { GetterTree } from 'vuex';
import {
  IVuexRootStoreState,
  IJobTemplateStoreState,
  IJobTemplate,
} from '@/types';

export const JobTemplatesStoreGetters: GetterTree<IJobTemplateStoreState, IVuexRootStoreState> = {
  getData: (state: IJobTemplateStoreState): IJobTemplate[] => state.data,

  getCancelSource: (
    state: IJobTemplateStoreState,
  ): IJobTemplateStoreState['cancelSource'] => state.cancelSource,

  getLoading: (
    state: IJobTemplateStoreState,
  ): IJobTemplateStoreState['loading'] => state.loading,
};
