import axios from 'axios';
import {
  IConditionalLogicStoreState,
} from '@/types';

export const getDefaultState = (): IConditionalLogicStoreState => ({
  cancelSource: axios.CancelToken.source(),
  loading: false,
  data: [],
  statusData: [],
  deletedItems: [],
  addedItems: [],
  numberCalculateLogic: null,
} as IConditionalLogicStoreState);
