import axios from 'axios';
import {
  IInboundOutboundMappingStoreState,
  IWorkorderTemplateData,
  IOutboundMessage,
  IFieldConditions,
} from '@/types';

/**
 * Get default store state
 * @return {IInboundOutboundMappingStoreState}
 */
export const getDefaultState = (): IInboundOutboundMappingStoreState => ({
  data: {
    workOrderTemplate: '',
    messageName: '',
    mappings: [],
    alerts: [],
    deletedFieldAlerts: [],
    deletedStatusAlerts: [],
    mappedTemplates: [],
    templateData: {} as IWorkorderTemplateData,
    outboundMessages: [],
    editedOutboundMessage: {} as IOutboundMessage,
    endpoint: '',
    fieldConditions: {} as IFieldConditions,
  },
  cancelSource: axios.CancelToken.source(),
  loading: false,
  outboundMessageLoading: false,
} as IInboundOutboundMappingStoreState);
