import axios from 'axios';
import {
  IAuditTrailStoreState,
} from '@/types';

export const getDefaultState = (): IAuditTrailStoreState => ({
  filter: {
    id: '',
    field: '',
    action: '',
    user: '',
    date: '',
    sortBy: 'date',
    isDesc: '',
  },
  loading: false,
  pagination: { page: 1, itemsPerPage: 10, serverItemsLength: 0 },
  cancelSource: axios.CancelToken.source(),
  data: [],
} as IAuditTrailStoreState);
