import { MutationTree } from 'vuex';
import {
  IConditionalLogicStoreState,
  IConditionalLogicAlarm,
} from '@/types';

export const ConditionalLogicStoreMutations: MutationTree<IConditionalLogicStoreState> = {
  setData: (state: IConditionalLogicStoreState, data: IConditionalLogicAlarm[]) => {
    state.data = data;
  },
  addData: (state: IConditionalLogicStoreState, data: IConditionalLogicAlarm[]) => {
    data.forEach((item) => state.data.push(item));
  },
  setStatusData: (state: IConditionalLogicStoreState, data: IConditionalLogicAlarm[]) => {
    state.statusData = data;
  },

  addConditionalLogicAlarm: (state, conditionalLogicAlarm: IConditionalLogicAlarm) => {
    state.addedItems.push(conditionalLogicAlarm);
  },

  resetConditionalLogicAlarm: (state) => {
    state.addedItems = [];
    state.deletedItems = [];
  },

  /**
  *  @summary  Removes alarm from Added array and if alarm exists in DB then add to deleted Items
  *  @param {Object} conditionalLogicAlarm Alarm data (id, eventId - if exists in DB, text, value)
  *  @author EJ McVey
  */
  removeConditionalLogicAlarm: (state, conditionalLogicAlarm: IConditionalLogicAlarm) => {
    const foundAlarm = state.addedItems.findIndex((Element) => Element.id === conditionalLogicAlarm.id || Element.id === conditionalLogicAlarm.tempId);
    const foundStatusAlarm = state.statusData.findIndex((Element) => Element.id === conditionalLogicAlarm.id || Element.id === conditionalLogicAlarm.tempId);
    // if it hasn't been added to the backend yet just remove from the added array
    if (foundAlarm !== -1) state.addedItems.splice(foundAlarm, 1);
    else if (foundStatusAlarm !== -1) state.addedItems.splice(foundStatusAlarm, 1);

    else if (conditionalLogicAlarm.eventId) {
      state.deletedItems.push({
        alarmId: conditionalLogicAlarm.id || 0,
        eventId: conditionalLogicAlarm.eventId || 0,
      });
    }
  },

  setLoading: (state: IConditionalLogicStoreState, isLoading: boolean) => {
    state.loading = isLoading;
  },
};
