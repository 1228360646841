import axios from 'axios';
import {
  IOutboundStatusStoreState,
} from '@/types';

/**
 * Get default store state
 * @return {IOutboundStatusStoreState} Initial state
 */
export const getDefaultState = (): IOutboundStatusStoreState => ({
  cancelSource: axios.CancelToken.source(),
  loading: false,
  data: [],
  templateId: '',
});
