import { MutationTree } from 'vuex';
import axios from 'axios';
import {
  ITemplateMappingStoreState,
  IWorkorderTemplateData,
  ITemplateSectionField,
  IJobField,
  IWOTemplateJob,
} from '@/types';
import { getDefaultState } from './state';

export const TemplateMappingStoreMutations: MutationTree<ITemplateMappingStoreState> = {
  // cancelSource
  generateCancelSource: (state) => {
    state.cancelSource = axios.CancelToken.source();
  },
  cancelCancelSource: (state) => {
    state.cancelSource.cancel('cancel');
  },

  // Util
  setWorkOrderTemplate: (state, workOrderTemplate: string) => {
    state.data.workOrderTemplate = workOrderTemplate;
  },

  setTemplateJobs: (state, jobs: IWOTemplateJob[]) => {
    state.data.templateJobs = jobs;
  },

  setJobTemplate: (state, jobTemplate: string) => {
    state.data.jobTemplate = jobTemplate;
  },

  setJobTemplateRefId: (state, jobTemplateRefId: number) => {
    state.data.jobTemplateRefId = jobTemplateRefId;
  },

  setJobWorkflows: (state, workflows) => {
    state.data.jobWorkflows = workflows;
  },

  setLoading: (state, isLoading: boolean) => {
    state.loading = isLoading;
  },

  setMappingsLoading: (state, mappingsLoading: boolean) => {
    state.mappingsLoading = mappingsLoading;
  },

  setWorkOrderFields: (state, fields: ITemplateSectionField[]) => {
    state.data.workOrderFields = fields;
  },

  setMappedFields: (state, fieldIds) => {
    state.data.mappedFields = fieldIds;
  },

  setJobFields: (state, fields: IJobField[]) => {
    state.data.jobFields = fields;
  },
  /**
   *  @description
   *    Adds job field mappings to state with a key of the template ID.
   *    Allows us to retain all mappings in state due to the nature of the
   *    excessive API calls required to retrieve these mappings.
   *    This allows us to retain mappings across multiple templates for
   *    the duration of the session.
   *  @param mappings
   *  @author Jack O'Connor
   */
  setMappings: (state, mappings: any) => {
    const exists = Object.keys(state.data.mappings).find((map: any) => map === mappings.templateId);
    const { templateId } = mappings;
    delete mappings.templateId;

    const holder = state.data.mappings;

    if (exists) {
      Object.assign(holder[templateId], mappings);
    } else {
      holder[templateId] = mappings;
    }

    state.data.mappings = holder;
  },
  /**
   *  @description
   *    Adds workflow mappings to state with a key of the template ID.
   *    Allows us to retain all mappings in state due to the nature of the
   *    excessive API calls required to retrieve these mappings.
   *    This allows us to retain mappings across multiple templates for
   *    the duration of the session.
   *  @param mappings
   *  @author Jack O'Connor
   */
  setWorkflowMappings: (state, mappings: any) => {
    const exists = Object.keys(state.data.workflowMappings).find((map: any) => map === mappings.templateId);
    const { templateId } = mappings;
    delete mappings.templateId;

    const holder = state.data.workflowMappings;

    if (exists) {
      Object.assign(holder[templateId], mappings);
    } else {
      holder[templateId] = mappings;
    }

    state.data.workflowMappings = holder;
  },
  /**
   *  @summary Adds a mapping to state
   *  @param payload.templateId - The template ID attached to the job of the mapping
   *  @param payload.jobId - The jobId attached to the mapping
   *  @param payload.map - The mapping object itself
   *  @author Jack O'Connor
   */
  addMapping: (state, { templateId, jobId, map }) => {
    if (!state.data.mappings?.[templateId]?.[jobId]) state.data.mappings[templateId] = { [jobId]: [] };

    const holder = state.data.mappings;
    holder[templateId][jobId].push(map);
    state.data.mappings = null;
    state.data.mappings = holder;
  },
  /**
   *  @summary Adds a mapping to state
   *  @param payload.templateId - The template ID attached to the job of the mapping
   *  @param payload.jobId - The jobId attached to the mapping
   *  @param payload.map - The mapping object itself
   *  @author Jack O'Connor
   */
  addWorkflowMapping: (state, { templateId, jobId, map }) => {
    if (!state.data.workflowMappings?.[templateId]?.[jobId]) state.data.workflowMappings[templateId] = { [jobId]: [] };

    const holder = state.data.workflowMappings;
    holder[templateId][jobId].push(map);
    state.data.workflowMappings = null;
    state.data.workflowMappings = holder;
  },
  /**
   *  Until save is clicked in edit mappings we have to store delete mappings in a list
   *  and only delete until saved. Otherwise we will dispose of this list and keep the items.
   *  @param payload.id - the ID of the mapping to be deleted
   *  @param payload.type - The type of mapping e.g. field or workflow
   *  @author Jack O'Connor
   */
  addDeletedMappings: (state, payload) => {
    state.data.deletedMappings.push({
      id: payload.id,
      type: payload.type,
    });
  },
  clearDeletedMappings: (state, type) => {
    state.data.deletedMappings = state.data.deletedMappings.filter((map: any) => map.type !== type);
  },
  /**
   *  @description
   *    A mutation designed to remove mappings from state that do NOT have an ID.
   *    i.e. these mappings have been added in the edit panel, and subsequently
   *    removed from the mappings panel before a save has been performed.
   *    State is declared null and then re-set in order to invoke reactivity.
   *  @param payload.templateId - The template ID attached to the job mapping
   *  @param payload.jobId - The ID of the job which the mapping belongs
   *  @param payload.workOrderFieldId - The ID of the work order field in the mapping
   *  @param payload.jobFieldId - The ID of the jobField in the mapping
   *  @author Jack O'Connor
   */
  removeNewMapping: (state, {
    templateId, jobId, workOrderFieldId, jobFieldId,
  }) => {
    const holder = state.data.mappings;
    holder[templateId][jobId] = holder[templateId][jobId].filter(
      (map: any) => map.workOrderFieldId !== workOrderFieldId && map.jobFieldId !== jobFieldId,
    );

    state.data.mappings = null; // in order to invoke reactivity in computed props
    state.data.mappings = holder;
  },
  /**
   *  @description
   *    A mutation designed to remove WF mappings from state that do NOT have an ID.
   *    i.e. these mappings have been added in the edit panel, and subsequently
   *    removed from the mappings panel before a save has been performed.
   *    State is declared null and then re-set in order to invoke reactivity.
   *  @param payload.templateId - The template ID attached to the job mapping
   *  @param payload.jobId - The ID of the job which the mapping belongs
   *  @param payload.workOrderFieldId - The ID of the work order field in the mapping
   *  @param payload.jobWorkflowId - The ID of the jobWorkflow field in the mapping
   *  @author Jack O'Connor
   */
  removeNewWorkflowMapping: (state, {
    templateId, jobId, workOrderFieldId, jobWorkflowId,
  }) => {
    const holder = state.data.workflowMappings;
    holder[templateId][jobId] = holder[templateId][jobId].filter(
      (map: any) => map.workOrderFieldId !== workOrderFieldId && map.jobFieldId !== jobWorkflowId,
    );

    state.data.workflowMappings = null; // in order to invoke reactivity in computed props
    state.data.workflowMappings = holder;
  },
  deleteTemplateJob: (state, id) => {
    state.data.templateJobs = state.data.templateJobs.filter((job: IWOTemplateJob) => job.id !== id);
  },
  deleteJobMappings: (state, payload) => {
    const data = state.data.mappings;
    delete data[payload.templateId][payload.jobId];
    state.data.mappings = data;
  },
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },

  setMappedTemplates: (
    state: ITemplateMappingStoreState,
    mappedTemplates: IWorkorderTemplateData[],
  ) => {
    state.data.mappedTemplates = mappedTemplates;
  },

  updateAllCurrentMappings: async (state, payload) => {
    state.data.mappings = payload.mappings;
    state.data.workflowMappings = payload.workflowMappings;
  },
};
