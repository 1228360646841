import { GetterTree } from 'vuex';
import {
  IVuexRootStoreState,
  IReferenceListStoreState,
  IReferenceListStoreFilter,
  IPagination,
  IReferenceListSearchFilter,
  IReferenceListRecord,
} from '@/types';
import { formatDateString } from '@/utils';

export const ReferenceListStoreGetters: GetterTree<IReferenceListStoreState, IVuexRootStoreState> = {
  getFilter: (state): IReferenceListStoreFilter => state.filter,
  getLoading: (state): boolean => state.loading,
  getData: (state): IReferenceListRecord[] => state.data,

  getFilterValue: (state) => {
    const { filter } = state;
    const { pagination } = state;

    let createAtAfter = null;
    let createAtBefore = null;

    let startDate = null;
    let endDate = null;

    if (filter.createdAt) {
      const createdAtArr = filter.createdAt.split(' ~ ');
      createAtAfter = formatDateString(createdAtArr[0]);
      createAtBefore = formatDateString(createdAtArr[1]);
    }

    if (filter.startEndDate) {
      const startEndArr = filter.startEndDate.split(' ~ ');
      startDate = formatDateString(startEndArr[0]);
      endDate = formatDateString(startEndArr[1]);
    }

    const filterObj = {
      'filter[name]': filter.name || null,
      'filter[type]': filter.type || null,
      'filter[created_after]': createAtAfter,
      'filter[created_before]': createAtBefore,
      'filter[start_after]': startDate,
      'filter[end_before]': endDate,
      sort: `${filter.isDesc}${filter.sortBy}`,
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    } as IReferenceListSearchFilter;
    // removes any empty property
    return Object.keys(filterObj).reduce((accu, key) => {
      if (
        filterObj[key]
        || (typeof filterObj[key] === 'boolean' && !filterObj[key])
      ) accu[key] = filterObj[key];
      return accu;
    }, {} as IReferenceListSearchFilter);
  },

  getPagination: (state): IPagination => state.pagination,
};
