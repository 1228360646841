import { GetterTree } from 'vuex';
import { IVuexAuthState, IVuexRootStoreState, IAuthToken } from '@/types';

export const authGetters: GetterTree<IVuexAuthState, IVuexRootStoreState> = {
  /**
   *  @summary Return an auth token from state
   *  @returns {IAuthToken}
   *  @author Jack O'Connor
   */
  GET_AUTH_TOKEN(state): IAuthToken {
    return state.token;
  },
  /**
   *  @summary Returns a boolean determining if a token call is currently being made
   *  @returns {Boolean}
   *  @author Jack O'Connor
   */
  GET_ACTIVE_AUTH(state): boolean {
    return state.activeAuth;
  },
};
