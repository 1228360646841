import axios from 'axios';
import {
  ITemplateCustomisationStoreState,
} from '@/types';

/**
 * Get default store state
 * @return {ITemplateCustomisationStoreState} Initial state
 */
export const getDefaultState = (): ITemplateCustomisationStoreState => ({
  cancelSource: axios.CancelToken.source(),
  loading: false,
  saveLoading: false,
  data: null,
  filter: {
    id: '',
    version: '',
  },
  fieldConditions: [],
  showDeleteBox: false,
  inboundConfigured: false,
  outboundConfigured: false,
  templateMappingsConfigured: false,
});
