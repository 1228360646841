import axios from 'axios';
import {
  IVersionListStoreState,
} from '@/types';

export const getDefaultState = (): IVersionListStoreState => ({
  filter: {
    id: '',
    identifier: '',
    description: '',
    version: '',
    createdBy: '',
    createdAt: '',
    updatedAt: '',
    sortBy: 'updated_at',
    isDesc: '',
  },
  loading: false,
  total: 0,
  pagination: { page: 1, itemsPerPage: 10, serverItemsLength: 0 },
  cancelSource: axios.CancelToken.source(),
  data: [],
} as IVersionListStoreState);
