import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IAuditTrailStoreState,
} from '@/types';

export const AuditTrailStoreActions: ActionTree<IAuditTrailStoreState, IVuexRootStoreState> = {
  // Util
  resetStoreState: ({ commit }) => {
    commit('resetState');
  },
  // set template id
  setTemplateId: ({ commit }, templateId) => {
    commit('setTemplateId', templateId);
  },
  setFilterDate: ({ commit }, date: string[]) => {
    commit('setFilterUpdatedAt', date);
  },
  setFilterField: ({ commit }, field: string) => {
    commit('setFilterFieldName', field);
  },
  setFilterAction: ({ commit }, action: string) => {
    commit('setFilterPreviousValue', action);
  },
  setFilterUser: ({ commit }, user: string) => {
    commit('setFilterEditedBy', user);
  },
  setPaginationPage: ({ commit }, page: number) => {
    commit('setPaginationPage', page);
  },
  setPaginationItemsPerPage: ({ commit }, itemsPerPage: number) => {
    commit('setPaginationItemsPerPage', itemsPerPage);
  },
  setFilterIsDesc: ({ commit }, isDesc: '-' | '') => {
    commit('setFilterIsDesc', isDesc);
  },
  resetTemplateId: ({ commit }) => {
    commit('resetTemplateId');
  },
};
