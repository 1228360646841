import axios from 'axios';
import {
  ITemplateMappingStoreState,
} from '@/types';

/**
 * Get default store state
 * @return {ITemplateMappingStoreState}
 */
export const getDefaultState = (): ITemplateMappingStoreState => ({
  data: {
    workOrderTemplate: '',
    templateJobs: [],
    jobTemplate: '',
    jobTemplateRefId: null,
    workOrderFields: [],
    jobFields: [],
    mappings: [],
    jobWorkflows: [],
    workflowMappings: [],
    mappedTemplates: [],
    deletedMappings: [], // TODO clear state if back out
    mappedFields: [],
  },
  cancelSource: axios.CancelToken.source(),
  loading: false,
  mappingsLoading: false,
} as ITemplateMappingStoreState);
