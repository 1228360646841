import { MutationTree } from 'vuex';
import axios from 'axios';
import {
  ITableLookupsRecord,
  ITableLookupsStoreState,
} from '@/types';
import { getDefaultState } from './state';

export const TableLookupsStoreMutations: MutationTree<ITableLookupsStoreState> = {
  setData: (state, data) => {
    state.data = data;
  },
  setLookupTypes: (state, lookupTypes) => {
    state.lookupTypes = lookupTypes;
  },

  setLookupByType: (state, lookupData: { type: 'labour_role' | 'resource_type' | 'category' | 'sub_category' | 'commercial_category'
  | 'unit_materials' | 'unit_plant', items: ITableLookupsRecord[] }) => {
    state.dropdownLookups[lookupData.type] = lookupData.items;
  },

  // Filter
  setFilterSortBy: (state, sort: string) => {
    let sortValue = 'created_at';

    if (sort === 'createdAt') sortValue = 'created_at';
    else if (sort) sortValue = sort;

    state.filter.sortBy = sortValue;
  },

  setFilterIsDesc: (state, isDesc: '-' | '') => {
    state.filter.isDesc = isDesc;
  },

  // Pagination
  setPaginationPage: (state, page: number) => {
    state.pagination.page = page;
  },
  setPaginationItemsPerPage: (state, itemsPerPage: number) => {
    state.pagination.itemsPerPage = itemsPerPage;
  },
  setPaginationServerItemsLength: (state, itemsLength: number) => {
    state.pagination.serverItemsLength = itemsLength;
  },

  // cancelSource
  generateCancelSource: (state) => {
    state.cancelSource = axios.CancelToken.source();
  },
  cancelCancelSource: (state) => {
    state.cancelSource.cancel('cancel');
  },

  setLoading: (state, isLoading: boolean) => {
    state.loading = isLoading;
  },

  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};
