import { MutationTree } from 'vuex';
import {
  IInboundOutboundMappingStoreState,
  IWorkorderTemplateData,
  IOutboundAlert,
  IOutboundMappingItem,
  IOutboundMessage,
  IFieldConditions,
} from '@/types';
import { getDefaultState } from './state';

export const OutboundMappingStoreMutations: MutationTree<IInboundOutboundMappingStoreState> = {
  setLoading: (
    state: IInboundOutboundMappingStoreState,
    isLoading: boolean,
  ) => {
    state.loading = isLoading;
  },

  setOutboundMessageLoading: (
    state: IInboundOutboundMappingStoreState,
    isLoading: boolean,
  ) => {
    state.outboundMessageLoading = isLoading;
  },

  setWorkOrderTemplate: (state, workOrderTemplate: string) => {
    state.data.workOrderTemplate = workOrderTemplate;
  },

  setMessageName: (state, messageName: string) => {
    state.data.messageName = messageName;
  },

  setMappings: (state, mappings: IOutboundMappingItem[]) => {
    state.data.mappings = mappings;
  },

  setAlerts: (state, alerts: IOutboundAlert[]) => {
    state.data.alerts = alerts;
  },

  setMappedTemplates: (
    state: IInboundOutboundMappingStoreState,
    mappedTemplates: IWorkorderTemplateData[],
  ) => {
    state.data.mappedTemplates = mappedTemplates;
  },

  setTemplateData: (
    state: IInboundOutboundMappingStoreState,
    templateData: IWorkorderTemplateData,
  ) => {
    state.data.templateData = templateData;
  },

  setOutboundMessages: (state, outboundMessages: IOutboundMessage[]) => {
    state.data.outboundMessages = outboundMessages;
  },

  setEditedOutboundMessage: (state, outboundMessage: IOutboundMessage) => {
    state.data.editedOutboundMessage = outboundMessage;
  },
  /**
   *  @summary Remove a single outbound message from state based on the parsed ID
   *  @param id - ID of the outbound message to delete
   *  @author Jack O'Connor
   */
  removeOutboundMessage: (state, id: number) => {
    if (state.data.outboundMessages && state.data.outboundMessages.length > 0) {
      state.data.outboundMessages = state.data.outboundMessages.filter((msg) => msg.id !== id);
    }
  },

  setEndpoint: (state, endpoint: string) => {
    state.data.endpoint = endpoint;
  },

  setFieldConditions: (state, fieldConditions: IFieldConditions) => {
    state.data.fieldConditions = fieldConditions;
  },

  removeAlarmFromDeleteArray: (state) => {
    state.data.deletedStatusAlerts = [];
    state.data.deletedFieldAlerts = [];
  },

  addAlarmToFieldDeleteArray: (state, alarmId: number) => {
    state.data.deletedFieldAlerts?.push(alarmId);
  },

  addAlarmToStatusDeleteArray: (state, statusName: string) => {
    state.data.deletedStatusAlerts?.push(statusName);
  },

  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};
