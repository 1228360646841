import { GetterTree } from 'vuex';
import {
  IVuexRootStoreState,
  ITemplateCustomisationStoreState,
  ISectionSelectFieldGetter,
  IWorkorderTemplateData,
  ITemplateFieldConditionalLogicField,
  IVComboboxItem,
} from '@/types';
import { createFieldData } from '@/utils';

export const TemplateCustomisationGetters: GetterTree<ITemplateCustomisationStoreState, IVuexRootStoreState> = {
  getData: (
    state: ITemplateCustomisationStoreState,
  ): IWorkorderTemplateData | null => state.data,

  getLoading: (
    state: ITemplateCustomisationStoreState,
  ): ITemplateCustomisationStoreState['loading'] => state.loading,

  getSaveLoading: (
    state: ITemplateCustomisationStoreState,
  ): ITemplateCustomisationStoreState['saveLoading'] => state.saveLoading,

  getTemplateId: (state: ITemplateCustomisationStoreState): string => state.filter.id,

  getTemplateVersion: (state: ITemplateCustomisationStoreState): string => state.filter.version,

  getInboundConfigured: (state: ITemplateCustomisationStoreState): boolean => state.inboundConfigured,

  getOutboundConfigured: (state: ITemplateCustomisationStoreState): boolean => state.outboundConfigured,

  getMappingsConfigured: (state: ITemplateCustomisationStoreState): boolean => state.templateMappingsConfigured,

  getSectionFields:
    (state: ITemplateCustomisationStoreState) => (sectionName: string) => {
      if (!state.data) return '';

      const foundSection = state.data.sections.find((section) => section.name === sectionName);
      return foundSection || '';
    },

  getFieldConditions: (state: ITemplateCustomisationStoreState): IVComboboxItem[] => state.fieldConditions,

  getSectionFieldData:
    (state: ITemplateCustomisationStoreState) => (sectionFieldData: any) => {
      const { sectionName, fieldIndex, subsectionIndex } = sectionFieldData;
      if (!state.data) return '';

      let sectionFound: any = state.data.sections.find((section) => section.name === sectionName);

      if (typeof subsectionIndex !== 'undefined' && sectionFound) {
        sectionFound = sectionFound.fields[subsectionIndex];
      }

      return sectionFound ? sectionFound.fields[fieldIndex] : '';
    },

  getSectionSelectFieldData:
    (state: ITemplateCustomisationStoreState) => (sectionFieldData: ISectionSelectFieldGetter) => {
      const { fieldId, tempId } = sectionFieldData;
      if (!state.data) return '';
      const sectionFound = state.data.sections.find((section) => section.fields.find((field) => field.id === fieldId));
      let selectFieldData = sectionFound?.fields.find((field) => field.id === fieldId);
      if (!selectFieldData) selectFieldData = sectionFound?.fields.find((field) => field.tempId === tempId);
      return selectFieldData;
    },

  getSectionNames:
   (state: ITemplateCustomisationStoreState) => {
     if (!state.data) return '';
     return state.data.sections.map((section) => section.name);
   },

  getDeleteBoxState: (
    state: ITemplateCustomisationStoreState,
  ): boolean => state.showDeleteBox,

  /**
   * Returns all fields from every section for a template.
   * @returns '@/types/ITemplateFieldConditionalLogicField'[]
   */
  getAllSectionsFields: (state: ITemplateCustomisationStoreState) => {
    if (!state.data) return '';

    const sectionFields = [] as ITemplateFieldConditionalLogicField[];
    /* loop through all sections looping through every field */
    state.data.sections.forEach(
      (section) => section.fields.forEach((field) => {
        const fieldData = createFieldData(field);
        sectionFields.push(fieldData);

        if (field.type === 'subSection') {
          field.fields?.forEach((subField) => {
            const subFieldData = createFieldData(subField);
            sectionFields.push(subFieldData);
          });
        }
      }),
    );

    return sectionFields || '';
  },
};
