import { Module } from 'vuex';
import {
  IJobTemplateStoreState,
  IVuexRootStoreState,
} from '@/types';
import { JobTemplatesStoreGetters } from './getters';
import { JobTemplatesStoreActions } from './actions';
import { JobTemplatesStoreMutations } from './mutations';
import { getDefaultState } from './state';

export const JobTemplateStore: Module<IJobTemplateStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: JobTemplatesStoreGetters,
  actions: JobTemplatesStoreActions,
  mutations: JobTemplatesStoreMutations,
};
