import { GetterTree } from 'vuex';
import {
  IVuexRootStoreState,
  IReferenceListItemsStoreState,
  IReferenceListLabourItem,
  IEmployee,
  IReferenceListMaterialItem,
  IReferenceListItemsStoreFilter,
  IReferenceListItemsSearchFilter,
  IPagination,
} from '@/types';

export const ReferenceListItemsStoreGetters: GetterTree<IReferenceListItemsStoreState, IVuexRootStoreState> = {
  getLoading: (state): boolean => state.loading,
  getData: (state): IReferenceListLabourItem[] | IReferenceListMaterialItem[] => state.data,
  getListId: (state): string => state.listId,
  getEmployees: (state): IEmployee[] => state.employees,
  getFilter: (state): IReferenceListItemsStoreFilter => state.filter,

  getFilterValue: (state) => {
    const { filter } = state;
    const { pagination } = state;

    const filterObj = {
      'filter[id]': filter.id,
      'filter[employee_id]': filter.employeeId,
      'filter[employee_name]': filter.employeeName,
      'filter[labour_role.value]': filter.labourRole,
      'filter[team]': filter.team,
      'filter[resource_type.value]': filter.resourceType,
      'filter[contract]': filter.contract,
      'filter[payroll_number]': filter.payrollNumber,
      'filter[description]': filter.description,
      'filter[category.value]': filter.category,
      'filter[sub_category.value]': filter.subCategory,
      'filter[commercial_category.value]': filter.commercialCategory,
      'filter[manufacturers_code]': filter.manufacturersCode,
      'filter[unit.value]': filter.unit,
      'filter[rate]': filter.rate,
      sort: `${filter.isDesc}${filter.sortBy}`,
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    } as IReferenceListItemsSearchFilter;

    // removes any empty property
    return Object.keys(filterObj).reduce((accu, key) => {
      if (
        filterObj[key]
        || (typeof filterObj[key] === 'boolean' && !filterObj[key])
      ) accu[key] = filterObj[key];
      return accu;
    }, {} as IReferenceListItemsSearchFilter);
  },

  getPagination: (state): IPagination => state.pagination,
};
