import { MutationTree } from 'vuex';
import { IAuthToken, IVuexAuthState } from '@/types';

export const authMutations: MutationTree<IVuexAuthState> = {
  /**
   *  @summary Set an auth token into state
   *  @param {IAuthToken} token - An auth token object
   *  @author Jack O'Connor
   */
  SET_AUTH_TOKEN(state, token: IAuthToken) {
    state.token = token;
  },
  /**
   *  @summary Sets active auth boolean
   *  @param active - Boolean
   *  @author Jack O'Connor
   */
  SET_ACTIVE_AUTH(state, active: boolean) {
    state.activeAuth = active;
  },
};
