import { GetterTree } from 'vuex';
import {
  IInboundOutboundMappingStoreState,
  IVuexRootStoreState,
  IOutboundMessage,
  IInboundOutboundMappingRecord,
  IWorkorderTemplateData,
  IOutboundAlert,
} from '@/types';

export const OutboundMappingStoreGetters: GetterTree<IInboundOutboundMappingStoreState, IVuexRootStoreState> = {
  getData: (state): IInboundOutboundMappingRecord => state.data,
  getTemplateData: (state): IWorkorderTemplateData | undefined => state.data.templateData,
  getWorkOrderTemplateId: (state): string => state.data.workOrderTemplate,
  getMappings: (state) => state.data.mappings,
  getAlerts: (state): IOutboundAlert[] | null => {
    if (state.data.alerts) return state.data.alerts;
    return null;
  },
  getMappedTemplates: (
    state: IInboundOutboundMappingStoreState,
  ): IWorkorderTemplateData[] | undefined => state.data.mappedTemplates,
  getOutboundMessages: (
    state: IInboundOutboundMappingStoreState,
  ): IOutboundMessage[] | [] => state.data.outboundMessages || [],
  getLoading: (
    state: IInboundOutboundMappingStoreState,
  ): IInboundOutboundMappingStoreState['loading'] => state.loading,
  getOutboundMessageLoading: (
    state: IInboundOutboundMappingStoreState,
  ): IInboundOutboundMappingStoreState['outboundMessageLoading'] => state.outboundMessageLoading,
  getEditedOutboundMessage: (
    state: IInboundOutboundMappingStoreState,
  ): IOutboundMessage | undefined => state.data.editedOutboundMessage,
  getMessageName: (
    state: IInboundOutboundMappingStoreState,
  ): string | undefined => state.data.messageName,
  getEndpoint: (
    state: IInboundOutboundMappingStoreState,
  ): string | undefined => state.data.endpoint,
  getFieldConditions: (
    state: IInboundOutboundMappingStoreState,
  ) => state.data.fieldConditions,
  getDeletedFieldAlarms: (
    state: IInboundOutboundMappingStoreState,
  ) => state.data.deletedFieldAlerts,
  getDeletedStatusAlarms: (
    state: IInboundOutboundMappingStoreState,
  ) => state.data.deletedStatusAlerts,
};
