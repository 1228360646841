import { GetterTree } from 'vuex';
import {
  IOutboundStatusStoreState,
  IVuexRootStoreState,
  ITemplateStatus,
} from '@/types';

export const TemplateStatusStoreGetters: GetterTree<IOutboundStatusStoreState, IVuexRootStoreState> = {
  getData: (state: IOutboundStatusStoreState): ITemplateStatus[] => state.data,

  getTemplateId: (state: IOutboundStatusStoreState): string => state.templateId,

  getCancelSource: (
    state: IOutboundStatusStoreState,
  ): IOutboundStatusStoreState['cancelSource'] => state.cancelSource,

  getLoading: (
    state: IOutboundStatusStoreState,
  ): IOutboundStatusStoreState['loading'] => state.loading,
};
