import { Module } from 'vuex';
import {
  IVuexRootStoreState,
  IMessageTemplateState,
} from '@/types';
import { messageTemplateGetters } from './getters';
import { messageTemplateActions } from './actions';
import { messageTemplateMutations } from './mutations';

export const messageTemplateStore: Module<IMessageTemplateState, IVuexRootStoreState> = {
  namespaced: true,
  state: {
    mail: {
      list: [],
      lastCalled: null,
      pagination: {
        per_page: 10,
        page: 1,
        total: 0,
      },
    },
    sms: {
      list: [],
      lastCalled: null,
      pagination: {
        per_page: 10,
        page: 1,
        total: 0,
      },
    },
  },
  getters: messageTemplateGetters,
  actions: messageTemplateActions,
  mutations: messageTemplateMutations,
};
