import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IVersionListStoreState,
  IVersionDuplicateObject,
  ITemplateVersionListRecord,
} from '@/types';

export const VersionListStoreActions: ActionTree<IVersionListStoreState, IVuexRootStoreState> = {
  /**
   * Gets list of templates based on defined filters and populates data state
   * loading state is also updated
   */
  loadData: async ({ commit, getters, dispatch }) => {
    commit('setLoading', true);

    const templateId = getters.getFilter.id;
    const filterParams = getters.getFilterValue;
    if (filterParams.per_page === -1) delete filterParams.per_page;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/versions`,
      options: {
        params: filterParams,
      },
    }, { root: true })
      .then((data) => {
        const versionItems = data.data.data;
        commit('setPaginationServerItemsLength', data.data.total as number);
        commit('setData', versionItems as ITemplateVersionListRecord);
        commit('setTotal', data.data.data.length);
      })
      .finally(() => commit('setLoading', false));
  },
  /**
  * Duplicate template function
  */
  duplicateTemplates: async ({ commit, getters, dispatch }, updateFieldObject: IVersionDuplicateObject) => {
    commit('setLoading', true);

    const templateId = getters.getFilter.id;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/versions`,
      options: {
        method: 'POST',
        data: updateFieldObject,
      },
      backupError: 'Could not duplicate template version. Please try again later.',
    }, { root: true })
      .then(async () => {
        /**
         * @TODO Review whether a reload is necessary or if we could use the response instead.
         */
        await dispatch('loadData');
        commit('setLoading', false);
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Template version duplicated successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },
  // Util
  resetStoreState: ({ commit }) => {
    commit('resetState');
  },
  // set template id
  setTemplateId: ({ commit }, templateId) => {
    commit('setTemplateId', templateId);
  },
  setFilterUpdatedAt: ({ commit }, updatedAt: string[]) => {
    commit('setFilterUpdatedAt', updatedAt);
  },
  setPaginationPage: ({ commit }, page: number) => {
    commit('setPaginationPage', page);
  },
  setPaginationItemsPerPage: ({ commit }, itemsPerPage: number) => {
    commit('setPaginationItemsPerPage', itemsPerPage);
  },
  setFilterIsDesc: ({ commit }, isDesc: '-' | '') => {
    commit('setFilterIsDesc', isDesc);
  },
  resetTemplateId: ({ commit }) => {
    commit('resetTemplateId');
  },
};
