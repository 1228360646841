import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IJobTemplateStoreState,
  IJobTemplate,
} from '@/types';

export const JobTemplatesStoreActions: ActionTree<IJobTemplateStoreState, IVuexRootStoreState> = {
  /**
   * Get the list of all Job Templates
   */
  loadData: async ({ dispatch, commit }) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/geopalapi/jobtemplates`,
      options: {
        params: {
          is_deleted: false,
        },
      },
    }, { root: true })
      .then((data) => {
        commit('setData', data.jobTemplates as IJobTemplate[]);
      })
      .finally(() => commit('setLoading', false));
  },
};
