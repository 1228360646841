import { Module } from 'vuex';
import {
  IReferenceListStoreState,
  IVuexRootStoreState,
} from '@/types';
import { ReferenceListStoreGetters } from './getters';
import { ReferenceListStoreActions } from './actions';
import { ReferenceListStoreMutations } from './mutations';
import { getDefaultState } from './state';

export const ReferenceListStore: Module<IReferenceListStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: ReferenceListStoreGetters,
  actions: ReferenceListStoreActions,
  mutations: ReferenceListStoreMutations,
};
