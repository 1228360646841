import { Module } from 'vuex';
import {
  ITableLookupsStoreState,
  IVuexRootStoreState,
} from '@/types';
import { TableLookupsStoreGetters } from './getters';
import { TableLookupsStoreActions } from './actions';
import { TableLookupsStoreMutations } from './mutations';
import { getDefaultState } from './state';

export const TableLookupsStore: Module<ITableLookupsStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: TableLookupsStoreGetters,
  actions: TableLookupsStoreActions,
  mutations: TableLookupsStoreMutations,
};
