import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { IVuexRootStoreState } from '@/types';
import { InboundMappingStore } from './InboundMappingStore';
import { JobTemplateStore } from './JobTemplateStore';
import { OutboundMappingStore } from './OutboundMappingStore';
import { TemplateCustomisationDataStore } from './TemplateCustomisationDataStore';
import { TemplateListStore } from './TemplateListStore';
import { TemplateMappingStore } from './TemplateMappingStore';
import { TemplateStatusStore } from './TemplateStatusStore';
import { VersionListStore } from './VersionListStore';
import { WorkOrderTemplateStore } from './WorkOrderTemplateStore';
import { authStore } from './authStore';
import { AlertStore } from './AlertStore';
import { AuditTrailStore } from './AuditTrailStore';
import { MessageLogStore } from './MessageLogStore';
import { ReferenceListStore } from './ReferenceListStore';
import { ConditionalLogicStore } from './ConditionalLogicStore';
import { TableLookupsStore } from './TableLookupsStore';
import { ReferenceListItemsStore } from './ReferenceListItemsStore';
import { newStore } from './new';
import RootGetters from './getters';
import RootActions from './actions';
import RootMutations from './mutations';

Vue.use(Vuex);

const state: IVuexRootStoreState = {};

const store: StoreOptions<IVuexRootStoreState> = {
  state,
  getters: RootGetters,
  actions: RootActions,
  mutations: RootMutations,
  modules: {
    inboundMapping: InboundMappingStore,
    jobTemplate: JobTemplateStore,
    outboundMapping: OutboundMappingStore,
    templateCustomisation: TemplateCustomisationDataStore,
    templateList: TemplateListStore,
    templateMapping: TemplateMappingStore,
    templateStatus: TemplateStatusStore,
    versionList: VersionListStore,
    workOrderTemplate: WorkOrderTemplateStore,
    alerts: AlertStore,
    auth: authStore,
    auditTrail: AuditTrailStore,
    messageLog: MessageLogStore,
    referenceList: ReferenceListStore,
    conditionalLogic: ConditionalLogicStore,
    tableLookups: TableLookupsStore,
    referenceListItems: ReferenceListItemsStore,
    new: newStore,
  },
};

export default new Vuex.Store<IVuexRootStoreState>(store);
