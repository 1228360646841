import { MutationTree } from 'vuex';
import {
  IInboundOutboundMappingStoreState,
  IInboundMappingItem,
  IWorkorderTemplateData,
} from '@/types';
import { getDefaultState } from './state';

export const InboundMappingStoreMutations: MutationTree<IInboundOutboundMappingStoreState> = {
  setWorkOrderTemplate: (state: IInboundOutboundMappingStoreState, workOrderTemplate: string) => {
    state.data.workOrderTemplate = workOrderTemplate;
  },

  setLoading: (
    state: IInboundOutboundMappingStoreState,
    isLoading: boolean,
  ) => {
    state.loading = isLoading;
  },

  setMappings: (state: IInboundOutboundMappingStoreState, mappings: IInboundMappingItem[]) => {
    state.data.mappings = mappings;
  },

  resetState: (state: IInboundOutboundMappingStoreState) => {
    Object.assign(state, getDefaultState());
  },
  setTemplateData: (
    state: IInboundOutboundMappingStoreState,
    templateData: IWorkorderTemplateData,
  ) => {
    state.data.templateData = templateData;
  },
  setMappedTemplates: (
    state: IInboundOutboundMappingStoreState,
    mappedTemplates: IWorkorderTemplateData[],
  ) => {
    state.data.mappedTemplates = mappedTemplates;
  },
};
