import { MutationTree } from 'vuex';
import axios from 'axios';
import {
  IReferenceListStoreState,
} from '@/types';
import { getDefaultState } from './state';

export const ReferenceListStoreMutations: MutationTree<IReferenceListStoreState> = {
  setData: (state, data) => {
    state.data = data;
  },
  // Filter
  setFilterCreatedAt: (state, doneAt: string[]) => {
    if (doneAt.length > 1) {
      const fromDate = new Date(doneAt[0]).toLocaleDateString();
      const toDate = new Date(doneAt[1]).toLocaleDateString();

      if (![fromDate, toDate].includes('Invalid Date')) {
        state.filter.createdAt = `${fromDate} ~ ${toDate}`;
      }
    } else if (!doneAt.length) {
      state.filter.createdAt = '';
    }
  },

  setFilterStartEndDate: (state, startEnd: string[]) => {
    if (startEnd.length > 1) {
      const fromDate = new Date(startEnd[0]).toLocaleDateString();
      const toDate = new Date(startEnd[1]).toLocaleDateString();

      if (![fromDate, toDate].includes('Invalid Date')) {
        state.filter.startEndDate = `${fromDate} ~ ${toDate}`;
      }
    } else if (!startEnd.length) {
      state.filter.startEndDate = '';
    }
  },

  setFilterSortBy: (state, sort: string) => {
    let sortValue = 'created_at';

    if (sort === 'createdAt') sortValue = 'created_at';
    else if (sort === 'startEndDate') sortValue = 'start_date';
    else if (sort) sortValue = sort;

    state.filter.sortBy = sortValue;
  },

  setFilterIsDesc: (state, isDesc: '-' | '') => {
    state.filter.isDesc = isDesc;
  },

  // Pagination
  setPaginationPage: (state, page: number) => {
    state.pagination.page = page;
  },
  setPaginationItemsPerPage: (state, itemsPerPage: number) => {
    state.pagination.itemsPerPage = itemsPerPage;
  },
  setPaginationServerItemsLength: (state, itemsLength: number) => {
    state.pagination.serverItemsLength = itemsLength;
  },

  // cancelSource
  generateCancelSource: (state) => {
    state.cancelSource = axios.CancelToken.source();
  },
  cancelCancelSource: (state) => {
    state.cancelSource.cancel('cancel');
  },

  setLoading: (state, isLoading: boolean) => {
    state.loading = isLoading;
  },

  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};
