import { Module } from 'vuex';
import { IVuexRootStoreState, IAPIStoreState } from '@/types';
import { apiGetters } from './getters';
import { apiActions } from './actions';
import { apiMutations } from './mutations';

export const apiStore: Module<IAPIStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: {},
  getters: apiGetters,
  actions: apiActions,
  mutations: apiMutations,
};
