import { MutationTree } from 'vuex';
import axios from 'axios';
import {
  ITemplateListStoreState,
  ITemplateSearchRecord,
  IGridSettingsSelectionField,
  IVComboboxItem,
} from '@/types';
import { getDefaultState } from './state';

export const TemplateListStoreMutations: MutationTree<ITemplateListStoreState> = {
  // Filter
  setFilterIdentifier: (state, identifier: string) => {
    state.filter.identifier = identifier;
  },
  setFilterPublished: (state, published: boolean | null) => {
    state.filter.published = published;
  },
  setFilterVersion: (state, version: string) => {
    state.filter.version = version;
  },
  setFilterCreatedBy: (state, createdBy: string) => {
    state.filter.createdBy = createdBy;
  },
  setFilterCreatedAt: (state, createdAt: string[]) => {
    if (createdAt.length > 1) {
      const fromDate = new Date(createdAt[0]).toLocaleDateString();
      const toDate = new Date(createdAt[1]).toLocaleDateString();

      if (![fromDate, toDate].includes('Invalid Date')) {
        state.filter.createdAt = `${fromDate} ~ ${toDate}`;
      }
    } else if (!createdAt.length) {
      state.filter.createdAt = '';
    }
  },
  setFilterUpdatedAt: (state, updatedAt: string[]) => {
    if (updatedAt.length > 1) {
      const fromDate = new Date(updatedAt[0]).toLocaleDateString();
      const toDate = new Date(updatedAt[1]).toLocaleDateString();

      if (![fromDate, toDate].includes('Invalid Date')) {
        state.filter.updatedAt = `${fromDate} ~ ${toDate}`;
      }
    } else if (!updatedAt.length) {
      state.filter.updatedAt = '';
    }
  },
  setFilterSortBy: (state, sort: string) => {
    let sortValue = 'identifier';

    if (sort === 'updatedAt') sortValue = 'updated_at';
    else if (sort === 'createdAt') sortValue = 'created_at';
    else if (sort) sortValue = sort;

    state.filter.sortBy = sortValue;
  },
  setFilterIsDesc: (state, isDesc: '-' | '') => {
    state.filter.isDesc = isDesc;
  },

  // Pagination
  setPaginationPage: (state, page: number) => {
    state.pagination.page = page;
  },
  setPaginationItemsPerPage: (state, itemsPerPage: number) => {
    state.pagination.itemsPerPage = itemsPerPage;
  },
  setPaginationServerItemsLength: (state, itemsLength: number) => {
    state.pagination.serverItemsLength = itemsLength;
  },

  // cancelSource
  generateCancelSource: (state) => {
    state.cancelSource = axios.CancelToken.source();
  },
  cancelCancelSource: (state) => {
    state.cancelSource.cancel('cancel');
  },

  setDefaultGridSetting: (state, data: IGridSettingsSelectionField[]) => {
    state.defaultGridSettings = data;
  },

  addDefaultGridSetting: (state, data: IGridSettingsSelectionField) => {
    state.defaultGridSettings.push(data);
  },

  removeDefaultGridSetting: (state, fieldId: number) => {
    state.defaultGridSettings = state.defaultGridSettings.filter((setting) => setting.customFieldId !== fieldId);
  },

  // Util
  setData: (state, data: ITemplateSearchRecord[]) => {
    state.data = data;
  },

  setPublishedTemplatesData: (state, data: ITemplateSearchRecord[]) => {
    state.publishedTemplates = data;
  },

  setDefaultGridSettingTemplateFields: (state, data: IGridSettingsSelectionField[]) => {
    state.defaultGridSettingTemplateField = data;
  },

  setUnpublishedTemplatesData: (state, data: IVComboboxItem[]) => {
    state.unpublishedTemplates = data;
  },

  setPublishedTemplates: (state, data: ITemplateSearchRecord[]) => {
    state.publishedTemplates = data;
  },

  setLoading: (state, isLoading: boolean) => {
    state.loading = isLoading;
  },

  setUnpublishedTemplateLoading: (state, isLoading: boolean) => {
    state.unpublishedTemplateLoading = isLoading;
  },

  resetDefaultGridSetting: (state) => {
    state.defaultGridSettings = [];
  },

  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};
