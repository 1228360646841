






























import { computed, defineComponent, ref } from '@vue/composition-api';
import router from '@/router';
import RouterMapping from '@/router/RouterMapping';
import Store from '@/store';
import SaveTemplateChangesDialog from './TemplateCustomisation/SaveTemplateChangesDialog.vue';

export default defineComponent({
  components: { SaveTemplateChangesDialog },
  props: {},

  setup() {
    const route = computed(() => router.app.$route);
    const isVisible = ref(false);
    const renderKey = ref(0);

    const isTemplatePublished = computed(() => (Store.getters['templateCustomisation/getData'] ? Store.getters['templateCustomisation/getData'].published : false as boolean));

    /**
     * Returns current route name
     * @returns {string} Route name
     */
    const getCurrentRoute = (): string => (typeof route.value.name === 'string' ? route.value.name : '');

    const currentRoute = computed(getCurrentRoute);

    /**
     * Navigate back 1 in the page history
     */
    const goBack = () => {
      if (currentRoute.value === 'TemplateCustomisation' && !isTemplatePublished.value) {
        isVisible.value = true;
        renderKey.value++;
      } else {
        router.go(-1);
      }
    };

    /**
     * Get current header title
     * @returns {string} Header name
     */
    const headerTitle = computed((): string => {
      const pageMapping = RouterMapping;
      const routeName = getCurrentRoute();

      if (typeof routeName === 'string' && pageMapping[routeName]) {
        return pageMapping[routeName];
      }

      return '404 - Page not Found';
    });

    /**
     * Show in built browser warning when user tries to close the tab or browser window
     */
    window.addEventListener('beforeunload', (e) => {
      if (currentRoute.value === 'TemplateCustomisation' && !isTemplatePublished.value) {
        e.preventDefault();
        e.returnValue = '';
      }
    });

    return {
      goBack,
      currentRoute,
      headerTitle,
      isVisible,
      renderKey,
    };
  },
});
