import { GetterTree } from 'vuex';
import {
  IVuexRootStoreState,
  ITableLookupsStoreState,
  ITableLookupsStoreFilter,
  IPagination,
  ITableLookupsSearchFilter,
  ITableLookupsRecord,
  ILookupType,
} from '@/types';

export const TableLookupsStoreGetters: GetterTree<ITableLookupsStoreState, IVuexRootStoreState> = {
  getFilter: (state): ITableLookupsStoreFilter => state.filter,
  getLoading: (state): boolean => state.loading,
  getData: (state): ITableLookupsRecord[] => state.data,
  getLookupTypes: (state): ILookupType[] => state.lookupTypes,
  getLabourRoles: (state): ITableLookupsRecord[] => state.dropdownLookups.labour_role,
  getResourceTypes: (state): ITableLookupsRecord[] => state.dropdownLookups.resource_type,
  getCategories: (state): ITableLookupsRecord[] => state.dropdownLookups.category,
  getSubCategories: (state): ITableLookupsRecord[] => state.dropdownLookups.sub_category,
  getCommercialCategories: (state): ITableLookupsRecord[] => state.dropdownLookups.commercial_category,
  getUnitMaterials: (state): ITableLookupsRecord[] => state.dropdownLookups.unit_materials,
  getUnitPlant: (state): ITableLookupsRecord[] => state.dropdownLookups.unit_plant,

  getFilterValue: (state) => {
    const { filter } = state;
    const { pagination } = state;

    const filterObj = {
      'filter[key]': filter.key || null,
      'filter[type]': filter.type || null,
      sort: `${filter.isDesc}${filter.sortBy}`,
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    } as ITableLookupsSearchFilter;
    // removes any empty property
    return Object.keys(filterObj).reduce((accu, key) => {
      if (
        filterObj[key]
        || (typeof filterObj[key] === 'boolean' && !filterObj[key])
      ) accu[key] = filterObj[key];
      return accu;
    }, {} as ITableLookupsSearchFilter);
  },

  getPagination: (state): IPagination => state.pagination,
};
