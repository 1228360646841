import { Module } from 'vuex';
import {
  IMessageLogStoreState,
  IVuexRootStoreState,
} from '@/types';
import { MessageLogStoreGetters } from './getters';
import { MessageLogStoreActions } from './actions';
import { MessageLogStoreMutations } from './mutations';
import { getDefaultState } from './state';

export const MessageLogStore: Module<IMessageLogStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: MessageLogStoreGetters,
  actions: MessageLogStoreActions,
  mutations: MessageLogStoreMutations,
};
