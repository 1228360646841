import axios from 'axios';
import {
  IReferenceListStoreState,
} from '@/types';

/**
 * Get default store state
 * @return {IReferenceListStoreState}
 */
export const getDefaultState = (): IReferenceListStoreState => ({
  filter: {
    name: '',
    type: '',
    startEndDate: '',
    createdAt: '',
    sortBy: '',
    isDesc: '',
  },
  pagination: { page: 1, itemsPerPage: 10, serverItemsLength: 0 },
  cancelSource: axios.CancelToken.source(),
  loading: false,
  data: [],
} as IReferenceListStoreState);
