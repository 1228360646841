


































import { defineComponent, ref, PropType } from '@vue/composition-api';
import { IAlertDetails } from '@/types';

export default defineComponent({
  props: {
    alertDetails: {
      type: Object as PropType<IAlertDetails>,
      required: true,
    },
  },
  setup(props) {
    const snackbar = ref(false);
    const timeout = ref();

    const messageText = ref('');
    const iconType = ref('');
    const iconColour = ref('');

    const icons: { [key: string]: string } = {
      success: 'mdi-checkbox-marked-circle',
      error: 'mdi-alert-circle',
      warning: 'mdi-alert-circle',
    };

    const colours: { [key: string]: string } = {
      success: '#77ACA2',
      error: 'red',
      warning: '#eab308',
    };

    const setSnackbarDetails = () => {
      iconType.value = icons[props.alertDetails.alertType];
      iconColour.value = colours[props.alertDetails.alertType];
      messageText.value = props.alertDetails.alertText;
      timeout.value = props.alertDetails.timeout;
      snackbar.value = true;
    };

    setSnackbarDetails();

    return {
      snackbar,
      timeout,
      messageText,
      iconType,
      iconColour,
    };
  },
});

