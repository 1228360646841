import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IMessageLogStoreState,
} from '@/types';

export const MessageLogStoreActions: ActionTree<IMessageLogStoreState, IVuexRootStoreState> = {
  // Commit actions
  setWorkOrderTemplate: ({ commit }, templateId: string) => {
    commit('setWorkOrderTemplate', templateId);
  },
  // Filter
  setFilterWorkOrderIdentifier: ({ commit }, identifier: string) => {
    commit('setFilterWorkOrderIdentifier', identifier);
  },
  setFilterStatus: ({ commit }, status: string) => {
    commit('setFilterStatus', status);
  },
  setFilterCreatedAt: ({ commit }, doneAt: string[]) => {
    commit('setFilterCreatedAt', doneAt);
  },
  setFilterResponseStatus: ({ commit }, responseStatus: string) => {
    commit('setFilterResponseStatus', responseStatus);
  },
  setFilterSortBy: ({ commit }, sortBy: string) => {
    commit('setFilterSortBy', sortBy);
  },
  setFilterIsDesc: ({ commit }, isDesc: '-' | '') => {
    commit('setFilterIsDesc', isDesc);
  },

  // Outbound filter
  setFilterCreatedAtOutbound: ({ commit }, doneAt: string[]) => {
    commit('setFilterCreatedAtOutbound', doneAt);
  },
  setFilterSortByOutbound: ({ commit }, sortBy: string) => {
    commit('setFilterSortByOutbound', sortBy);
  },
  setFilterIsDescOutbound: ({ commit }, isDesc: '-' | '') => {
    commit('setFilterIsDescOutbound', isDesc);
  },

  // Pagination
  setPaginationPage: ({ commit }, page: number) => {
    commit('setPaginationPage', page);
  },
  setPaginationItemsPerPage: ({ commit }, itemsPerPage: number) => {
    commit('setPaginationItemsPerPage', itemsPerPage);
  },

  // Outbound Pagination
  setOutboundPaginationPage: ({ commit }, page: number) => {
    commit('setOutboundPaginationPage', page);
  },
  setOutboundPaginationItemsPerPage: ({ commit }, itemsPerPage: number) => {
    commit('setOutboundPaginationItemsPerPage', itemsPerPage);
  },

  loadInboundLogs: async ({ dispatch, commit, getters }) => {
    commit('setLoading', true);
    const templateId = getters.getWorkOrderTemplate;
    const filters = getters.getFilterValue;

    Object.assign(filters, {
      'filter[work_order_template_id]': templateId,
    });

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/inboundlogs`,
      options: {
        params: filters,
      },
    }, { root: true })
      .then((data) => {
        commit('setPaginationPage', data.currentPage as number);
        commit('setPaginationServerItemsLength', data.total as number);
        commit('setInboundLogs', data.data);
      })
      .finally(() => commit('setLoading', false));
  },

  loadOutboundLogs: async ({ dispatch, commit, getters }) => {
    commit('setLoading', true);
    const templateId = getters.getWorkOrderTemplate;
    const filters = getters.getOutboundFilterValue;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}/messagelog`,
      options: {
        params: filters,
      },
      backupError: 'Error loading outbound message logs.',
    }, { root: true })
      .then((data) => {
        commit('setOutboundPaginationPage', data.currentPage as number);
        commit('setOutboundPaginationServerItemsLength', data.total as number);
        commit('setOutboundLogs', data.data);
      })
      .finally(() => commit('setLoading', false));
  },

  resendOutboundMessage: async ({ commit, dispatch }, messageLogData) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${messageLogData.workOrderId}/messagelog/${messageLogData.id}`,
      options: {
        method: 'POST',
      },
      backupError: 'Failed to resend message. Please try again later.',
    }, { root: true })
      .then(async () => {
        await dispatch('loadOutboundLogs');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Message resent successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  rebuildOutboundMessage: async ({ commit, dispatch }, messageLogData) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workorders/${messageLogData.workOrderId}/messagelog/rebuild/${messageLogData.id}`,
      options: {
        method: 'POST',
      },
      backupError: 'Failed to rebuild message. Please try again later.',
    }, { root: true })
      .then(async () => {
        await dispatch('loadOutboundLogs');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Message rebuilt and resent successfully',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },
};
