import { Module } from 'vuex';
import {
  IConditionalLogicStoreState,
  IVuexRootStoreState,
} from '@/types';
import { ConditionalLogicStoreGetters } from './getters';
import { ConditionalLogicStoreActions } from './actions';
import { ConditionalLogicStoreMutations } from './mutations';
import { getDefaultState } from './state';

export const ConditionalLogicStore: Module<IConditionalLogicStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: ConditionalLogicStoreGetters,
  actions: ConditionalLogicStoreActions,
  mutations: ConditionalLogicStoreMutations,
};
