import VueRouter, { RouteConfig } from 'vue-router';

const routeOptions = [
  {
    path: '/',
    name: 'Home',
  },
  {
    path: '/templates',
    name: 'TemplateList',
  },
  {
    path: '/templates/customisation/:templateId/:version?',
    name: 'TemplateCustomisation',
  },
  {
    path: '/template/details/:templateId/:version?',
    name: 'TemplateDetailsCustomisation',
  },
  {
    path: '/template-mappings',
    name: 'TemplateMappings',
  },
  {
    path: '/template-mappings/customisation/:templateId/published=:isPublished',
    name: 'TemplateMappingsCustomisation',
  },
  {
    path: '/outbound-mappings',
    name: 'OutboundMappings',
  },
  {
    path: '/outbound-mappings/customisation/:templateId/published=:isPublished',
    name: 'OutboundMappingsCustomisation',
  },
  {
    path: '/inbound-mappings',
    name: 'InboundMappings',
  },
  {
    path: '/inbound-mappings/customisation/:templateId/published=:isPublished',
    name: 'InboundMappingsCustomisation',
  },
  {
    path: '/message-logs',
    name: 'MessageLogsTemplates',
  },
  {
    path: '/message-logs/:templateId',
    name: 'MessageLogs',
  },
  {
    path: '/grid-search-settings',
    name: 'GridSearchSettings',
  },
  {
    path: '/lists-config',
    name: 'ListsConfig',
  },
  {
    path: '/lists-config/ref-lists/:listType/:listId/view',
    name: 'RefListItems',
  },
  {
    path: '/message-templates',
    name: 'MessageTemplates',
    folder: 'MessageTemplate',
    props: true,
  },
  {
    path: '/message-templates/:type?/new',
    name: 'MessageTemplateNew',
    folder: 'MessageTemplate',
  },
  {
    path: '/message-templates/:type?/edit/:id',
    name: 'MessageTemplateEdit',
    folder: 'MessageTemplate',
    componentName: 'MessageTemplateNew',
  },
];

const routes: Array<RouteConfig> = routeOptions.map((route) => ({
  ...route,
  component: () => import(/* webpackChunkName: "[request]" */ `@/views/${route.folder || ''}${route.folder ? '/' : ''}${route.componentName || route.name}.vue`),
}));

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
