import { Module } from 'vuex';
import {
  IReferenceListItemsStoreState,
  IVuexRootStoreState,
} from '@/types';
import { ReferenceListItemsStoreGetters } from './getters';
import { ReferenceListItemsStoreActions } from './actions';
import { ReferenceListItemsStoreMutations } from './mutations';
import { getDefaultState } from './state';

export const ReferenceListItemsStore: Module<IReferenceListItemsStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: ReferenceListItemsStoreGetters,
  actions: ReferenceListItemsStoreActions,
  mutations: ReferenceListItemsStoreMutations,
};
