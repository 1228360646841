import { ActionTree } from 'vuex';
import _ from 'lodash';
import {
  ITemplateCustomisationStoreState,
  IVuexRootStoreState,
  IWorkorderTemplateData,
  ITemplateSectionField,
  IWorkOrderTemplateList,
  IJobListHeader,
  ITemplateSection,
} from '@/types';

export const TemplateCustomisationActions: ActionTree<ITemplateCustomisationStoreState, IVuexRootStoreState> = {
  // set template id
  setTemplateId: ({ commit }, templateId) => {
    commit('setTemplateId', templateId);
  },

  setTemplateVersion: ({ commit }, version) => {
    commit('setTemplateVersion', version);
  },

  setLoading: ({ commit }, loading) => {
    commit('setLoading', loading);
  },
  /**
   *  @description
   *    A dispatchable action for merging subsection fields into the section
   *    fields array so we can work with them evenly beside top-level fields.
   *  @param sections - The sections array we are going to work with.
   *  @returns { ITemplateSection[] }
   *  @author Jack O'Connor
   */
  mapSubSections: (__, sections: ITemplateSection[]) => {
    sections.map((section: ITemplateSection) => {
      section.subSections.map((sub: ITemplateSectionField) => {
        sub.type = 'subSection';
        return sub;
      });

      section.fields = [
        ...section.fields,
        ...section.subSections,
      ];

      section.fields = section.fields.sort((a: any, b: any) => a.order > b.order ? 1 : -1);

      return section;
    });

    return sections;
  },

  loadData: async ({ commit, getters, dispatch }) => {
    commit('setLoading', true);
    const templateId = getters.getTemplateId;
    const version = getters.getTemplateVersion;

    let url = `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}`;

    if (version) url += `?version=${version}`;

    await dispatch('auth/apiRequest', {
      url,
    }, { root: true })
      .then((data) => {
        dispatch('mapSubSections', data.data.sections);
        commit('setData', data.data as IWorkorderTemplateData);
      })
      .finally(() => commit('setLoading', false));
  },

  updateSectionFields: ({ commit }, updateSectionDetails) => {
    commit('updateSectionFields', updateSectionDetails);
  },

  updateIndividualField: ({ commit }, updatedFieldDetails) => {
    commit('updateIndividualField', updatedFieldDetails);
  },

  addNewSection: ({ commit }, { name, order }) => {
    commit('addNewSection', { name, order });
  },

  updateSectionName: ({ commit }, updateSectionNameDetails) => {
    commit('updateSectionName', updateSectionNameDetails);
  },

  deleteSection: ({ commit }, sectionName) => {
    commit('deleteSection', sectionName);
  },

  updateDeleteBox: ({ commit }, value: boolean) => {
    commit('updateDeleteBox', value);
  },

  updateRibbonType: ({ commit }, value: string) => {
    commit('updateRibbonType', value);
  },

  saveTemplateChanges: async ({ commit, getters, dispatch }, action: string) => {
    commit('setSaveLoading', true);

    // Forcing a parse by value so we aren't modifying actual state below.
    const templateData = JSON.parse(JSON.stringify(getters.getData as IWorkorderTemplateData));

    // need to remove id key for any field if empty as BE will know
    // this is new custom field and will generate a new id
    templateData.sections = templateData.sections.map((section: ITemplateSection) => {
      const cloneSection = _.cloneDeep(section);
      const { fields } = cloneSection;
      const subsecs: any = [];

      cloneSection.fields = fields.map((field: ITemplateSectionField, index: number) => {
        // newly added fields have id=0
        // need to remove for BE to create
        if (!field.id) {
          field = _.omit(field, ['id']) as ITemplateSectionField;
        }
        if (!('value' in field)) {
          field.value = '';
        }

        // do the same for fields within a subsection if the current field is a subsection
        if (field.type === 'subSection' && field.fields) {
          field.fields.map((subField: ITemplateSectionField) => {
            if (!subField.id) {
              subField.id = undefined;
            }
            if (!('value' in subField)) {
              field.value = '';
            }
            return subField;
          });
        }

        let { validation } = field;

        if (validation) {
          const keys = Object.keys(validation);

          keys.forEach((key) => {
            // remove empty validation keys
            // exclude isRange as answer is a bool

            if (validation && !validation[key] && key !== 'isRange') {
              validation = _.omit(validation, key) as ITemplateSectionField['validation'];
            }

            if (validation && key === 'fileType' && (validation![key].includes('any') || validation![key].includes('Any'))) {
              delete validation![key];
            }
          });

          field.validation = validation;
        }

        // remove structure from joblist component as it can interfere with saving changes
        if (field.type === 'joblist' && field.structure && !field.jobListStructure) {
          field.jobListStructure = field.structure as IJobListHeader[];
          delete field.structure;
        } else if (field.type === 'subSection') {
          field.toDelete = true;
          subsecs.push({
            ...field,
            index,
          });
        }

        return field;
      });

      cloneSection.fields = cloneSection.fields.filter((f) => !f.toDelete);
      cloneSection.subSections = subsecs;

      return cloneSection;
    });

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateData.id}?version=${templateData.version}`,
      options: {
        method: 'PUT',
        data: templateData,
      },
      backupError: 'Error saving changes',
    }, { root: true })
      .then((data) => {
        dispatch('mapSubSections', data.data.sections);
        commit('setData', data.data as IWorkorderTemplateData);

        if (action === 'save') {
          dispatch('alerts/createAlert', {
            type: 'success',
            message: 'Template changes saved successfully.',
          }, { root: true });
        }
      })
      .finally(() => commit('setSaveLoading', false));
  },

  /**
   *  @summary Retrieves the options for each field type for the 'Condition' select box
   *  @author Rory Harford
   */
  loadFieldConditions: async ({ commit, dispatch }) => {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/alarmcriterias`,
    }, { root: true })
      .then((data) => {
        data.data.file = [{ value: '=', text: 'File present' }, { value: '!=', text: 'File not present' }];
        commit('setFieldConditions', data.data);
      });
  },

  publishTemplate: async ({ commit, getters, dispatch }) => {
    const templateData = getters.getData as IWorkorderTemplateData;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateData.id}/publish/?version=${templateData.version}`,
      options: {
        method: 'POST',
      },
      backupError: 'Could not publish template. Please try again later.',
    }, { root: true })
      .then(() => {
        commit('updatePublishedStatus', true);
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Template published successfully.',
        }, { root: true });
      });
  },

  /**
   *  @summary Checks whether template configuration is complete.
   *  @description
   *    This action checks whether the template has inbound mappings,
   *    template mappings and outbound messages configured and sets the
   *    corresponding variables to true/false.
   *  @author Ewa Murjas
   */
  checkMappingsConfigured: async ({ commit, getters, dispatch }) => {
    let inboundConfigured = false;
    let outboundConfigured = false;

    // check inbound mappings
    const templateData = getters.getData as IWorkorderTemplateData;

    templateData.sections.forEach((section) => {
      if (section.fields.some((field) => field.inboundMapping)) {
        inboundConfigured = true;
      }
    });

    commit('setInboundConfigured', inboundConfigured);

    // check outbound
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateData.id}/outbound`,
      options: {
        method: 'GET',
      },
    }, { root: true })
      .then((data) => {
        outboundConfigured = data.data.length > 0;
        commit('setOutboundConfigured', outboundConfigured);
      });

    let mappingsConfigured = false;

    // check template mappings
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateData.id}/jobs`,
      options: {
        method: 'GET',
      },
    }, { root: true })
      .then(async (data) => {
        if (data.data && data.data.length > 0) {
          // Call all jobs via individual APIs (no API for multi-call). Async call each.
          await Promise.all(data.data.map(async (job: IWorkOrderTemplateList) => {
            await dispatch('auth/apiRequest', {
              url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateData.id}/jobs/${job.id}/fields`,
              options: {
                method: 'GET',
              },
            }, { root: true })
              .then(async (res) => {
                if (!mappingsConfigured && res.data) mappingsConfigured = res.data.length > 0;
                commit('setMappingsConfigured', mappingsConfigured);
              });
          }));
        } else {
          commit('setMappingsConfigured', false);
        }
      });
  },
};
