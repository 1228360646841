import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IReferenceListItemsStoreState,
  IEmployee,
  IReferenceListRecord,
  IReferenceListLabourItem,
} from '@/types';

export const ReferenceListItemsStoreActions: ActionTree<IReferenceListItemsStoreState, IVuexRootStoreState> = {
  setListId: ({ commit }, id: string) => {
    commit('setListId', id);
  },

  // Pagination
  setPaginationPage: ({ commit }, page: number) => {
    commit('setPaginationPage', page);
  },
  setPaginationItemsPerPage: ({ commit }, itemsPerPage: number) => {
    commit('setPaginationItemsPerPage', itemsPerPage);
  },

  // Sorting
  setFilterSortBy: ({ commit }, sortBy: string) => {
    commit('setFilterSortBy', sortBy);
  },
  setFilterIsDesc: ({ commit }, isDesc: '-' | '') => {
    commit('setFilterIsDesc', isDesc);
  },

  loadListItems: async ({
    dispatch, commit, getters, rootGetters,
  }) => {
    commit('setLoading', true);
    const listId = getters.getListId;
    const filterParams = getters.getFilterValue;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/referencelists/${listId}/items`,
      options: {
        params: filterParams,
      },
      backupError: 'Error loading reference list items.',
    }, { root: true })
      .then((result) => {
        commit('setPaginationPage', result.currentPage as number);
        commit('setPaginationServerItemsLength', result.total as number);

        const listData = rootGetters['referenceList/getData'].find((list: IReferenceListRecord) => list.id === listId);
        result.data = result.data.map((item: IReferenceListLabourItem) => {
          Object.assign(item, {
            name: listData.name,
            workOrderTemplates: listData.workOrderTemplates,
          });
          return item;
        });

        commit('setData', result.data);
      })
      .finally(() => commit('setLoading', false));
  },

  loadEmployees: async ({ commit, dispatch }) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/geopalapi/employees`,
      backupError: 'Error loading employees.',
    }, { root: true })
      .then((data) => {
        data.employees = data.employees.map((employee: IEmployee) => {
          Object.assign(employee, {
            fullName: `${employee.firstName} ${employee.lastName}`,
          });
          return employee;
        });
        commit('setEmployees', data.employees);
      })
      .finally(() => commit('setLoading', false));
  },

  createNewListItem: async ({ dispatch, commit, getters }, newListItemData) => {
    commit('setLoading', true);
    const listId = getters.getListId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/referencelists/${listId}/items`,
      options: {
        method: 'POST',
        data: newListItemData,
      },
      backupError: 'Error creating reference list item.',
    }, { root: true })
      .then(async () => {
        await dispatch('loadListItems');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Reference list item created successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  deleteListItem: async ({ dispatch, commit, getters }, itemId) => {
    commit('setLoading', true);
    const listId = getters.getListId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/referencelists/${listId}/items/${itemId}`,
      options: {
        method: 'DELETE',
      },
      backupError: 'Could not delete list item. Please try again later.',
    }, { root: true })
      .then(async () => {
        await dispatch('loadListItems');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Reference list item deleted successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  updateListItem: async ({ dispatch, commit, getters }, itemData) => {
    commit('setLoading', true);
    const listId = getters.getListId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/referencelists/${listId}/items/${itemData.id}`,
      options: {
        method: 'PUT',
        data: itemData,
      },
      backupError: 'Could not update list item. Please try again later.',
    }, { root: true })
      .then(async () => {
        await dispatch('loadListItems');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Reference list item updated successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  loadBulkUploadTemplate: async ({ dispatch, commit, getters }) => {
    commit('setLoading', true);
    const listId = getters.getListId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/referencelists/${listId}/items/bulk/export`,
      options: {
        method: 'GET',
        responseType: 'blob',
      },
      backupError: 'Failed to retrieve template. Please try again later.',
    }, { root: true })
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const fileName = `${listId}.xlsx`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .finally(() => commit('setLoading', false));
  },

  bulkUploadItems: async ({ dispatch, commit, getters }, file) => {
    commit('setLoading', true);
    const listId = getters.getListId;
    const formData = new FormData();
    formData.append('file', file);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/referencelists/${listId}/items/bulk/import`,
      options: {
        method: 'POST',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      backupError: 'Failed to upload file. Please try again later.',
    }, { root: true })
      .then(async () => {
        await dispatch('loadListItems');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Items uploaded successfully',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  resetStoreState: ({ commit }) => {
    commit('resetState');
  },
};
