import { MutationTree } from 'vuex';
import axios from 'axios';
import {
  IMessageLogStoreState,
} from '@/types';
import { getDefaultState } from './state';

export const MessageLogStoreMutations: MutationTree<IMessageLogStoreState> = {
  setWorkOrderTemplate: (state, templateId: string) => {
    state.templateId = templateId;
  },
  setInboundLogs: (state, data) => {
    state.inboundLogs = data;
  },
  setOutboundLogs: (state, data) => {
    state.outboundLogs = data;
  },
  // Filter
  setFilterWorkOrderIdentifier: (state, identifier: string) => {
    state.filter.workOrderIdentifier = identifier;
  },
  setFilterStatus: (state, status: string) => {
    state.filter.status = status;
  },
  setFilterCreatedAt: (state, doneAt: string[]) => {
    if (doneAt.length > 1) {
      const fromDate = new Date(doneAt[0]).toLocaleDateString();
      const toDate = new Date(doneAt[1]).toLocaleDateString();

      if (![fromDate, toDate].includes('Invalid Date')) {
        state.filter.createdAt = `${fromDate} ~ ${toDate}`;
      }
    } else if (!doneAt.length) {
      state.filter.createdAt = '';
    }
  },

  setFilterResponseStatus: (state, responseStatus: string) => {
    state.filter.success = responseStatus;
  },

  setFilterSortBy: (state, sort: string) => {
    let sortValue = 'work_order_identifier';

    if (sort === 'workOrderIdentifier') sortValue = 'work_order_identifier';
    else if (sort === 'createdAt') sortValue = 'created_at';
    else if (sort) sortValue = sort;

    state.filter.sortBy = sortValue;
  },
  setFilterIsDesc: (state, isDesc: '-' | '') => {
    state.filter.isDesc = isDesc;
  },

  // outbound filter
  setFilterCreatedAtOutbound: (state, doneAt: string[]) => {
    if (doneAt.length > 1) {
      const fromDate = new Date(doneAt[0]).toLocaleDateString();
      const toDate = new Date(doneAt[1]).toLocaleDateString();

      if (![fromDate, toDate].includes('Invalid Date')) {
        state.outboundFilter.createdAt = `${fromDate} ~ ${toDate}`;
      }
    } else if (!doneAt.length) {
      state.outboundFilter.createdAt = '';
    }
  },
  setFilterSortByOutbound: (state, sort: string) => {
    let sortValue = 'created_at';

    if (sort === 'createdAt') sortValue = 'created_at';
    else if (sort === 'workOrder') sortValue = 'work_order_identifier';
    else if (sort) sortValue = sort;

    state.outboundFilter.sortBy = sortValue;
  },

  setFilterIsDescOutbound: (state, isDesc: '-' | '') => {
    state.outboundFilter.isDesc = isDesc;
  },

  // Pagination
  setPaginationPage: (state, page: number) => {
    state.pagination.page = page;
  },
  setPaginationItemsPerPage: (state, itemsPerPage: number) => {
    state.pagination.itemsPerPage = itemsPerPage;
  },
  setPaginationServerItemsLength: (state, itemsLength: number) => {
    state.pagination.serverItemsLength = itemsLength;
  },

  // OutboundPagination
  setOutboundPaginationPage: (state, page: number) => {
    state.outboundPagination.page = page;
  },
  setOutboundPaginationItemsPerPage: (state, itemsPerPage: number) => {
    state.outboundPagination.itemsPerPage = itemsPerPage;
  },
  setOutboundPaginationServerItemsLength: (state, itemsLength: number) => {
    state.outboundPagination.serverItemsLength = itemsLength;
  },

  // cancelSource
  generateCancelSource: (state) => {
    state.cancelSource = axios.CancelToken.source();
  },
  cancelCancelSource: (state) => {
    state.cancelSource.cancel('cancel');
  },

  setLoading: (state, isLoading: boolean) => {
    state.loading = isLoading;
  },

  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};
