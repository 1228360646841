import { Module } from 'vuex';
import {
  IWorkOrderTemplateStoreState,
  IVuexRootStoreState,
} from '@/types';
import { WorkOrderTemplateStoreMutations } from './mutations';
import { WorkOrderTemplateStoreGetters } from './getters';
import { WorkOrderTemplateStoreActions } from './actions';
import { getDefaultState } from './state';

export const WorkOrderTemplateStore: Module<IWorkOrderTemplateStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: WorkOrderTemplateStoreGetters,
  actions: WorkOrderTemplateStoreActions,
  mutations: WorkOrderTemplateStoreMutations,
};
