import { Module } from 'vuex';
import {
  IInboundOutboundMappingStoreState,
  IVuexRootStoreState,
} from '@/types';
import { getDefaultState } from './state';
import { InboundMappingStoreGetters } from './getters';
import { InboundMappingStoreActions } from './actions';
import { InboundMappingStoreMutations } from './mutations';

export const InboundMappingStore: Module<IInboundOutboundMappingStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: InboundMappingStoreGetters,
  actions: InboundMappingStoreActions,
  mutations: InboundMappingStoreMutations,
};
