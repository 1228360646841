import { GetterTree } from 'vuex';
import { CancelTokenSource } from 'axios';
import {
  IVuexRootStoreState,
  ITemplateMappingStoreState,
  ITemplateMappingRecord,
  ITemplateSectionField,
  IJobField,
  ITemplateMappingItem,
  ITemplateWorkflowMappingItem,
  IWorkorderTemplateData,
} from '@/types';

export const TemplateMappingStoreGetters: GetterTree<ITemplateMappingStoreState, IVuexRootStoreState> = {
  getData: (state): ITemplateMappingRecord => state.data,
  getCancelSource: (state): CancelTokenSource => state.cancelSource,
  getLoading: (state): boolean => state.loading,
  getMappingsLoading: (state): boolean => state.mappingsLoading,
  getWorkOrderFields: (state): ITemplateSectionField[] | null => state.data.workOrderFields,
  getTemplateJobs: (state) => state.data.templateJobs,
  getJobFields: (state): IJobField[] | null => state.data.jobFields,
  getWorkOrderTemplateId: (state): string => state.data.workOrderTemplate,
  getMappings: (state): ITemplateMappingItem[] | null => state.data.mappings,
  getJobWorkflows: (state) => state.data.jobWorkflows,
  getJobTemplateId: (state): string => state.data.jobTemplate,
  getJobTemplateRefId: (state): number | null => state.data.jobTemplateRefId,
  getWorkflowMappings:
    (state): ITemplateWorkflowMappingItem[] | null => state.data.workflowMappings,
  getMappedTemplates: (
    state: ITemplateMappingStoreState,
  ): IWorkorderTemplateData[] | [] => state.data.mappedTemplates,
  getDeletedMappings: (state) => state.data.deletedMappings,
  getMappedFields: (state) => state.data.mappedFields,

};
