import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  ITemplateListStoreState,
  ITemplateSearchRecord,
  IGridSettingsSelectionField,
  ITemplateSection,
  IVComboboxItem,
} from '@/types';

export const TemplateListStoreActions: ActionTree<ITemplateListStoreState, IVuexRootStoreState> = {
  // Commit actions
  // Filter
  setFilterIdentifier: ({ commit }, identifier: string) => {
    commit('setFilterIdentifier', identifier);
  },
  setFilterPublished: ({ commit }, published: boolean) => {
    commit('setFilterPublished', published);
  },
  setFilterVersion: ({ commit }, version: string) => {
    commit('setFilterVersion', version);
  },
  setFilterCreatedBy: ({ commit }, createdBy: string) => {
    commit('setFilterCreatedBy', createdBy);
  },
  setFilterCreatedAt: ({ commit }, createdAt: string[]) => {
    commit('setFilterCreatedAt', createdAt);
  },
  setFilterUpdatedAt: ({ commit }, updatedAt: string[]) => {
    commit('setFilterUpdatedAt', updatedAt);
  },
  setFilterSortBy: ({ commit }, sortBy: string) => {
    commit('setFilterSortBy', sortBy);
  },
  setFilterIsDesc: ({ commit }, isDesc: '-' | '') => {
    commit('setFilterIsDesc', isDesc);
  },

  setDefaultGridSettingTemplateFields: ({ commit }, data) => {
    commit('setDefaultGridSettingTemplateFields', data);
  },

  addDefaultGridSetting: ({ commit }, item: IGridSettingsSelectionField) => {
    commit('addDefaultGridSetting', item);
  },

  removeDefaultGridSetting: ({ commit }, itemId: number) => {
    commit('removeDefaultGridSetting', itemId);
  },

  // Pagination
  setPaginationPage: ({ commit }, page: number) => {
    commit('setPaginationPage', page);
  },
  setPaginationItemsPerPage: ({ commit }, itemsPerPage: number) => {
    commit('setPaginationItemsPerPage', itemsPerPage);
  },

  getUnpublishedTemplatesData: async ({ dispatch, commit }) => {
    commit('setLoading', true);
    commit('setUnpublishedTemplateLoading', true);
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates`,
      options: {
        params: {
          'filter[published]': 0,
          with_versions: 1,
        },
      },
    }, { root: true })
      .then((data) => {
        const tempItems = data.data.map((temp : ITemplateSearchRecord) => ({
          text: temp.identifier,
          value: temp.id,
        }));
        commit('setUnpublishedTemplatesData', tempItems as IVComboboxItem);
        commit('setUnpublishedTemplateLoading', false);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },

  resetDefaultGridSetting: ({ commit }) => {
    commit('resetDefaultGridSetting', []);
  },

  setTemplateFieldsGridSettings: async ({ dispatch, commit }, templateId: string) => {
    commit('setLoading', true);
    commit('setDefaultGridSettingTemplateFields', []);
    const exludeFieldTypes = ['file', 'table', 'joblist'];
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}`,
    }, { root: true })
      .then((data) => {
        const fields = [] as IGridSettingsSelectionField[];
        const templateName = data.data.identifier;
        data.data.sections.forEach((section: ITemplateSection) => {
          const sectionName = section.name;
          section.fields.forEach((field) => {
            if (!exludeFieldTypes.includes(field.type)) {
              fields.push({
                customFieldId: field.id || -1,
                templateId,
                templateName,
                sectionName,
                customFieldName: field.name,
                customFieldType: field.type,
              });
            }
          });
        });
        commit('setDefaultGridSettingTemplateFields', fields);
      })
      .finally(() => commit('setLoading', false));
  },

  getPublishedTemplatesData: async ({ dispatch, commit }) => {
    commit('setLoading', true);
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates`,
      options: {
        params: {
          'filter[published]': 1,
        },
      },
    }, { root: true })
      .then((data) => {
        commit('setPublishedTemplatesData', data.data as ITemplateSearchRecord);
      })
      .finally(() => commit('setLoading', false));
  },

  loadDefaultGridSettingTemplateFieldsSelections: async ({ dispatch, commit }) => {
    commit('setLoading', true);
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/companysettings/gridfields`,
      options: {
      },
    }, { root: true })
      .then((data) => {
        commit('setDefaultGridSetting', data.data.settingValue as IGridSettingsSelectionField);
      })
      .finally(() => commit('setLoading', false));
  },
  /**
    * api is called to save admin grid template settings
   */
  saveAdminGridTemplateSettings: async ({ dispatch, getters, commit }) => {
    commit('setLoading', true);
    const defaultGridSettings = getters.getDefaultGridSettingTemplateFieldsSelections;
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/companysettings/gridfields`,
      options: {
        method: 'PUT',
        data: {
          setting_value: defaultGridSettings,
        },
      },
      backupError: 'Could not save the default grid setting. Please try again later.',
    }, { root: true })
      .then(() => {
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Default grid settings saved successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  /**
   * Gets list of templates based on defined filters and populates data state
   * loading state is also updated
   */
  filter: async ({ dispatch, getters, commit }) => {
    commit('setLoading', true);

    const filterParams = getters.getFilterValue;
    if (filterParams.per_page === -1) delete filterParams.per_page;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates`,
      options: {
        params: filterParams,
      },
    }, { root: true })
      .then((data) => {
        commit('setPaginationPage', data.currentPage as number);
        commit('setPaginationServerItemsLength', data.total as number);
        data.data = data.data.map((record: ITemplateSearchRecord) => {
          Object.assign(record, {
            createdByName: record.user?.name,
          });
          return record;
        });
        commit('setData', data.data as ITemplateSearchRecord);
      })
      .finally(() => commit('setLoading', false));
  },
};
