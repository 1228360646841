import { MutationTree } from 'vuex';
import { IAlertStoreState } from '@/types';

export const AlertStoreMutations: MutationTree<IAlertStoreState> = {
  addAlert(state, payload) {
    state.alerts.push(payload);
  },
  removeAlert(state, id) {
    state.alerts = state.alerts.filter((alert) => alert.id !== id);
  },
  removeAllAlerts(state) {
    state.alerts = [];
  },
};
