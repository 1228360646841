import _ from 'lodash';
import { ActionTree } from 'vuex';
import { IAlertStoreState, IVuexRootStoreState } from '@/types';

export const AlertStoreActions: ActionTree<IAlertStoreState, IVuexRootStoreState> = {
  createAlert: ({ commit }, payload) => {
    // expects object with type and message props
    if (payload.type && (payload.message || payload.messageList)) {
      switch (payload.type) {
        case 'error':
          break;
        case 'success':
          break;
        case 'warning':
          break;
        default:
          // no valid type has been parsed to the alert
          return;
      }

      const notification = {
        id: _.uniqueId(),
        alertType: payload.type,
        alertText: payload.message ? payload.message : null,
        timeout: payload.timeout ? payload.timeout : 7000,
      };

      commit('addAlert', notification);
    } else {
      // required alert properties are unset

    }
  },
  removeAlert: ({ commit }, id) => {
    commit('removeAlert', id);
  },
};
