




















import {
  watch, computed, ref, defineComponent, onMounted,
} from '@vue/composition-api';
import router from '@/router';
import Store from '@/store';
import Header from '@/components/Header.vue';
import Notification from '@/components/Notification.vue';
import { IAlertDetails } from '@/types';

export default defineComponent({
  name: 'App',

  components: {
    Header,
    Notification,
  },
  setup() {
    const transitionName = ref('slide-y-reverse-transition');
    const route = computed(() => router.app.$route);

    const alerts = computed(() => Store.getters['alerts/getAlerts'] as IAlertDetails[]);

    watch(alerts, () => {
      if (alerts.value.length >= 1) {
        alerts.value.forEach((alert) => {
          setTimeout(() => {
            Store.dispatch('alerts/removeAlert', alert.id);
          }, (alert.timeout + 1000));
        });
      }
    });

    /**
     * Will watch route for chnages and based on the depth of the route
     * will determine that animation
     */
    watch(route, (to, from) => {
      let toDepth = to.path.split('/').length;
      let fromDepth = from.path.split('/').length;

      // Home route is only one level deep but split returns two empty strings
      if (to.path === '/') toDepth = 1;
      if (from.path === '/') fromDepth = 1;

      /**
       * If coming from /templates (2) to / (1) transition will be a slide from left to right
       * If coming from / (1) to /templates (2) transition will be a slide frm right to left
       * If they have the same depth transition will be from bottom to top
       */
      if (toDepth < fromDepth) {
        transitionName.value = 'slide-x-reverse-transition';
      } else if (toDepth > fromDepth) {
        transitionName.value = 'slide-x-transition';
      } else transitionName.value = 'slide-y-reverse-transition';
    });
    /**
     *  Event listener for JWT from GeoPal iframe.
     */
    const messageReceived = (event: any) => {
      if (event?.data?.jwt) {
        window.removeEventListener('message', messageReceived);
        Store.dispatch('auth/setAuthToken', event.data.jwt);
      }
    };
    onMounted(() => {
      if (process.env.NODE_ENV === 'production') {
        window.addEventListener('message', messageReceived);
      }
    });

    return {
      transitionName,
      alerts,
    };
  },
});
