import axios from 'axios';
import {
  IInboundOutboundMappingStoreState,
  IWorkorderTemplateData,
} from '@/types';

/**
 * Get default store state
 * @return {IInboundOutboundMappingStoreState}
 */
export const getDefaultState = (): IInboundOutboundMappingStoreState => ({
  data: {
    workOrderTemplate: '',
    mappings: [],
    templateData: {} as IWorkorderTemplateData,
    mappedTemplates: [],
  },
  cancelSource: axios.CancelToken.source(),
  loading: false,
});
