import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  ITableLookupsStoreState,
} from '@/types';

export const TableLookupsStoreActions: ActionTree<ITableLookupsStoreState, IVuexRootStoreState> = {
  // Filter
  setFilterSortBy: ({ commit }, sortBy: string) => {
    commit('setFilterSortBy', sortBy);
  },
  setFilterIsDesc: ({ commit }, isDesc: '-' | '') => {
    commit('setFilterIsDesc', isDesc);
  },

  // Pagination
  setPaginationPage: ({ commit }, page: number) => {
    commit('setPaginationPage', page);
  },
  setPaginationItemsPerPage: ({ commit }, itemsPerPage: number) => {
    commit('setPaginationItemsPerPage', itemsPerPage);
  },

  loadTableLookups: async ({ dispatch, commit, getters }) => {
    commit('setLoading', true);
    const filters = getters.getFilterValue;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/lookup`,
      options: {
        params: filters,
      },
    }, { root: true })
      .then((result) => {
        commit('setPaginationPage', result.currentPage as number);
        commit('setPaginationServerItemsLength', result.total as number);
        commit('setData', result.data);
      })
      .finally(() => commit('setLoading', false));
  },

  loadLookupTypes: async ({ dispatch, commit }) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/lookuptypes`,
    }, { root: true })
      .then((result) => {
        commit('setPaginationPage', result.currentPage as number);
        commit('setPaginationServerItemsLength', result.total as number);
        commit('setLookupTypes', result.data);
      })
      .finally(() => commit('setLoading', false));
  },

  loadTableLookupsByType: async ({ dispatch, commit }, lookupType) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/lookup/${lookupType}`,
    }, { root: true })
      .then((result) => {
        commit('setLookupByType', {
          type: lookupType,
          items: result.data,
        });
      })
      .finally(() => commit('setLoading', false));
  },

  createLookupItem: async ({ dispatch, commit }, item) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/lookup`,
      options: {
        method: 'POST',
        data: item,
      },
      backupError: 'Error creating lookup item',
    }, { root: true })
      .then(async () => {
        await dispatch('loadTableLookups');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Item created successfully',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  deleteLookupItem: async ({ dispatch, commit }, itemId) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/lookup/${itemId}`,
      options: {
        method: 'DELETE',
      },
      backupError: 'Error deleting lookup item',
    }, { root: true })
      .then(async () => {
        await dispatch('loadTableLookups');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Item deleted successfully',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },
};
