import axios from 'axios';
import {
  IJobTemplateStoreState,
} from '@/types';

/**
 * Get default store state
 * @return {IJobTemplateStoreState} Initial state
 */
export const getDefaultState = (): IJobTemplateStoreState => ({
  cancelSource: axios.CancelToken.source(),
  loading: false,
  data: [],
});
