import { Module } from 'vuex';
import {
  ITemplateCustomisationStoreState,
  IVuexRootStoreState,
} from '@/types';
import { getDefaultState } from './state';
import { TemplateCustomisationGetters } from './getters';
import { TemplateCustomisationActions } from './actions';
import { TemplateCustomisationDataStoreMutations } from './mutations';

export const TemplateCustomisationDataStore: Module<ITemplateCustomisationStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: TemplateCustomisationGetters,
  actions: TemplateCustomisationActions,
  mutations: TemplateCustomisationDataStoreMutations,
};
