import { Module } from 'vuex';
import {
  IVuexRootStoreState,
  IWorkOrderTemplateState,
} from '@/types';
import { templateGetters } from './getters';
import { templateActions } from './actions';
import { templateMutations } from './mutations';

export const templateStore: Module<IWorkOrderTemplateState, IVuexRootStoreState> = {
  namespaced: true,
  state: {
    templates: [],
  },
  getters: templateGetters,
  actions: templateActions,
  mutations: templateMutations,
};
