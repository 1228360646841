import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IMessageTemplateState,
  IMessageTemplatePayload,
  IMessageTemplateType,
} from '@/types';

export const messageTemplateActions: ActionTree<IMessageTemplateState, IVuexRootStoreState> = {
  /**
   *  @summary Create a message template via the API
   *  @param payload.data - The data to submit to the API
   *  @param payload.type - The type of template we're creating (mail | sms)
   *  @author Jack O'Connor
   */
  createMessageTemplate: async ({ dispatch, commit }, { data, type }: ICUMethodPayload) => {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/messagetemplates/${type}`,
      options: {
        method: 'POST',
        data,
      },
    }, { root: true })
      .then((response) => {
        commit('addMessageTemplate', { template: response.data, type });

        dispatch('alerts/createAlert', {
          type: 'success',
          message: `Your ${type} template was created successfully.`,
        }, { root: true });
      });
  },
  /**
   *  @summary Get all message templates by type
   *  @param payload.type - The type of template we're creating (mail | sms)
   *  @author Jack O'Connor
   */
  readAllMessageTemplates: async ({ dispatch, commit }, { type, params }: { type: IMessageTemplateType, params: { [key: string]: string | number } }) => {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/messagetemplates/${type}`,
      options: {
        method: 'GET',
        params,
      },
    }, { root: true })
      .then((response) => {
        commit('setMessageTemplates', { templates: response.data, type });
        commit('setAPILastCalled', { type, dtime: new Date() });

        // Set pagination properties
        commit('setPaginationPage', { type, page: response.currentPage });
        commit('setPaginationPerPage', { type, per_page: response.perPage });
        commit('setPaginationTotal', { type, total: response.total });
      });
  },
  /**
   *  @summary Get a specific message template by type and ID
   *  @param payload.type - The type of template we're creating (mail | sms)
   *  @param payload.id - The ID of the message template you want to get
   *  @author Jack O'Connor
   */
  readMessageTemplate: async ({ dispatch, commit }, { type, id }: IRMethodArgs) => {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/messagetemplates/${type}/${id}`,
      options: {
        method: 'GET',
      },
    }, { root: true })
      .then((response) => {
        commit('addMessageTemplate', {
          template: response.data,
          type,
        });
      });
  },
  /**
   *  @summary Update a message template via the API
   *  @param payload.template - The data to submit to the API
   *  @param payload.type - The type of template we're creating (mail | sms)
   *  @author Jack O'Connor
   */
  updateMessageTemplate: async ({ dispatch, commit }, { data, type }: ICUMethodPayload) => {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/messagetemplates/${type}/${data.id}`,
      options: {
        method: 'PUT',
        data,
      },
    }, { root: true })
      .then((response) => {
        commit('updateMessageTemplate', {
          template: response.data,
          type,
        });

        dispatch('alerts/createAlert', {
          type: 'success',
          message: `Successfully updated ${type} template`,
        }, { root: true });
      });
  },
  /**
   *  @summary Deletes a message template via the API
   *  @param payload.type - The type of template we're creating (mail | sms)
   *  @param payload.id - The ID of the template to delete
   *  @author Jack O'Connor
   */
  deleteMessageTemplate: async ({ dispatch, commit, getters }, { type, id }: { type: IMessageTemplateType, id: string }) => {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/messagetemplates/${type}/${id}`,
      options: {
        method: 'DELETE',
      },
    }, { root: true })
      .then(() => {
        // deletes the template
        commit('deleteMessageTemplate', { type, id });
        // updates the pagination to reflect the deletion
        commit('setPaginationTotal', { type, total: getters.getMessageTemplates(type).pagination.total - 1 });
        dispatch('alerts/createAlert', {
          type: 'success',
          message: `Successfully deleted ${type} template`,
        }, { root: true });
      });
  },
};

interface ICUMethodPayload {
  data: IMessageTemplatePayload
  type: IMessageTemplateType
}

interface IRMethodArgs {
  id: string
  type: IMessageTemplateType
}
