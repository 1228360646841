import axios from 'axios';
import {
  IReferenceListItemsStoreState,
} from '@/types';

/**
 * Get default store state
 * @return {IReferenceListItemsStoreState}
 */
export const getDefaultState = (): IReferenceListItemsStoreState => ({
  filter: {
    id: '',
    employeeId: null,
    employeeName: '',
    labourRole: '',
    team: '',
    resourceType: '',
    contract: '',
    payrollNumber: '',
    description: '',
    category: '',
    subCategory: '',
    commercialCategory: '',
    manufacturersCode: '',
    unit: '',
    rate: null,
    sortBy: '',
    isDesc: '',
  },
  pagination: { page: 1, itemsPerPage: 10, serverItemsLength: 0 },
  cancelSource: axios.CancelToken.source(),
  loading: false,
  data: [],
  listId: '',
  employees: [],
} as IReferenceListItemsStoreState);
