import { Module } from 'vuex';
import {
  IInboundOutboundMappingStoreState,
  IVuexRootStoreState,
} from '@/types';
import { OutboundMappingStoreGetters } from './getters';
import { OutboundMappingStoreActions } from './actions';
import { OutboundMappingStoreMutations } from './mutations';
import { getDefaultState } from './state';

export const OutboundMappingStore: Module<IInboundOutboundMappingStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: OutboundMappingStoreGetters,
  actions: OutboundMappingStoreActions,
  mutations: OutboundMappingStoreMutations,
};
