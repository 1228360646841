import { GetterTree } from 'vuex';
import {
  IInboundOutboundMappingStoreState,
  IInboundMappingItem,
  IInboundOutboundMappingRecord,
  IWorkorderTemplateData,
  IVuexRootStoreState,
} from '@/types';

export const InboundMappingStoreGetters: GetterTree<IInboundOutboundMappingStoreState, IVuexRootStoreState> = {
  getData: (
    state: IInboundOutboundMappingStoreState,
  ): IInboundOutboundMappingRecord => state.data,

  getWorkOrderTemplateId: (
    state: IInboundOutboundMappingStoreState,
  ): string => state.data.workOrderTemplate,

  getMappings: (
    state: IInboundOutboundMappingStoreState,
  ): IInboundMappingItem[] | null => state.data.mappings,

  getLoading: (
    state: IInboundOutboundMappingStoreState,
  ): IInboundOutboundMappingStoreState['loading'] => state.loading,

  getMappedTemplates: (
    state: IInboundOutboundMappingStoreState,
  ): IWorkorderTemplateData[] | undefined => state.data.mappedTemplates,
};
