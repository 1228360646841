import axios from 'axios';
import {
  IMessageLogStoreState,
} from '@/types';

/**
 * Get default store state
 * @return {IMessageLogStoreState}
 */
export const getDefaultState = (): IMessageLogStoreState => ({
  filter: {
    workOrderIdentifier: '',
    status: '',
    createdAt: '',
    success: '',
    statusCode: null,
    statusName: '',
    sortBy: '',
    isDesc: '',
  },
  outboundFilter: {
    workOrderIdentifier: '',
    messageName: '',
    statusName: '',
    action: '',
    createdAt: '',
    success: '',
    sortBy: 'created_at',
    isDesc: '-',
  },
  pagination: { page: 1, itemsPerPage: 10, serverItemsLength: 0 },
  outboundPagination: { page: 1, itemsPerPage: 10, serverItemsLength: 0 },
  cancelSource: axios.CancelToken.source(),
  loading: false,
  data: [],
  templateId: '',
  inboundLogs: [],
  outboundLogs: [],
} as IMessageLogStoreState);
