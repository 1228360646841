import { MutationTree } from 'vuex';
import axios from 'axios';
import {
  IReferenceListItemsStoreState,
} from '@/types';
import { getDefaultState } from './state';

export const ReferenceListItemsStoreMutations: MutationTree<IReferenceListItemsStoreState> = {
  setData: (state, data) => {
    state.data = data;
  },
  setListId: (state, listId) => {
    state.listId = listId;
  },
  setEmployees: (state, employees) => {
    state.employees = employees;
  },

  // cancelSource
  generateCancelSource: (state) => {
    state.cancelSource = axios.CancelToken.source();
  },
  cancelCancelSource: (state) => {
    state.cancelSource.cancel('cancel');
  },

  setLoading: (state, isLoading: boolean) => {
    state.loading = isLoading;
  },

  setFilterSortBy: (state, sort: string) => {
    let sortValue = 'created_at';

    if (sort === 'manufacturersCode') sortValue = 'manufacturers_code';
    else if (sort === 'category') sortValue = 'category.value';
    else if (sort === 'subCategory') sortValue = 'sub_category.value';
    else if (sort === 'commercialCategory') sortValue = 'commercial_category.value';
    else if (sort === 'unit') sortValue = 'unit.value';
    else if (sort === 'employeeId') sortValue = 'employee_id';
    else if (sort === 'employeeName') sortValue = 'employee_name';
    else if (sort === 'payrollNumber') sortValue = 'payroll_number';
    else if (sort === 'labourRole') sortValue = 'labour_role.value';
    else if (sort === 'resourceType') sortValue = 'resource_type.value';
    else if (sort) sortValue = sort;

    state.filter.sortBy = sortValue;
  },
  setFilterIsDesc: (state, isDesc: '-' | '') => {
    state.filter.isDesc = isDesc;
  },

  // Pagination
  setPaginationPage: (state, page: number) => {
    state.pagination.page = page;
  },
  setPaginationItemsPerPage: (state, itemsPerPage: number) => {
    state.pagination.itemsPerPage = itemsPerPage;
  },
  setPaginationServerItemsLength: (state, itemsLength: number) => {
    state.pagination.serverItemsLength = itemsLength;
  },

  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};
