import { Module } from 'vuex';
import {
  ITemplateMappingStoreState,
  IVuexRootStoreState,
} from '@/types';
import { TemplateMappingStoreMutations } from './mutations';
import { TemplateMappingStoreGetters } from './getters';
import { TemplateMappingStoreActions } from './actions';
import { getDefaultState } from './state';

export const TemplateMappingStore: Module<ITemplateMappingStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: TemplateMappingStoreGetters,
  actions: TemplateMappingStoreActions,
  mutations: TemplateMappingStoreMutations,
};
