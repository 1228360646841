import { GetterTree } from 'vuex';
import { IAlertStoreState, IVuexRootStoreState } from '@/types';

export const AlertStoreGetters: GetterTree<IAlertStoreState, IVuexRootStoreState> = {
  getAlerts(state) {
    if (state.alerts) {
      return state.alerts;
    }
    return false;
  },
};
