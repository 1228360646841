import { MutationTree } from 'vuex';
import axios from 'axios';
import {
  IWorkOrderTemplateStoreState,
  IWorkOrderTemplateList,
  IWorkOrderTemplate,
  IWorkOrderTemplateStatuses,
} from '@/types';
import { getDefaultState } from './state';

export const WorkOrderTemplateStoreMutations: MutationTree<IWorkOrderTemplateStoreState> = {
  setName: (state, name: string) => {
    state.data.name = name;
  },
  setJobTemplate: (state, jobTemplate: string) => {
    state.data.jobTemplate = jobTemplate;
  },
  setAddJobTemplate: (state, jobTemplate: string) => {
    state.data.availableJobTemplates.addingTemplate = jobTemplate;
  },
  setValidationName: (state, value: boolean | string) => {
    state.validation.name = value;
  },

  // Util
  setWorkOrderJobTemplateData: (state, data: IWorkOrderTemplate) => {
    state.data = data;
  },

  setSelectedJobTemplates: (state, data: IWorkOrderTemplateList) => {
    state.data.availableJobTemplates.templateList.push(data);
  },

  cancelCancelSource: (state) => {
    state.cancelSource.cancel('cancel');
  },
  generateCancelSource: (state) => {
    state.cancelSource = axios.CancelToken.source();
  },

  setLoading: (state, isLoading: boolean) => {
    state.loading = isLoading;
  },

  setDuplicateLoading: (state, isLoading: boolean) => {
    state.duplicateLoading = isLoading;
  },

  setManualCreateValue: (state, manualCreateValue: boolean) => {
    state.data.manualCreate = manualCreateValue;
  },

  setStatuses: (state, statusNames: IWorkOrderTemplateStatuses[]) => {
    state.data.jobTemplateStatuses = statusNames;
  },

  setSelectedNavItem: (state, selectedNavItem: number) => {
    state.data.availableJobTemplates.selectedNavItem = selectedNavItem;
  },

  setDefaultTemplateCreateStatus: (state, statusName: string) => {
    state.data.availableJobTemplates.defaultTemplateCreateStatus = statusName;
  },

  deleteStatuses: (state, status: IWorkOrderTemplateStatuses) => {
    /* check to see if status being deleted is the default status */
    if (state.data.availableJobTemplates.defaultTemplateCreateStatus === status.name) state.data.availableJobTemplates.defaultTemplateCreateStatus = '';

    /* delete status */
    const updatedStatuses = state.data.jobTemplateStatuses.filter(
      (statusCheck) => statusCheck.id !== status.id,
    );

    const newArray = updatedStatuses.map((statusCheck) => {
      const newTrasitions = statusCheck.transitions.filter(
        (statusTrasition) => statusTrasition.id !== status.id,
      );
      // eslint-disable-next-line no-param-reassign
      statusCheck.transitions = newTrasitions;
      return statusCheck;
    });
    state.data.jobTemplateStatuses = newArray;
  },

  /**
   *  @summary deleteTemplate removes the template from the vuex store
   *  @description
   *    Removes the template from vuex store.
   *    if the template was the defualt template the property in the vuex store to indicate a default template has been selected is set to false.
   *  @param {number} templateId
   *  @author Rory Harford
   */
  deleteTemplate: (state, templateId: number) => {
    const deleteItem = state.data.availableJobTemplates.templateList.find(
      (template) => template.jobTemplateId === templateId,
    );
    const remainingTemplates = state.data.availableJobTemplates.templateList.filter(
      (template) => template.jobTemplateId !== templateId,
    );

    if (deleteItem?.isDefault === true) state.data.availableJobTemplates.defaultTemplate = false;

    state.data.availableJobTemplates.deleteTemplateList.push(templateId);
    state.data.availableJobTemplates.templateList = remainingTemplates;
  },

  /**
   *  @summary selectDefaultTemplate sets the template that is created when default status is reached.
   *  @description
   *    sets the clicked template item to the opposite the template default value is.
   *    resets all other templates default status to false as there should only be one default.
   *  @param {IWorkOrderTemplateList} template
   *  @author Rory Harford
   */
  selectDefaultTemplate: (state, template: IWorkOrderTemplateList) => {
    state.data.availableJobTemplates.defaultTemplate = !template.isDefault;
    template.autoCreate = !template.autoCreate;

    state.data.availableJobTemplates.templateList.forEach((temp) => {
      temp.isDefault = temp.jobTemplateId === template.jobTemplateId ? !temp.isDefault : false;
    });
  },

  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};
