import { MutationTree } from 'vuex';
import axios from 'axios';
import {
  IAuditTrailStoreState,
  IAuditTrailRecord,
} from '@/types';
import { getDefaultState } from './state';

export const AuditTrailStoreMutations: MutationTree<IAuditTrailStoreState> = {
  setData: (state, data: IAuditTrailRecord[]) => {
    state.data = data;
  },
  setLoading: (state, isLoading: boolean) => {
    state.loading = isLoading;
  },
  // Pagination
  setPaginationPage: (state, page: number) => {
    state.pagination.page = page;
  },
  setPaginationItemsPerPage: (state, itemsPerPage: number) => {
    state.pagination.itemsPerPage = itemsPerPage;
  },
  setPaginationServerItemsLength: (state, itemsLength: number) => {
    state.pagination.serverItemsLength = itemsLength;
  },
  setFilterIsDesc: (state, isDesc: '-' | '') => {
    state.filter.isDesc = isDesc;
  },
  setFilterField: (state, field: string) => {
    state.filter.field = field;
  },
  setFilterAction: (state, action: string) => {
    state.filter.action = action;
  },
  setFilterUser: (state, user: string) => {
    state.filter.user = user;
  },
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
  setTemplateId: (state, id: string) => {
    state.filter.id = id;
  },
  setFilterDate: (state, date: string[]) => {
    if (date.length > 1) {
      const fromDate = new Date(date[0]).toLocaleDateString();
      const toDate = new Date(date[1]).toLocaleDateString();

      if (![fromDate, toDate].includes('Invalid Date')) {
        state.filter.date = `${fromDate} ~ ${toDate}`;
      }
    } else if (!date.length) {
      state.filter.date = '';
    }
  },
  resetTemplateId: (state) => {
    state.filter.id = '';
  },
  // cancelSource
  generateCancelSource: (state) => {
    state.cancelSource = axios.CancelToken.source();
  },
  cancelCancelSource: (state) => {
    state.cancelSource.cancel('cancel');
  },
};
