import { Module } from 'vuex';
import {
  IAuditTrailStoreState,
  IVuexRootStoreState,
} from '@/types';
import { AuditTrailStoreGetters } from './getters';
import { AuditTrailStoreActions } from './actions';
import { AuditTrailStoreMutations } from './mutations';
import { getDefaultState } from './state';

export const AuditTrailStore: Module<IAuditTrailStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: AuditTrailStoreGetters,
  actions: AuditTrailStoreActions,
  mutations: AuditTrailStoreMutations,
};
