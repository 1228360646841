import _ from 'lodash';
import { MutationTree } from 'vuex';
import {
  ITemplateCustomisationStoreState,
  ITemplateSection,
  IWorkorderTemplateData,
  ITemplateSectionFieldsUpdate,
  ITemplateSectionNameUpdate,
  ITemplateFieldUpdateObject,
  IVComboboxItem,
} from '@/types';

export const TemplateCustomisationDataStoreMutations: MutationTree<ITemplateCustomisationStoreState> = {
  setData: (
    state: ITemplateCustomisationStoreState,
    data: IWorkorderTemplateData,
  ) => {
    state.data = data;
  },
  setLoading: (
    state: ITemplateCustomisationStoreState,
    isLoading: boolean,
  ) => {
    state.loading = isLoading;
  },
  setSaveLoading: (
    state: ITemplateCustomisationStoreState,
    isSaveLoading: boolean,
  ) => {
    state.saveLoading = isSaveLoading;
  },
  setTemplateId: (state: ITemplateCustomisationStoreState, id: string) => {
    state.filter.id = id;
  },
  setTemplateVersion: (state: ITemplateCustomisationStoreState, version: string) => {
    state.filter.version = version;
  },
  setInboundConfigured: (state: ITemplateCustomisationStoreState, value: boolean) => {
    state.inboundConfigured = value;
  },
  setFieldConditions: (state: ITemplateCustomisationStoreState, value: IVComboboxItem[]) => {
    state.fieldConditions = value;
  },
  setOutboundConfigured: (state: ITemplateCustomisationStoreState, value: boolean) => {
    state.outboundConfigured = value;
  },
  setMappingsConfigured: (state: ITemplateCustomisationStoreState, value: boolean) => {
    state.templateMappingsConfigured = value;
  },
  updateSectionFields: (
    state: ITemplateCustomisationStoreState,
    updateObject: ITemplateSectionFieldsUpdate,
  ) => {
    if (!state.data) return;

    const sectionFound = state.data.sections.find(
      (section: ITemplateSection) => section.name === updateObject.name,
    );
    if (sectionFound) sectionFound.fields = updateObject.fields;
  },
  updateSectionName: (
    state: ITemplateCustomisationStoreState,
    updateObject: ITemplateSectionNameUpdate,
  ) => {
    if (!state.data) return;

    const sectionFound = state.data.sections.find(
      (section: ITemplateSection) => section.name === updateObject.oldName,
    );
    if (sectionFound) sectionFound.name = updateObject.newName;
  },

  updateIndividualField: (
    state: ITemplateCustomisationStoreState,
    field: ITemplateFieldUpdateObject,
  ) => {
    if (!state.data) return;

    const sectionList = _.cloneDeep(state.data.sections);
    let sectionFound: any = sectionList.find(
      (section: ITemplateSection) => section.name === field.sectionName,
    );
    if (!sectionFound) return;

    if (field.subsectionIndex && sectionFound) {
      sectionFound = sectionFound.fields[field.subsectionIndex];
    }

    sectionFound.fields[field.index] = field.fieldData;
    state.data.sections = sectionList;
  },

  addNewSection: (
    state: ITemplateCustomisationStoreState,
    { name, order },
  ) => {
    if (!state.data) return;
    state.data.sections.push({
      fields: [],
      name,
      order,
      parentSectionId: null,
    });
  },

  updateDeleteBox: (state: ITemplateCustomisationStoreState, value: boolean) => {
    state.showDeleteBox = value;
  },

  updatePublishedStatus: (state: ITemplateCustomisationStoreState, value: boolean) => {
    if (!state.data) return;
    state.data.published = value;
  },

  updateRibbonType: (state: ITemplateCustomisationStoreState, value: string) => {
    if (!state.data) return;
    state.data.ribbonType = value;
  },

  deleteSection: (
    state: ITemplateCustomisationStoreState,
    sectionName: string,
  ) => {
    if (!state.data) return;

    const sectionsLeft = state.data.sections.filter(
      (section: ITemplateSection) => section.name !== sectionName,
    );

    state.data.sections = sectionsLeft;
  },
};
