





























































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import Store from '@/store';
import { IWorkorderTemplateData, ITemplateFieldConditionalLogicField } from '@/types';
import router from '@/router';

export default defineComponent({
  props: {
    action: {
      type: String,
      required: true,
    },
    isBackBtn: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    screenSize: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dialog = ref(false);
    if (props.isBackBtn) {
      dialog.value = props.isVisible;
    }

    const publishLoading = ref(false);
    const isLoading = ref(false);
    const tooltipShow = true;

    const saveLoading = computed(():
    boolean => Store.getters['templateCustomisation/getSaveLoading']);

    const getTemplateName = computed((): string => {
      const templateData = Store
        .getters['templateCustomisation/getData'] as IWorkorderTemplateData | null;
      if (templateData) return templateData.identifier;
      return '';
    });

    const fields = computed(() => Store.getters['templateCustomisation/getAllSectionsFields'] as ITemplateFieldConditionalLogicField[]);
    const inboundConfigured = computed((): boolean => Store.getters['templateCustomisation/getInboundConfigured']);
    const outboundConfigured = computed((): boolean => Store.getters['templateCustomisation/getOutboundConfigured']);
    const fieldMappingsConfigured = computed((): boolean => Store.getters['templateCustomisation/getMappingsConfigured']);

    /**
     * Closes dialog box
     */
    const closeDialog = () => {
      dialog.value = false;
    };

    const goBack = () => {
      closeDialog();
      router.go(-1);
    };

    const getPopupTitle = computed(() => {
      if (props.action === 'save' && !props.isBackBtn) return `Save Changes to ${getTemplateName.value}?`;
      if (props.action === 'save' && props.isBackBtn) return 'Do you wish to leave this page without saving?';
      if (props.action === 'publish' && (!inboundConfigured.value || !outboundConfigured.value || !fieldMappingsConfigured.value)) {
        return 'Warning: Incomplete Configuration';
      }
      if (props.action === 'publish') return `Publish ${getTemplateName.value}?`;
      return '';
    });

    const getPopupMessage = computed(() => {
      if (props.action === 'save' && !props.isBackBtn) {
        return {
          text: 'Please confirm if you wish to save your changes of this template.',
        };
      }
      if (props.action === 'save' && props.isBackBtn) {
        return {
          text: 'Please confirm if you wish to leave this page without saving or click \'Save\' to save your changes.',
          subtext: 'All unsaved changes will be lost.',
        };
      }
      if (props.action === 'publish' && inboundConfigured.value && outboundConfigured.value && fieldMappingsConfigured.value) {
        return {
          text: 'Please confirm if you wish to Publish this template.',
          subtext: 'Once you publish it cannot be edited again.',
        };
      }

      let warningMessage = 'The following options haven\'t been configured: ';
      if (!inboundConfigured.value) warningMessage += '\n\n- Inbound mappings';
      if (!fieldMappingsConfigured.value) warningMessage += '\n- Job template mappings';
      if (!outboundConfigured.value) warningMessage += '\n- Outbound messages';

      warningMessage += '\n\nDo you wish to publish anyway?';
      return {
        text: warningMessage,
        subtext: 'Once you publish it cannot be edited again.',
      };
    });

    /**
     *  @summary Triggers notification to be displayed
     *  @description Takes the notification type & message as params
     *  @author EJ McVey
     */
    const sendNotifiction = (type: string, message: string) => {
      Store.dispatch('alerts/createAlert', {
        type,
        message,
      }, { root: true });
    };

    /**
     *  @summary Saves template if conditions are met
     *  @description Checks to see if data has been changed, table & select comps have options
     *  @author EJ McVey
     */
    const saveTemplateChanges = async () => {
      publishLoading.value = true;
      const noOptsSelectFields = fields.value.filter((field) => (field.type === 'select' || field.type === 'table') && (!field.structure));
      const noJobsHeadersConfigured = fields.value.filter((field) => field.type === 'joblist' && !field.jobListStructure && !field.structure);

      if (noOptsSelectFields.length > 0) {
        sendNotifiction('error', 'One or more table or select fields have no options defined. Please add them in before saving.');
      } else if (noJobsHeadersConfigured.length > 0) {
        sendNotifiction('error', 'Please configure job list headers before saving.');
      } else if (!fields.value.length) {
        sendNotifiction('error', 'Please add at least one field before saving.');
      } else {
        await Store.dispatch('templateCustomisation/saveTemplateChanges', props.action);
        if (props.isBackBtn) goBack();

        if (props.action === 'publish') await Store.dispatch('templateCustomisation/publishTemplate');

        publishLoading.value = false;
      }

      closeDialog();
    };

    /**
     *  @summary Opens the dialog. If the action is 'publish', it checks if template config is complete.
     *  @author Ewa Murjas
     */
    const openDialog = async () => {
      if (props.action === 'publish') {
        isLoading.value = true;
        await Store.dispatch('templateCustomisation/checkMappingsConfigured');
        isLoading.value = false;
      }
      dialog.value = true;
    };

    return {
      dialog,
      openDialog,
      tooltipShow,
      saveLoading,
      publishLoading,
      closeDialog,
      getPopupTitle,
      getPopupMessage,
      saveTemplateChanges,
      getTemplateName,
      isLoading,
      goBack,
    };
  },
});
