import { MutationTree } from 'vuex';
import axios from 'axios';
import {
  IJobTemplateStoreState,
  IJobTemplate,
} from '@/types';
import { getDefaultState } from './state';

export const JobTemplatesStoreMutations: MutationTree<IJobTemplateStoreState> = {
  setData: (state: IJobTemplateStoreState, data: IJobTemplate[]) => {
    state.data = data;
  },

  generateCancelSource: (state: IJobTemplateStoreState) => {
    state.cancelSource = axios.CancelToken.source();
  },
  cancelCancelSource: (state: IJobTemplateStoreState) => {
    state.cancelSource.cancel('cancel');
  },

  setLoading: (state: IJobTemplateStoreState, isLoading: boolean) => {
    state.loading = isLoading;
  },

  resetState: (state: IJobTemplateStoreState) => {
    Object.assign(state, getDefaultState());
  },
};
