import _ from 'lodash';
import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IInboundOutboundMappingStoreState,
  IInboundMappingItem,
  IWorkorderTemplateData,
  ITemplateSearchRecord,
} from '@/types';

export const InboundMappingStoreActions: ActionTree<IInboundOutboundMappingStoreState, IVuexRootStoreState> = {
  setWorkOrderTemplate: ({ commit }, workOrderTemplate: string) => {
    commit('setWorkOrderTemplate', workOrderTemplate);
  },
  setMappings: ({ commit }, mappings: IInboundMappingItem[]) => {
    commit('setMappings', mappings);
  },
  setMappedTemplates: async ({ commit, dispatch }) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates?filter[has_inbound_mappings]=1&with_versions=1`,
      backupError: 'Error loading templates. Please try again later.',
    }, { root: true })
      .then((data) => {
        data.data = data.data.map((record: ITemplateSearchRecord) => {
          Object.assign(record, {
            createdByName: record.user?.name,
          });
          return record;
        });
        commit('setLoading', false);
        commit('setMappedTemplates', data.data);
      })
      .finally(() => commit('setLoading', false));
  },

  loadTemplateData: async ({ commit, getters, dispatch }, templateParamId) => {
    commit('setLoading', true);
    const templateId = templateParamId || getters.getWorkOrderTemplateId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateId}`,
      backupErr: 'Error loading template. Please try again later.',
    }, { root: true })
      .then((data) => {
        commit('setLoading', false);
        commit('setTemplateData', data.data as IWorkorderTemplateData);
      })
      .finally(() => commit('setLoading', false));
  },

  saveMappingConfigurations: async ({ commit, getters, dispatch }, isEdited) => {
    commit('setLoading', true);
    const { mappings } = getters.getData;
    const templateData = getters.getData.templateData as IWorkorderTemplateData;
    const alertMessage = isEdited ? 'Inbound mappings saved successfully.' : 'Inbound mappings created successfully.';

    // TODO ask M if other solution is better - avoiding nested loops
    templateData.sections = templateData.sections.map((section) => {
      const cloneSection = _.cloneDeep(section);
      const { fields } = cloneSection;

      cloneSection.fields = fields.map((field) => {
        mappings.forEach((mappingObj: IInboundMappingItem) => {
          if (mappingObj.workOrderFieldId === field.id) {
            Object.assign(field, {
              inboundMapping: mappingObj.inboundLabel,
              inboundOverwrite: mappingObj.inboundOverwrite,
            });
          }
          if (field.type === 'joblist') {
            Object.assign(field, {
              jobListStructure: field.structure,
            });
          }
        });
        return field;
      });
      return cloneSection;
    });

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateData.id}/?version=${templateData.version}`,
      options: {
        method: 'PUT',
        data: templateData,
      },
      backupError: 'Could not make required inbound mapping changes',
    }, { root: true })
      .then(() => {
        dispatch('alerts/createAlert', {
          type: 'success',
          message: alertMessage,
        }, { root: true });
      }).finally(() => commit('setLoading', false));
  },

  setSavedMappings: ({ commit, getters }) => {
    const templateData = getters.getData.templateData as IWorkorderTemplateData;

    const mappings: IInboundMappingItem[] = [];

    if (templateData && templateData.sections) {
      templateData.sections.forEach((section) => {
        const { fields } = section;

        fields.map((field) => {
          if (field.id && field.inboundMapping) {
            mappings.push({
              workOrderFieldId: field.id,
              workOrderFieldName: `${field.name} (${section.name})`,
              inboundLabel: field.inboundMapping,
              inboundOverwrite: field.inboundOverwrite,
            });
          }
          return field;
        });
      });
    }
    if (mappings.length) commit('setMappings', mappings);
  },

  deleteInboundMessage: async ({ commit, getters, dispatch }) => {
    commit('setLoading', true);
    const templateData = getters.getData.templateData as IWorkorderTemplateData;

    templateData.sections = templateData.sections.map((section) => {
      const cloneSection = _.cloneDeep(section);
      const { fields } = cloneSection;

      cloneSection.fields = fields.map((field) => {
        if (field.id && field.inboundMapping) {
          field.inboundMapping = '';
        }
        return field;
      });
      return cloneSection;
    });

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateData.id}/?version=${templateData.version}`,
      options: {
        method: 'PUT',
        data: templateData,
      },
      backupErr: 'Could not delete this inbound mapping . Please try again later.',
    }, { root: true })
      .then(() => {
        // reload new list of templates
        dispatch('setMappedTemplates');
        commit('setMappings', []);
        commit('setWorkOrderTemplate', '');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Inbound mapping deleted successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },
  // Util
  resetStoreState: ({ commit }) => {
    commit('resetState');
  },
};
