import { IRouteMapping } from '@/types';

export default {
  TemplateList: 'Template List',
  VersionList: 'Template Version List',
  TemplateCustomisation: 'Customisation Screen',
  TemplateDetailsCustomisation: 'Customisation Screen',
  MappingPanel: 'Mapping Config Panel',
  OutboundMappings: 'Outbound Mapping Panel',
  InboundMappings: 'Inbound Mapping Panel',
  Home: 'Admin Panel',
  TemplateMappings: 'Template and Job Mapping',
  TemplateMappingsCustomisation: 'Mapping Page',
  OutboundMappingsCustomisation: 'Outbound Overview',
  InboundMappingsCustomisation: 'Inbound Overview',
  MessageLogsTemplates: 'Message Logs',
  MessageLogs: 'Message Logs',
  GridSearchSettings: 'Grid Search Settings',
  ListsConfig: 'Lists Configuration',
  RefListItems: 'Reference List',
  MessageTemplates: 'Message Templates',
  MessageTemplateNew: 'Create Message Template',
  MessageTemplateEdit: 'Edit Message Template',
} as IRouteMapping;
