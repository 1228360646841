import { MutationTree } from 'vuex';
import { IMessageTemplate, IMessageTemplateState, IMessageTemplateType } from '@/types';

export const messageTemplateMutations: MutationTree<IMessageTemplateState> = {
  /**
   *  @summary Sets the SMS or Mail state array to the parsed template array value
   *  @param param.templates - The array of message templates
   *  @param param.type - The message template type (mail|sms)
   *  @author Jack O'Connor
   */
  setMessageTemplates: (
    state, { templates, type }: { templates: IMessageTemplate[], type: IMessageTemplateType },
  ) => {
    state[type].list = templates;
  },
  /**
   *  @summary Adds a template of type SMS or Mail to the corresponding state array
   *  @param param.template - The message template to be added (obj)
   *  @param param.type - The message template type (mail|sms)
   *  @author Jack O'Connor
   */
  addMessageTemplate: (
    state, { template, type }: { template: IMessageTemplate, type: IMessageTemplateType },
  ) => {
    state[type].list.unshift(template);
  },
  /**
   *  @summary Updates a template of type SMS or Mail in the list array
   *  @param param.template - The message template to be added (obj)
   *  @param param.type - The message template type (mail|sms)
   *  @author Jack O'Connor
   */
  updateMessageTemplate: (
    state, { template, type }: { template: IMessageTemplate, type: IMessageTemplateType },
  ) => {
    state[type].list = [
      ...state[type].list.filter((tmp) => tmp.id !== template.id),
      template,
    ];
  },
  /**
   *  @summary Deletes a template of type SMS or Mail from the list array
   *  @param param.type - The message template type (mail|sms)
   *  @param param.id - The ID of the message template to be deleted
   *  @author Jack O'Connor
   */
  deleteMessageTemplate: (
    state, { type, id }: { type: IMessageTemplateType, id: string },
  ) => {
    state[type].list = state[type].list.filter((tmp) => tmp.id !== id);
  },
  /**
   *  @summary Sets the date and time of the last time a message template API was called
   *  @param param.type - The message template type (mail|sms)
   *  @param param.dtime - The date/time of when the last API call was made
   *  @author Jack O'Connor
   */
  setAPILastCalled: (state, { type, dtime }: { type: IMessageTemplateType, dtime: Date }) => {
    state[type].lastCalled = dtime;
  },
  /**
   *  @summary Updates pagination properties
   *  @covers
   *    - setPaginationPage
   *    - setPaginationPerPage
   *    - setPaginationTotal
   *  @author Jack O'Connor
   */
  setPaginationPage: (state, { type, page }: { type: IMessageTemplateType, page: number }) => {
    state[type].pagination.page = page;
  },
  setPaginationPerPage: (state, { type, per_page }: { type: IMessageTemplateType, per_page: number }) => {
    state[type].pagination.per_page = per_page;
  },
  setPaginationTotal: (state, { type, total }: { type: IMessageTemplateType, total: number }) => {
    state[type].pagination.total = total;
  },
};
