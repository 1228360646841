import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IReferenceListStoreState,
  IReferenceListRecord,
} from '@/types';

export const ReferenceListStoreActions: ActionTree<IReferenceListStoreState, IVuexRootStoreState> = {
  // Filter
  setFilterCreatedAt: ({ commit }, createdAt: string[]) => {
    commit('setFilterCreatedAt', createdAt);
  },
  setFilterStartEndDate: ({ commit }, startEndDate: string) => {
    commit('setFilterStartEndDate', startEndDate);
  },
  setFilterSortBy: ({ commit }, sortBy: string) => {
    commit('setFilterSortBy', sortBy);
  },
  setFilterIsDesc: ({ commit }, isDesc: '-' | '') => {
    commit('setFilterIsDesc', isDesc);
  },

  // Pagination
  setPaginationPage: ({ commit }, page: number) => {
    commit('setPaginationPage', page);
  },
  setPaginationItemsPerPage: ({ commit }, itemsPerPage: number) => {
    commit('setPaginationItemsPerPage', itemsPerPage);
  },

  loadReferenceLists: async ({ dispatch, commit, getters }) => {
    commit('setLoading', true);
    const filters = getters.getFilterValue;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/referencelists`,
      options: {
        params: filters,
      },
    }, { root: true })
      .then((result) => {
        commit('setPaginationPage', result.currentPage as number);
        commit('setPaginationServerItemsLength', result.total as number);
        result.data = result.data.map((record: IReferenceListRecord) => {
          Object.assign(record, {
            createdBy: record.user.name,
          });
          return record;
        });
        commit('setData', result.data);
      })
      .finally(() => commit('setLoading', false));
  },

  createNewList: async ({ dispatch, commit }, newListData) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/referencelists`,
      options: {
        method: 'POST',
        data: newListData,
      },
      backupError: 'Could not create reference list. Please try again later.',
    }, { root: true })
      .then(async () => {
        await dispatch('loadReferenceLists');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Reference list created successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  updateRefList: async ({ dispatch, commit }, listData) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/referencelists/${listData.id}`,
      options: {
        method: 'PUT',
        data: listData,
      },
      backupError: 'Could not update reference list. Please try again later.',
    }, { root: true })
      .then(async () => {
        await dispatch('loadReferenceLists');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Reference list updated successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },

  deleteRefList: async ({ dispatch, commit }, listId) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/referencelists/${listId}`,
      options: {
        method: 'DELETE',
      },
      backupError: 'Could not delete reference list. Please try again later.',
    }, { root: true })
      .then(async () => {
        await dispatch('loadReferenceLists');
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Reference list deleted successfully.',
        }, { root: true });
      })
      .finally(() => commit('setLoading', false));
  },
};
