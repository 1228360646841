import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IWorkOrderTemplateStoreState,
  IVComboboxItem,
  IWorkOrderTemplateStatuses,
  IWorkOrderTemplateStatusTransitions,
  IWorkOrderTemplate,
  IWorkOrderTemplateList,
} from '@/types';

export const WorkOrderTemplateStoreActions: ActionTree<IWorkOrderTemplateStoreState, IVuexRootStoreState> = {
  setName: ({ commit }, name: string) => {
    commit('setName', name);
    if (name === '') {
      commit('setValidationName', true);
    }
  },
  setJobTemplate: ({ commit }, jobTemplate: string) => {
    commit('setJobTemplate', jobTemplate);
  },
  setSelectedJobTemplates: ({ commit }, jobTemplate: IVComboboxItem) => {
    commit('setSelectedJobTemplates', jobTemplate);
  },
  /**
   * This is the setter called when the user is adding available job templates
   * in the work order.
   */
  setAddJobTemplate: ({ commit }, jobTemplate: string) => {
    commit('setAddJobTemplate', jobTemplate);
  },

  setStatuses: ({ commit }, jobTemplateStatuses: IWorkOrderTemplateStatuses[]) => {
    commit('setStatuses', jobTemplateStatuses);
  },

  setSelectedNavItem: ({ commit }, jobTemplateStatuses: IWorkOrderTemplateStatuses[]) => {
    commit('setSelectedNavItem', jobTemplateStatuses);
  },

  setManualCreateValue: ({ commit }, manualCreateValue: boolean) => {
    commit('setManualCreateValue', manualCreateValue);
  },

  setDefaultTemplateCreateStatus: ({ commit }, defaultStatusName: string) => {
    commit('setDefaultTemplateCreateStatus', defaultStatusName);
  },

  deleteStatuses: ({ commit }, statusId: number) => {
    commit('deleteStatuses', statusId);
  },

  deleteTemplate: ({ commit }, templateId: number) => {
    commit('deleteTemplate', templateId);
  },

  selectDefaultTemplate: ({ commit }, template: IWorkOrderTemplateList) => {
    commit('selectDefaultTemplate', template);
  },

  /**
   * Checks if template name is available
   */
  validateName: async ({ getters, commit, dispatch }) => {
    commit('setLoading', true);

    const { name } = getters.getData;

    if (!name) {
      commit('setLoading', false);
      commit('setValidationName', true);
      return;
    }

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/validateidentifier`,
      options: {
        params: {
          identifier: name,
        },
      },
    }, { root: true })
      .then((data) => {
        if (data.data.success) {
          commit('setValidationName', true);
        } else {
          commit('setValidationName', 'Template name already in use');
        }
      })
      .catch((error) => {
        const errorMessage = error.response?.data.msg ? `Error: ${error.response.data.msg}` : 'There has been an error validating template name';
        commit('setValidationName', errorMessage);
      })
      .finally(() => commit('setLoading', false));
  },

  /**
   * @summary Loads data of a previously created template
   * @description
   * Function calls the api to get the name of the template selected and the template statuses.
   * This is entered into the state in order to use in the 'TemplateCreateWizard' components as the edit functionality.
   * @param {IWorkorderTemplateData} templateData -> object basic info on the template
   * @author Rory Harford
   */
  loadWorkOrderJobTemplateData: async ({ commit, dispatch, rootGetters }, templateData) => {
    const templateDetails = {} as IWorkOrderTemplate;
    if (!templateData) return;
    commit('setLoading', true);
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateData.id}/statuses`,
    }, { root: true })
      .then((templateStatuses) => {
        templateDetails.name = templateData.identifier;
        templateStatuses.data = templateStatuses.data.sort((status1: IWorkOrderTemplateStatuses, status2:IWorkOrderTemplateStatuses) => status1.order - status2.order);
        templateDetails.jobTemplateStatuses = templateStatuses.data;
        templateDetails.manualCreate = templateData.manualCreate;
        templateDetails.version = templateData.version;

        templateDetails.availableJobTemplates = {
          addingTemplate: '',
          defaultTemplate: true,
          defaultTemplateCreateStatus: '',
          selectedNavItem: -1,
          deleteTemplateList: [],
          templateList: [],
        };

        /* looping through statuses if there is no status transistion then 'None' is added for the UI */
        templateDetails.jobTemplateStatuses.forEach((statuses) => {
          if (statuses.transitions.length === 0) {
            statuses.transitions.push({
              id: -1,
              name: 'None',
              display: false,
            });
          }
        });
      });

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateData.id}/jobs`,
      backupError: 'Error loading template data',
    }, { root: true })
      .then((savedJobTemplates) => {
        const savedJobs = savedJobTemplates.data;
        const jobTemplates = rootGetters['jobTemplate/getData'];
        for (let index = 0; index < savedJobs.length; index += 1) {
          /* find default template to find status name from the createdOnStatus id */
          if (savedJobs[index].isDefault === true) {
            const defaultStatus = templateDetails.jobTemplateStatuses.find((status) => status.id === savedJobs[index].createOnStatus);
            templateDetails.availableJobTemplates.defaultTemplateCreateStatus = defaultStatus?.name || '';
          }

          const foundJobTemplate = jobTemplates.find((job: any) => job.jobTemplateId === savedJobs[index].jobTemplateId);
          savedJobs[index].jobTemplateName = foundJobTemplate ? foundJobTemplate.name : '';
        }
        templateDetails.availableJobTemplates.templateList = savedJobs;

        commit('setWorkOrderJobTemplateData', templateDetails);
      })
      .finally(() => commit('setLoading', false));
  },

  /**
   *  @summary createTemplate creates a template when user enters in all template details
   *  @description
   *    This can be called from two places editing an existing template or creating a new template.
   *    If its an edit template it skips the first step which is creating the template itself. (the function knows by if a workOrderTemplateId is passed in).
   *    Step 2 is then to add all the creations of each status into an array to call in a promise.all() so all statueses will be created.
   *    Step 3 is creating the transitions for each status once all status are created they can then be linked to transition into eachother.
   *    The final step is to loop around the default templates and delete (if an edit) any removed templates and add the remaining.
   *    (These templates are created by calling the function workOrderAddMultipleJobTemplates)
   *  @param {string} workOrderTemplateId
   *  @author Rory Harford
   */
  createTemplate: async ({ getters, commit, dispatch }, workOrderTemplateId) => {
    const { name } = getters.getData;
    const { manualCreate } = getters.getData;
    const { version } = getters.getData;
    const { jobTemplateStatuses } = getters.getData;
    const { availableJobTemplates } = getters.getData;
    const { deleteTemplateList } = getters.getData.availableJobTemplates;
    let message = 'Template created successfully.';
    if (workOrderTemplateId) message = 'Template saved successfully.';

    commit('setLoading', true);
    if (!workOrderTemplateId) {
      await dispatch('auth/apiRequest', {
        url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates`,
        options: {
          method: 'POST',
          data: {
            identifier: name,
            work_order_type_id: 2,
            manual_create: manualCreate,
            ribbon_type: 'circle',
          },
        },
      }, { root: true })
        .then((data) => {
          workOrderTemplateId = data.data.id;
        });
    } else {
      await dispatch('auth/apiRequest', {
        url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTemplateId}`,
        options: {
          method: 'PUT',
          data: {
            version,
            manual_create: manualCreate,
          },
        },
      }, { root: true });
    }

    let createStatusesCalls: any[] = [];
    /* loop around creating template statuses */
    createStatusesCalls = jobTemplateStatuses.map(async (template: IWorkOrderTemplate, index: number) => {
      const req = await dispatch('auth/apiRequest', {
        url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTemplateId}/statuses`,
        options: {
          method: 'POST',
          data: {
            name: template.name,
            order: index,
          },
        },
      }, { root: true });

      return req;
    });

    let defaultStatusId = 0;

    /* after all statuses are created start to create the transitions */
    Promise.all(createStatusesCalls).then(async (responses) => {
      /* loop around created Templates to add the new status id (i.e backend created id) */
      responses.forEach((response) => {
        const newStatusId = response.data.id;
        if (response.data.name === availableJobTemplates.defaultTemplateCreateStatus) defaultStatusId = newStatusId;

        const createdStatus = jobTemplateStatuses.find(
          (status: IWorkOrderTemplateStatuses) => status.name === response.data.name,
        );
        createdStatus.id = newStatusId;
      });

      let statusId = '';
      let transition: IWorkOrderTemplateStatusTransitions[] = [];

      /* loop around created Templates to add status transitions */
      jobTemplateStatuses.forEach(async (status: IWorkOrderTemplateStatuses) => {
        transition = [];
        statusId = jobTemplateStatuses.find(
          (s: IWorkOrderTemplateStatuses) => s.name === status.name,
        ).id;

        /* looping through the templates status tansitions */
        status.transitions.forEach((statusTransition) => {
          const statusName = statusTransition.name;
          if (statusName === 'None') return;

          const statusTransId = jobTemplateStatuses.find(
            (s: IWorkOrderTemplateStatuses) => s.name === statusName,
          ).id;

          transition.push({
            id: statusTransId,
            name: statusName,
            display: true,
          });

          const workOrderStatusTransition = {
            workOrderTemplateId,
            statusId,
            statusTrans: transition,
          };

          dispatch('createTemplateStatusesTransitions', workOrderStatusTransition);
        });
      });
      const createJobTempsDetails = {
        availableTemplates: availableJobTemplates.templateList,
        defaultStatusId,
        workOrderTemplateId,
      };

      /* delete templates no longer present */
      await dispatch('auth/apiRequest', {
        url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTemplateId}/jobs`,
        backupError: 'Error saving template',
      }, { root: true }).then(async (data) => {
        /* find all templates user has deleted that where added previously */
        let deletedTemplates = data.data.filter((preSavedTemp: IWorkOrderTemplateList) => deleteTemplateList.some((deleteTempId: number) => preSavedTemp.jobTemplateId === deleteTempId));
        /* remove any templates from the list that where re-added */
        deletedTemplates = deletedTemplates.filter((deletedTemp: IWorkOrderTemplateList) => !availableJobTemplates.templateList.some((addedTemps:IWorkOrderTemplateList) => deletedTemp.jobTemplateId === addedTemps.jobTemplateId));
        deletedTemplates.forEach(async (deletedTemplate: IWorkOrderTemplateList) => {
          await dispatch('auth/apiRequest', {
            url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTemplateId}/jobs/${deletedTemplate.id}`,
            options: {
              method: 'DELETE',
            },
          }, { root: true });
        });
        dispatch('workOrderAddMultipleJobTemplates', createJobTempsDetails);
      });

      dispatch('alerts/createAlert', {
        type: 'success',
        message,
      }, { root: true });
    })
      .finally(() => commit('setLoading', false));
  },

  /**
  *  @summary workOrderAddMultipleJobTemplates links the job templates to the work order.
  *  @description
  *  Step 1 this function finds the default template to add the status it is created on.
  *  afterwards the data is passed to create the multiple job templates on the work order.
  *  @param {object} createJobTempsDetails contains work order template id and the list of templates to link to the job.
  *  @param {string} workOrderTemplateStatusDetails.workOrderTemplateId
  *  @param {array} workOrderTemplateStatusDetails.availableTemplates array of the job templates to link to the work order template.
  *  @author Rory Harford
  */
  workOrderAddMultipleJobTemplates: async ({ commit, dispatch }, createJobTempsDetails) => {
    /* find default template and update it with default create status id */
    const defaultTemp = createJobTempsDetails.availableTemplates.find((availTemp: IWorkOrderTemplateList) => availTemp.isDefault === true);
    defaultTemp.createOnStatus = createJobTempsDetails.defaultStatusId;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${createJobTempsDetails.workOrderTemplateId}/jobs/multiple`,
      options: {
        method: 'PUT',
        data: {
          template_jobs: createJobTempsDetails.availableTemplates,
        },
      },
    }, { root: true })
      .finally(() => commit('setLoading', false));
    commit('setSelectedNavItem', -1);
  },

  /**
  *  @summary createTemplateStatusesTransitions creates the statuses transitions on a status for a work order template/
  *  @param {object} workOrderTemplateStatusDetails
  *  @param {string} workOrderTemplateStatusDetails.workOrderTemplateId
  *  @param {string} workOrderTemplateStatusDetails.statusId the status ids the transitions are being created on.
  *  @param {array} workOrderTemplateStatusDetails.statusTrans array of the ids of the transition statuses.
  *  @author Rory Harford
  */
  createTemplateStatusesTransitions: async ({ dispatch }, workOrderTemplateStatusDetails) => {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTemplateStatusDetails.workOrderTemplateId}/statuses/${workOrderTemplateStatusDetails.statusId}/transitions`,
      options: {
        method: 'PUT',
        data: {
          transitions: workOrderTemplateStatusDetails.statusTrans,
        },
      },
    }, { root: true });
  },

  /**
   *  @summary edits a templates
   *  @description
   *    At the moment this function deletes all statuses and status transitions and then readds what status are left after the edit
   *  @author Rory Harford
   */
  editTemplate: async ({ commit, dispatch }, workOrderTemplateId) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTemplateId}/statuses`,
    }, { root: true })
      .then(async (previouslyAddedJobTemplates) => {
        const deleteStatusesTransitions = previouslyAddedJobTemplates.data.map(async (status : IWorkOrderTemplateStatuses) => {
          const req = await dispatch('auth/apiRequest', {
            url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTemplateId}/statuses/${status.id}/transitions`,
            options: {
              method: 'PUT',
              data: {
                transitions: [],
              },
            },
          }, { root: true });
          return req;
        });

        let deleteStatuses = [] as any;
        /* once all status transitons are deleted then delete the statuses */
        Promise.all(deleteStatusesTransitions).then(() => {
          deleteStatuses = previouslyAddedJobTemplates.data.map(async (status : IWorkOrderTemplateStatuses) => {
            const req = await dispatch('auth/apiRequest', {
              url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTemplateId}/statuses/${status.id}`,
              options: {
                method: 'DELETE',
              },
            }, { root: true });
            return req;
          });

          /* promise.all waits for all statuses to be deleted before starting the process of editing the work order template */
          Promise.all(deleteStatuses).then(() => {
            dispatch('createTemplate', workOrderTemplateId);
          });
        });
      });
  },

  /**
   * Creates a new template based on another template
   */
  duplicateTemplate: async (
    { getters, commit, dispatch },
    templateDetails: { templateId: string; companyId: number },
  ) => {
    const { name } = getters.getData;
    commit('setDuplicateLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${templateDetails.templateId}/duplicate`,
      options: {
        method: 'POST',
        data: {
          identifier: name,
        },
      },
      backupError: 'Error duplicating template',
    }, { root: true })
      .then(() => {
        dispatch('alerts/createAlert', {
          type: 'success',
          message: 'Template duplicated successfully.',
        }, { root: true });
      })
      .finally(() => commit('setDuplicateLoading', false));
  },

  /**
   * Resets store state
   */
  resetStoreState: ({ commit }) => {
    commit('resetState');
  },
};
