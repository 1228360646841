import { GetterTree } from 'vuex';
import { CancelTokenSource } from 'axios';
import {
  IWorkOrderTemplateStoreState,
  IVuexRootStoreState,
  IWorkOrderTemplate,
  IWorkOrderTemplateList,
} from '@/types';

export const WorkOrderTemplateStoreGetters: GetterTree<IWorkOrderTemplateStoreState, IVuexRootStoreState> = {
  getData: (state): IWorkOrderTemplate => state.data,
  getValidation: (state) => state.validation,
  getCancelSource: (state): CancelTokenSource => state.cancelSource,
  getLoading: (state): boolean => state.loading,
  getDuplicateLoading: (state): boolean => state.duplicateLoading,
  getDefaultJobTemplate: (state): boolean => state.data.availableJobTemplates.defaultTemplate,
  getAddedJobTemplates:
    (state): IWorkOrderTemplateList[] => state.data.availableJobTemplates.templateList,
  getDefaultTemplateCreateStatus:
    (state) => state.data.availableJobTemplates.defaultTemplateCreateStatus,
  getManualCreateValue:
    (state) => state.data.manualCreate,
  getSelectedNavItem:
    (state) => state.data.availableJobTemplates.selectedNavItem,

  getSelectTran: (state) => (navTranStatusId: number, tranStatusId: number): boolean => {
    const seletedTransItem = state.data
      .jobTemplateStatuses.find((status) => status.id === navTranStatusId)?.transitions
      .find((statusTransitions) => statusTransitions.id === tranStatusId);

    if (!seletedTransItem) return false;
    return true;
  },

  transitionStatuses: (state) => (selectedNav: number) => {
    if (selectedNav && selectedNav !== -1) {
      const tran = state.data.jobTemplateStatuses.filter((status) => status.id !== selectedNav);
      tran.push({
        id: -1,
        order: -1,
        name: 'None',
        statusInbound: {
          statusInboundName: '',
        },
        fieldChange: [],
        jobStatusChange: {
          geopalStatusId: 0,
          geopalStatusName: '',
        },
        transitions: [],
      });
      return tran;
    }
    return null;
  },

  getTranDisplayBtn: (state) => (navTranStatusId: number, tranStatusId: number): boolean => {
    const transItemDisplayButton = state.data
      .jobTemplateStatuses.find((status) => status.id === navTranStatusId)?.transitions
      .find((statusTransitions) => statusTransitions.id === tranStatusId)?.display;

    if (!transItemDisplayButton) return false;
    return transItemDisplayButton;
  },
};
