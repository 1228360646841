import { ActionTree } from 'vuex';
import {
  IVuexRootStoreState,
  IConditionalLogicStoreState,
  IConditionalLogicAlarm,
  IConditionalLogicAlarmDelete,
} from '@/types';

export const ConditionalLogicStoreActions: ActionTree<IConditionalLogicStoreState, IVuexRootStoreState> = {
  addConditionalLogicAlarm: ({ commit }, conditionalLogicAlarm) => {
    commit('addConditionalLogicAlarm', conditionalLogicAlarm);
  },

  setStatusData: ({ commit }, conditionalLogicAlarm) => {
    commit('setStatusData', conditionalLogicAlarm);
  },

  removeConditionalLogicAlarm: ({ commit }, conditionalLogicAlarm) => {
    commit('removeConditionalLogicAlarm', conditionalLogicAlarm);
  },

  /**
  *  @summary Deletes all alarms attached to conditional logic and the event conditional logic itself
  *  @param {object} object contains workOrderTemplateId and if it is called to add status logic
  *  @author Rory Harford
  */
  deleteAlarms: async ({ getters, commit, dispatch }, alarmParams) => {
    commit('setLoading', true);
    let deletedConditionalLogicIds = getters.getDeletedConditionalLogicIds;
    const conditionalLogicAdd = getters.getAddedConditionalLogic;
    let deleteCondLogic = `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${alarmParams.workOrderTemplateId}/changefieldvalues/`;
    if (alarmParams.calledFromStatusLogic) deleteCondLogic = `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${alarmParams.workOrderTemplateId}/changeworkorderstatus/`;

    /* if no items to delete move straight on to Create */
    if (deletedConditionalLogicIds.length <= 0) dispatch('createConditionalLogicAlarm', alarmParams);

    /* deletes all alarms & events */
    deletedConditionalLogicIds.forEach(async (element : IConditionalLogicAlarmDelete) => {
      /* delete alarms */
      await dispatch('auth/apiRequest', {
        url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${alarmParams.workOrderTemplateId}/alarms/${element.alarmId}`,
        options: {
          method: 'DELETE',
        },
      }, { root: true });

      /* delete events */
      await dispatch('auth/apiRequest', {
        url: `${deleteCondLogic}${element.eventId}`,
        options: {
          method: 'DELETE',
        },
        backupError: 'Error updating field. Please try again later.',
      }, { root: true })
        .then(() => {
          deletedConditionalLogicIds = deletedConditionalLogicIds.filter((item : IConditionalLogicAlarmDelete) => item.alarmId !== element.alarmId);
          if (conditionalLogicAdd.length <= 0 && deletedConditionalLogicIds.length === 0) {
            /* if all items have been deleted and no items to create notify */
            commit('setLoading', false);
            dispatch('alerts/createAlert', {
              type: 'success',
              message: 'Field alarm values updated',
            }, { root: true });
            dispatch('loadConditionalLogicForStatus', alarmParams.workOrderTemplateId);
            dispatch('loadConditionalLogicForFields', alarmParams.workOrderTemplateId);
          } else if (deletedConditionalLogicIds.length === 0 && conditionalLogicAdd.length > 0) {
            /* if all items have been deleted and items to create move on to Create */
            dispatch('createConditionalLogicAlarm', alarmParams);
          }
        });
    });
  },

  /**
  *  @summary Creates Logic Alarm
  *  @description creates the logic alarm and then calls the addAlarmValues function that adds in the conditions
  *  @param {string} workOrderTemplateId
  *  @author Rory Harford
  */
  createConditionalLogicAlarm: async ({ getters, commit, dispatch }, alarmParams) => {
    commit('setLoading', true);
    let url = `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${alarmParams.workOrderTemplateId}/changefieldvalues`;
    const conditionalLogicAlarms = getters.getAddedConditionalLogic;
    let numberToCreate = conditionalLogicAlarms.length;

    conditionalLogicAlarms.forEach(async (conditionalLogicAlarm: IConditionalLogicAlarm) => {
      const dataToSend = {
        custom_field_id: conditionalLogicAlarm.customFieldId,
      };

      if (alarmParams.calledFromStatusLogic) {
        url = `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${alarmParams.workOrderTemplateId}/changeworkorderstatus`;
        conditionalLogicAlarm.eventType = 'change_work_order_status';
        Object.assign(dataToSend, {
          status_id: conditionalLogicAlarm.populates,
        });
      } else if (Object.keys(conditionalLogicAlarm.calculates).length) {
        Object.assign(dataToSend, {
          new_value_logic: conditionalLogicAlarm.calculates,
        });
      } else {
        Object.assign(dataToSend, {
          new_value: conditionalLogicAlarm.populates,
        });
      }

      await dispatch('auth/apiRequest', {
        url,
        options: {
          method: 'POST',
          data: dataToSend,
        },
        backupError: 'Error updating field. Please try again later.',
      }, { root: true })
        .then((data) => {
          const changeFieldValuesId = data.data.id;
          conditionalLogicAlarm.eventId = changeFieldValuesId;
          numberToCreate--;
          commit('setLoading', true);
          dispatch('addAlarmValues', { conditionalLogicAlarm, numberToCreate });
        });
    });
  },

  /**
  *  @summary creates all the conditions on an alarm is called from createConditionalLogicAlarm
  *  @param {object} conditionalLogicAlarm
  *  @author Rory Harford
  */
  addAlarmValues: async ({ commit, dispatch }, conditionalLogicDetails) => {
    const { conditionalLogicAlarm, numberToCreate } = conditionalLogicDetails;

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${conditionalLogicAlarm.workOrderId}/alarms`,
      options: {
        method: 'POST',
        data: {
          name: 'TESTER',
          event_type: conditionalLogicAlarm.eventType || 'change_field_value',
          event_id: conditionalLogicAlarm.eventId,
          conditions: conditionalLogicAlarm.conditions,
        },
      },
      backupError: 'Error updating field. Please try again later.',
    }, { root: true })
      .then(() => {
        if (numberToCreate === 1 || numberToCreate === 0) {
          commit('setLoading', false);
          dispatch('alerts/createAlert', {
            type: 'success',
            message: 'Field alarm values updated',
          }, { root: true });
          dispatch('loadConditionalLogicForStatus', conditionalLogicAlarm.workOrderId);
          dispatch('loadConditionalLogicForFields', conditionalLogicAlarm.workOrderId);
        }
      });
  },

  /**
    *  @summary loads the change status alarm
    *  @param {string} workOrderTemplateId
    *  @author Rory Harford
   */
  loadConditionalLogicForStatus: async ({ dispatch, commit }, workOrderTemplateId) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTemplateId}/changeworkorderstatus`,
    }, { root: true })
      .then((data) => {
        data.data.workOrderTemplateId = workOrderTemplateId;
        dispatch('loadConditionalLogicConditionsForStatus', data.data);
      });
  },

  /**
    *  @summary loads the change field alarm
    *  @param {string} workOrderTemplateId
    *  @author Rory Harford
   */
  loadConditionalLogicForFields: async ({ dispatch, commit }, workOrderTemplateId) => {
    commit('setLoading', true);

    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${workOrderTemplateId}/changefieldvalues`,
    }, { root: true })
      .then((data) => {
        data.data.workOrderTemplateId = workOrderTemplateId;
        dispatch('loadConditionalLogicConditionsForFields', data.data);
      });
  },

  loadConditionalLogicConditionsForFields: async ({ dispatch, commit }, conditionalLogicData) => {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${conditionalLogicData.workOrderTemplateId}/alarms`,
    }, { root: true })
      .then((data) => {
        const alarmsEventChange = data.data.map((element: IConditionalLogicAlarm) => ({
          eventId: element.eventId,
          alarmId: element.id,
          conditions: element.conditions,
        }));

        conditionalLogicData.forEach((element: IConditionalLogicAlarm) => {
          const foundOperators = alarmsEventChange.find((eventChange: IConditionalLogicAlarm) => eventChange.eventId === element.id);
          element.conditions = foundOperators ? foundOperators.conditions : [];
          element.conditionId = foundOperators ? foundOperators.alarmId : '';
        });
        commit('setData', conditionalLogicData as IConditionalLogicStoreState[]);
      }).finally(() => { commit('setLoading', false); });
  },

  loadConditionalLogicConditionsForStatus: async ({ dispatch, commit }, conditionalLogicData) => {
    await dispatch('auth/apiRequest', {
      url: `${process.env.VUE_APP_API_URL}/api/v1/workordertemplates/${conditionalLogicData.workOrderTemplateId}/alarms`,
    }, { root: true })
      .then((data) => {
        const alarmsEventChange = data.data.map((element: IConditionalLogicAlarm) => ({
          eventId: element.eventId,
          alarmId: element.id,
          conditions: element.conditions,
        }));

        conditionalLogicData.forEach((element: IConditionalLogicAlarm) => {
          const foundOperators = alarmsEventChange.find((eventChange: IConditionalLogicAlarm) => eventChange.eventId === element.id);
          element.conditions = foundOperators ? foundOperators.conditions : [];
          element.conditionId = foundOperators ? foundOperators.alarmId : '';
        });
        commit('setStatusData', conditionalLogicData as IConditionalLogicStoreState[]);
      }).finally(() => { commit('setLoading', false); });
  },
};
