import { MutationTree } from 'vuex';
import axios from 'axios';
import {
  IOutboundStatusStoreState,
  ITemplateStatus,
} from '@/types';
import { getDefaultState } from './state';

export const TemplateStatusStoreMutations: MutationTree<IOutboundStatusStoreState> = {
  setData: (state: IOutboundStatusStoreState, data: ITemplateStatus[]) => {
    state.data = data;
  },

  setTemplateId: (state: IOutboundStatusStoreState, id: string) => {
    state.templateId = id;
  },

  generateCancelSource: (state: IOutboundStatusStoreState) => {
    state.cancelSource = axios.CancelToken.source();
  },
  cancelCancelSource: (state: IOutboundStatusStoreState) => {
    state.cancelSource.cancel('cancel');
  },

  setLoading: (state: IOutboundStatusStoreState, isLoading: boolean) => {
    state.loading = isLoading;
  },

  resetState: (state: IOutboundStatusStoreState) => {
    Object.assign(state, getDefaultState());
  },
};
