import { Module } from 'vuex';
import {
  IOutboundStatusStoreState,
  IVuexRootStoreState,
} from '@/types';
import { TemplateStatusStoreGetters } from './getters';
import { TemplateStatusStoreActions } from './actions';
import { TemplateStatusStoreMutations } from './mutations';
import { getDefaultState } from './state';

export const TemplateStatusStore: Module<IOutboundStatusStoreState, IVuexRootStoreState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: TemplateStatusStoreGetters,
  actions: TemplateStatusStoreActions,
  mutations: TemplateStatusStoreMutations,
};
