import { GetterTree } from 'vuex';
import {
  IConditionalLogicStoreState,
  IConditionalLogicAlarm,
  IVuexRootStoreState,
  IConditionalLogicAlarmDelete,
} from '@/types';

export const ConditionalLogicStoreGetters: GetterTree<IConditionalLogicStoreState, IVuexRootStoreState> = {
  getLoading: (
    state: IConditionalLogicStoreState,
  ): IConditionalLogicStoreState['loading'] => state.loading,

  getData: (state: IConditionalLogicStoreState): IConditionalLogicAlarm[] => state.data,

  getStatusData: (state: IConditionalLogicStoreState): IConditionalLogicAlarm[] => state.statusData,

  getAddedConditionalLogic: (state: IConditionalLogicStoreState): IConditionalLogicAlarm[] => state.addedItems,

  getDeletedConditionalLogicIds: (state: IConditionalLogicStoreState): IConditionalLogicAlarmDelete[] => state.deletedItems,

  getCalculateLogic: (state: IConditionalLogicStoreState) => state.numberCalculateLogic,
};
