import axios from 'axios';
import {
  IWorkOrderTemplateStoreState,
} from '@/types';

export const getDefaultState = (): IWorkOrderTemplateStoreState => ({
  data: {
    name: '',
    jobTemplate: '',
    manualCreate: null,
    version: 0,
    availableJobTemplates: {
      addingTemplate: '',
      selectedNavItem: -1,
      defaultTemplateCreateStatus: '',
      defaultTemplate: false,
      deleteTemplateList: [],
      templateList: [],
    },
    jobTemplateStatuses: [],
  },
  cancelSource: axios.CancelToken.source(),
  loading: false,
  duplicateLoading: false,
  validation: {
    name: true,
  },
});
